import { useEffect, useRef } from 'react'

export const useResizeObserver = <ElRef extends HTMLElement>(callback: (e?: ResizeObserverEntry[]) => any) => {
  const ref = useRef<ElRef>(null)
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    const observer = new ResizeObserver((e) => {
      callbackRef.current?.(e)
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [])

  return ref
}
