interface Translation {
  code: string
  acronym: string
  fullText: string
}

export const translations: Translation[] = [
  {
    code: 'net',
    acronym: 'NET',
    fullText: 'NET Bible&reg;',
  },
  {
    code: 'esv',
    acronym: 'ESV',
    fullText: 'English Standard Version',
  },
  {
    code: 'kjv',
    acronym: 'KJV',
    fullText: 'King James Version',
  },
  {
    code: 'asv',
    acronym: 'ASV',
    fullText: 'American Standard Version',
  },
  {
    code: 'akjv',
    acronym: 'KJV Easy',
    fullText: 'KJV Easy Read',
  },
  {
    code: 'basicenglish',
    acronym: 'BEB',
    fullText: 'Basic English Bible',
  },
  {
    code: 'darby',
    acronym: 'Darby',
    fullText: 'Darby',
  },
  {
    code: 'ylt',
    acronym: 'YLT',
    fullText: "Young's Literal Translation",
  },
  {
    code: 'web',
    acronym: 'WEB',
    fullText: 'World English Bible',
  },
  {
    code: 'wb',
    acronym: 'WB',
    fullText: "Webster's Bible",
  },
  {
    code: 'douayrheims',
    acronym: 'DR',
    fullText: 'Douay Rheims',
  },
]
