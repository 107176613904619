/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n      mutation createPlan($object: bible_reading_plan_insert_input!) {\n        insert_bible_reading_plan_one(object: $object) {\n          id\n        }\n      }\n    ": types.CreatePlanDocument,
    "\n      mutation updatePlan($id: Int!, $set: bible_reading_plan_set_input!) {\n        update_bible_reading_plan_by_pk(pk_columns: { id: $id }, _set: $set) {\n          id\n        }\n      }\n    ": types.UpdatePlanDocument,
    "\n      mutation DeleteBibleReadingPlan($id: Int!) {\n        delete_bible_reading_plan_by_pk(id: $id) {\n          id\n        }\n      }\n    ": types.DeleteBibleReadingPlanDocument,
    "\n      subscription allPlans {\n        bible_reading_plan(order_by: { created_at: asc }) {\n          id\n          chapters\n          name\n          active_email\n          active_text\n          legacy_id\n          legacy_email\n          legacy_phone\n          legacy_carrier\n          next_chapter\n          last_chapter\n          first_day\n          time\n          translation\n        }\n      }\n    ": types.AllPlansDocument,
    "\n      query GetBibleChapter($chapter: Int!, $version: String!) {\n        getBibleChapter(chapter: $chapter, version: $version) {\n          passage\n          error\n        }\n      }\n    ": types.GetBibleChapterDocument,
    "\n  mutation Login($username: String, $email: String, $password: String!) {\n    login(input: { username: $username, password: $password, email: $email }) {\n      token\n      error\n    }\n  }\n": types.LoginDocument,
    "\n  mutation Signup($username: String!, $email: String!, $password: String!) {\n    signup(input: { username: $username, password: $password, email: $email }) {\n      id\n      email\n      username\n      error\n    }\n  }\n": types.SignupDocument,
    "\n  mutation Logout($token: String, $allSessions: Boolean) {\n    logout(input: { token: $token, allSessions: $allSessions }) {\n      success\n      error\n    }\n  }\n": types.LogoutDocument,
    "\n  mutation ForgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      success\n      error\n    }\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation ResetPassword($linkId: uuid!, $newPassword: String!) {\n    resetPassword(linkId: $linkId, newPassword: $newPassword) {\n      success\n      error\n    }\n  }\n": types.ResetPasswordDocument,
    "\n  query Me {\n    me {\n      email\n      created_at\n      id\n      role\n      updated_at\n      username\n    }\n  }\n": types.MeDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation createPlan($object: bible_reading_plan_insert_input!) {\n        insert_bible_reading_plan_one(object: $object) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation createPlan($object: bible_reading_plan_insert_input!) {\n        insert_bible_reading_plan_one(object: $object) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation updatePlan($id: Int!, $set: bible_reading_plan_set_input!) {\n        update_bible_reading_plan_by_pk(pk_columns: { id: $id }, _set: $set) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation updatePlan($id: Int!, $set: bible_reading_plan_set_input!) {\n        update_bible_reading_plan_by_pk(pk_columns: { id: $id }, _set: $set) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteBibleReadingPlan($id: Int!) {\n        delete_bible_reading_plan_by_pk(id: $id) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation DeleteBibleReadingPlan($id: Int!) {\n        delete_bible_reading_plan_by_pk(id: $id) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      subscription allPlans {\n        bible_reading_plan(order_by: { created_at: asc }) {\n          id\n          chapters\n          name\n          active_email\n          active_text\n          legacy_id\n          legacy_email\n          legacy_phone\n          legacy_carrier\n          next_chapter\n          last_chapter\n          first_day\n          time\n          translation\n        }\n      }\n    "): (typeof documents)["\n      subscription allPlans {\n        bible_reading_plan(order_by: { created_at: asc }) {\n          id\n          chapters\n          name\n          active_email\n          active_text\n          legacy_id\n          legacy_email\n          legacy_phone\n          legacy_carrier\n          next_chapter\n          last_chapter\n          first_day\n          time\n          translation\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetBibleChapter($chapter: Int!, $version: String!) {\n        getBibleChapter(chapter: $chapter, version: $version) {\n          passage\n          error\n        }\n      }\n    "): (typeof documents)["\n      query GetBibleChapter($chapter: Int!, $version: String!) {\n        getBibleChapter(chapter: $chapter, version: $version) {\n          passage\n          error\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Login($username: String, $email: String, $password: String!) {\n    login(input: { username: $username, password: $password, email: $email }) {\n      token\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation Login($username: String, $email: String, $password: String!) {\n    login(input: { username: $username, password: $password, email: $email }) {\n      token\n      error\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Signup($username: String!, $email: String!, $password: String!) {\n    signup(input: { username: $username, password: $password, email: $email }) {\n      id\n      email\n      username\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation Signup($username: String!, $email: String!, $password: String!) {\n    signup(input: { username: $username, password: $password, email: $email }) {\n      id\n      email\n      username\n      error\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Logout($token: String, $allSessions: Boolean) {\n    logout(input: { token: $token, allSessions: $allSessions }) {\n      success\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($token: String, $allSessions: Boolean) {\n    logout(input: { token: $token, allSessions: $allSessions }) {\n      success\n      error\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ForgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      success\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation ForgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      success\n      error\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPassword($linkId: uuid!, $newPassword: String!) {\n    resetPassword(linkId: $linkId, newPassword: $newPassword) {\n      success\n      error\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPassword($linkId: uuid!, $newPassword: String!) {\n    resetPassword(linkId: $linkId, newPassword: $newPassword) {\n      success\n      error\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Me {\n    me {\n      email\n      created_at\n      id\n      role\n      updated_at\n      username\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      email\n      created_at\n      id\n      role\n      updated_at\n      username\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;