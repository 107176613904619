import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { Switch } from '@headlessui/react'
import clsx from 'clsx'

export interface ToggleProps {
  checked?: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
  name?: string
  id?: string
  disabled?: boolean
  leftLabel?: ReactNode
  rightLabel?: ReactNode
  leftDescription?: ReactNode
  rightDescription?: ReactNode
  neitherOff?: boolean
  className?: string
}

export const Toggle = ({
  leftLabel,
  rightLabel,
  leftDescription,
  rightDescription,
  checked,
  setChecked,
  neitherOff = false,
  id,
  name,
  disabled = false,
  className = '',
}: ToggleProps) => {
  return (
    <Switch.Group as="div" className={clsx(`inline-flex items-center justify-between`, className)}>
      {leftLabel && (
        <span
          className={clsx('flex flex-grow flex-col text-right opacity-100 transition-all duration-300', {
            'opacity-50': !!rightLabel && checked,
            'cursor-pointer': !disabled,
          })}
          onClick={() => setChecked(!rightLabel && !checked)}
        >
          <Switch.Label as="span" className="text-base leading-tight font-medium text-gray-900 dark:text-white" passive>
            {leftLabel}
          </Switch.Label>
          {leftDescription && (
            <Switch.Description as="span" className="text-sm leading-tight text-gray-400">
              {leftDescription}
            </Switch.Description>
          )}
        </span>
      )}
      <Switch
        id={id}
        name={name}
        checked={checked}
        onChange={setChecked}
        disabled={disabled}
        className={clsx(
          {
            'bg-primary-600': checked || neitherOff,
            'bg-gray-200 dark:bg-gray-700': !neitherOff && !checked,
            'ml-4': !!leftLabel,
            'mr-4': !!rightLabel,
          },
          'relative inline-flex h-6 w-11 flex-shrink-0 enabled:cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900 disabled:opacity-50'
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-gray-900 shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      {rightLabel && (
        <span
          className={clsx('flex flex-grow flex-col text-left opacity-100 transition-all duration-300', {
            'opacity-50': !!leftLabel && !checked,
            'cursor-pointer': !disabled,
          })}
          onClick={() => setChecked(!!leftLabel || !checked)}
        >
          <Switch.Label as="span" className="text-base leading-tight font-medium text-gray-900 dark:text-white" passive>
            {rightLabel}
          </Switch.Label>
          {rightDescription && (
            <Switch.Description as="span" className="text-sm leading-tight text-gray-400">
              {rightDescription}
            </Switch.Description>
          )}
        </span>
      )}
    </Switch.Group>
  )
}
