import { useRef } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

export const useOnlyOnce = (callback: () => any, condition = true) => {
  const hasRunOnce = useRef(false)
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useIsomorphicLayoutEffect(() => {
    if (!hasRunOnce.current && condition) {
      callbackRef.current?.()
      hasRunOnce.current = true
    }
  }, [condition])
}
