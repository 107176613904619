import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface RedirectProps {
  to: string
  state?: any
}

export const Redirect = ({ to, state }: RedirectProps) => {
  const location = useLocation()
  const goTo = useNavigate()

  useEffect(() => {
    if (to.includes('?')) {
      to = to.concat(location.search.replace('?', '&'))
    } else {
      to = to.concat(location.search)
    }

    goTo(to, { replace: true, state })
  }, [])

  return null
}
