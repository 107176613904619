import clsx from 'clsx'
import React, { ComponentProps } from 'react'

export interface ModalProps extends ComponentProps<'div'> {
  bgClass?: string
  overlayClasses?: string
  onClose(): void
}

let initialBgClass = 'bg-gray-900/70'
let initialOverlayClasses = 'fixed inset-0 flex justify-center items-center z-[999]'
let modalBaseClasses = 'shadow-md bg-white rounded-lg p-4 max-w-[95vw] max-h-[95vh] overflow-y-auto'

export const customizeModalClasses = ({
  bgClass: newBgClass,
  modalBaseClasses: newBase,
  overlayClasses: newOverlayClasses,
}: {
  bgClass?: string
  overlayClasses?: string
  modalBaseClasses?: string
}) => {
  if (typeof newBgClass === 'string') {
    initialBgClass = newBgClass
  }

  if (typeof newOverlayClasses === 'string') {
    initialOverlayClasses = newOverlayClasses
  }

  if (typeof newBase === 'string') {
    modalBaseClasses = newBase
  }
}

export const Modal = ({
  className = '',
  bgClass = initialBgClass,
  overlayClasses = initialOverlayClasses,
  onClose,
  onClick,
  children,
  ...props
}: ModalProps) => {
  return (
    <div className={clsx(bgClass, overlayClasses)} onClick={() => onClose()}>
      <div
        className={clsx(className, modalBaseClasses)}
        onClick={(e) => {
          e.stopPropagation()
          onClick?.(e)
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  )
}
