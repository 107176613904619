import { useEventListener } from '@8thday/react'
import { useAuthState } from './hooks/useAuthState'
import React from 'react'
import { useApolloClient } from '@apollo/client'

const accountsUrl =
  window.location.hostname === 'localhost' ? 'http://localhost:1001' : 'https://accounts.tehpsalmist.com'

export interface LoginPageProps {
  className?: string
}

export const LoginPage = ({ className = '' }: LoginPageProps) => {
  const client = useApolloClient()
  const { showLoginScreen, setShowLoginScreen, visitor } = useAuthState()

  useEventListener<MessageEvent>('message', (e) => {
    if (e.data.event === 'authChanged') {
      setShowLoginScreen(false)
      client.reFetchObservableQueries()
    }
  })

  return showLoginScreen && !visitor ? (
    <iframe
      style={{ position: 'fixed', inset: '0px', height: '100%', width: '100%', zIndex: 20, border: 'none' }}
      className={className}
      src={accountsUrl}
      sandbox="allow-scripts allow-same-origin allow-modals allow-popups"
    />
  ) : null
}
