import { useMutation } from '@apollo/client'
import React, { ComponentProps, useEffect, useState } from 'react'
import { graphql } from '../gql'
import { Button, Item, Select, TextInput, Toggle, toast } from '@8thday/react'
import { AllPlansSubscription } from '../gql/graphql'
import { bibleStructure, translations } from '../data'
import { Slider } from './Slider'
import { useAuthState } from '@tehpsalmist/tehpsalmist-auth'

let currentChapter = 0
const bibleOptions = Object.entries(bibleStructure).reduce<Record<string, Item[]>>((map, [book, chapters]) => {
  map[book] = chapters.map((v, i) => ({ label: i + 1, value: `${i + currentChapter}` }))
  currentChapter += chapters.length
  return map
}, {})
const translationItems = translations.map((tr) => ({ value: tr.code, label: tr.fullText }))

export interface PlanFormProps extends ComponentProps<'div'> {
  plan?: AllPlansSubscription['bible_reading_plan'][number]
  onCreate?(id: number): void
  onDelete?(): void
}

export const PlanForm = ({ className = '', plan, onCreate, onDelete, ...props }: PlanFormProps) => {
  const [name, setName] = useState(plan?.name ?? '')
  const [time, setTime] = useState(plan?.time ?? '')
  const [translation, setTranslation] = useState(plan?.translation ?? '')
  const [chapter, setChapter] = useState(plan?.next_chapter?.toString() ?? '')
  const [chapters, setChapters] = useState(plan?.chapters ?? 1)
  const [activeEmail, setActiveEmail] = useState(plan?.active_email ?? false)
  const [activeText, setActiveText] = useState(plan?.active_text ?? false)

  const { visitor } = useAuthState()

  const [createPlan] = useMutation(
    graphql(`
      mutation createPlan($object: bible_reading_plan_insert_input!) {
        insert_bible_reading_plan_one(object: $object) {
          id
        }
      }
    `)
  )

  const [updatePlan] = useMutation(
    graphql(`
      mutation updatePlan($id: Int!, $set: bible_reading_plan_set_input!) {
        update_bible_reading_plan_by_pk(pk_columns: { id: $id }, _set: $set) {
          id
        }
      }
    `)
  )

  const [deletePlan] = useMutation(
    graphql(`
      mutation DeleteBibleReadingPlan($id: Int!) {
        delete_bible_reading_plan_by_pk(id: $id) {
          id
        }
      }
    `)
  )

  const isDirty =
    name !== (plan?.name ?? '') ||
    time !== (plan?.time ?? '') ||
    translation !== (plan?.translation ?? '') ||
    chapter !== (plan?.next_chapter?.toString() ?? '') ||
    chapters !== (plan?.chapters ?? 1) ||
    activeEmail !== (plan?.active_email ?? false) ||
    activeText !== (plan?.active_text ?? false)

  const isValid = !!(name && time && translation && chapter && chapters)

  useEffect(() => {
    if (plan) {
      setName(plan.name ?? '')
      setTime(plan.time ?? '')
      setTranslation(plan.translation ?? '')
      setChapter(plan.next_chapter.toString() ?? '')
      setChapters(plan.chapters ?? 1)
      setActiveEmail(plan.active_email ?? false)
      setActiveText(plan.active_text ?? false)
    }
  }, [plan])

  return (
    <div className={`${className}`} {...props}>
      <form
        className="plan-form-grid mx-auto max-w-screen-lg gap-2 p-2 md:gap-4 md:p-4"
        onSubmit={async (e) => {
          e.preventDefault()
          e.stopPropagation()

          if (!plan) {
            const done = await createPlan({
              variables: {
                object: {
                  translation,
                  chapters,
                  name,
                  next_chapter: Number(chapter),
                  active_email: activeEmail,
                  active_text: activeText,
                  time,
                },
              },
            }).catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

            if (done instanceof Error) {
              toast.error({ message: 'Trouble creating your new reading plan', description: done.message })
            } else {
              toast.success({ message: 'Plan created!' })
              onCreate?.(done.data?.insert_bible_reading_plan_one?.id ?? 0)
            }
          } else {
            const done = await updatePlan({
              variables: {
                id: plan.id,
                set: {
                  translation,
                  chapters,
                  name,
                  next_chapter: Number(chapter),
                  active_email: activeEmail,
                  active_text: activeText,
                  time,
                },
              },
            }).catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

            if (done instanceof Error) {
              toast.error({ message: 'Trouble updating your reading plan', description: done.message })
            } else {
              toast.success({ message: 'Plan saved!' })
            }
          }
        }}
      >
        <TextInput className="plan-name" label="Plan Name" value={name} onChange={(e) => setName(e.target.value)} />
        <TextInput
          type="time"
          className="plan-time"
          label="Time of Day (24h)"
          description="Email delivery time each day"
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
        <Select
          label="Next Chapter"
          className="plan-next-chapter w-full"
          value={chapter}
          onValueChange={(e) => setChapter(e)}
          placeholder="Choose a starting chapter"
          items={bibleOptions}
          groupLabelInValue
        />
        <div className="plan-chapters mb-4 flex flex-col">
          <label htmlFor="chapters" className="mb-3 block text-base font-medium text-gray-700">
            Chapters per Day <span className="ml-4 text-lg font-bold text-primary-600">{chapters}</span>
          </label>
          <Slider
            className="w-full"
            id="chapters"
            value={[chapters]}
            onValueChange={([e]) => setChapters(e)}
            min={1}
            max={12}
            defaultValue={[2]}
            step={1}
          />
        </div>
        <Select
          label="Translation"
          className="plan-translation w-full"
          placeholder="Choose your translation"
          items={translationItems}
          value={translation}
          onValueChange={(e) => setTranslation(e)}
        />
        <div className="plan-email-reminders flex flex-col items-center sm:items-start">
          {visitor?.email_verified ? (
            <>
              <label htmlFor="activeEmail">Email Reminders</label>
              <Toggle
                checked={activeEmail}
                id="activeEmail"
                setChecked={setActiveEmail}
                rightLabel="On"
                leftLabel="Off"
              />
            </>
          ) : (
            <>
              <a href="https://accounts.tehpsalmist.com" target="_blank" rel="noreferrer noopener">
                <Button>Verify Email</Button>
              </a>
              <p className="mt-1 text-center text-xs text-gray-500">to enable email reminders</p>
            </>
          )}
        </div>
        <div className="plan-text-reminders flex flex-col items-center sm:items-start">
          {visitor?.phone_verified ? (
            <>
              <label htmlFor="activeText">Text Reminders</label>
              <Toggle checked={activeText} id="activeText" setChecked={setActiveText} rightLabel="On" leftLabel="Off" />
            </>
          ) : (
            <>
              <a href="https://accounts.tehpsalmist.com" target="_blank" rel="noreferrer noopener">
                <Button>Verify Phone</Button>
              </a>
              <p className="mt-1 text-center text-xs text-gray-500">to enable text reminders</p>
            </>
          )}
        </div>
        <div className="plan-actions mt-4 flex items-center justify-center gap-2 md:my-auto md:ml-auto md:mt-0">
          {plan && (
            <Button
              variant="destructive"
              onClick={async () => {
                if (confirm('Are you sure? This is not reversible.')) {
                  const res = await deletePlan({ variables: { id: plan.id } }).catch((err) =>
                    err instanceof Error ? err : new Error(JSON.stringify(err))
                  )

                  if (res instanceof Error) {
                    return toast.error({ message: 'Could not delete plan', description: res.message })
                  }

                  onDelete?.()
                  toast.success({ message: 'Plan deleted!' })
                }
              }}
            >
              Delete
            </Button>
          )}
          <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
            Save Plan
          </Button>
        </div>
      </form>
    </div>
  )
}
