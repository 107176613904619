import React, { ComponentProps, Fragment, MouseEvent, ReactNode, forwardRef, useMemo, useState } from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import clsx from 'clsx'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

export type Item = { value: string; label: ReactNode }
export type ItemList = Item[] | Record<string, Item[]>

export interface SelectProps extends RadixSelect.SelectProps {
  placeholder?: string
  items: ItemList
  'aria-label'?: string
  groupLabelInValue?: boolean
  emptyItem?: Item
  id?: string
  label?: ReactNode
  className?: string
  labelClass?: string
  hideLabel?: boolean
  onClick?(e: MouseEvent): void
  collapseDescriptionArea?: boolean
  description?: ReactNode
  errorMessage?: ReactNode
}

export const Select = ({
  placeholder = 'Choose One',
  items,
  'aria-label': ariaLabel,
  groupLabelInValue = false,
  emptyItem,
  id,
  label,
  className = '',
  labelClass = '',
  hideLabel = false,
  children,
  onClick,
  collapseDescriptionArea = false,
  description,
  errorMessage,
  ...props
}: SelectProps) => {
  const [touched, setTouched] = useState(false)

  const requiredAndBlank = !props.value && touched && props.required
  const errorState = !!errorMessage || requiredAndBlank

  const hasGroups = !Array.isArray(items)

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className={clsx('block text-base font-medium text-gray-700', labelClass, { 'sr-only': hideLabel })}
        >
          {label}
          {props.required && (
            <span className={clsx({ 'text-emerald-400': !requiredAndBlank, 'text-red-400': requiredAndBlank })}>
              {' '}
              *
            </span>
          )}
        </label>
      )}
      <RadixSelect.Root {...props}>
        <RadixSelect.Trigger
          id={id}
          className={clsx(
            'w-full flex items-center justify-between gap-2 rounded-md bg-white py-2 px-3 text-primary-950 outline-none border focus:shadow-primary-500 data-[placeholder]:text-primary-300 border-primary-300 text-primary-900 focus:border-primary-500 focus:ring-1 shadow-sm focus:ring-primary-500',
            { 'mt-1': !!label }
          )}
          aria-label={ariaLabel}
          onClick={(e) => {
            !touched && setTouched(true)
            onClick?.(e)
          }}
        >
          <RadixSelect.Value asChild>
            <LabelDisplay value={props.value} items={items} placeholder={placeholder} />
          </RadixSelect.Value>
          <RadixSelect.Icon className="h-5 w-5 text-primary-950">
            <ChevronDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal className="z-[999999]">
          <RadixSelect.Content className="overflow-hidden rounded-md bg-white shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
            <RadixSelect.ScrollUpButton className="flex h-6 cursor-default items-center justify-center bg-white text-primary-950">
              <ChevronUpIcon className="h-5 w-5" />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className="p-1">
              {children}
              {emptyItem && (
                <>
                  <SelectItem className="text-gray-400" value={emptyItem.value}>
                    {emptyItem.label}
                  </SelectItem>
                  <RadixSelect.Separator className="m-1 h-px bg-primary-300" />
                </>
              )}
              {hasGroups &&
                Object.entries(items ?? {}).map(([groupLabel, subitems], i) => (
                  <Fragment key={groupLabel}>
                    {i !== 0 && <RadixSelect.Separator className="m-1 h-px bg-primary-300" />}
                    <RadixSelect.Group>
                      <RadixSelect.Label className="px-6 text-xs leading-6 text-gray-700">
                        {groupLabel}
                      </RadixSelect.Label>
                      {subitems.map(({ value, label }, i) => (
                        <SelectItem key={i} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </RadixSelect.Group>
                  </Fragment>
                ))}
              {!hasGroups &&
                items.map(({ value, label }) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className="flex h-6 cursor-default items-center justify-center bg-white text-primary-950">
              <ChevronDownIcon className="h-5 w-5" />
            </RadixSelect.ScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
      {!collapseDescriptionArea && (
        <p
          className={clsx('mt-1 text-sm', {
            'text-red-600': errorState,
            'text-gray-500': description && !errorState,
            'h-5': !description && !errorState && !collapseDescriptionArea,
          })}
          id={`${id}-description`}
        >
          {errorMessage || (requiredAndBlank && 'This field is required.') || description}
        </p>
      )}
    </div>
  )
}

const findItem = (itemList?: ItemList, value?: string) => {
  if (!value) {
    return { groupLabel: undefined, item: undefined }
  }

  if (Array.isArray(itemList)) {
    return { groupLabel: undefined, item: itemList.find((i) => i.value === value) }
  }

  for (const groupLabel of Object.keys(itemList ?? {})) {
    const foundItem = itemList?.[groupLabel]?.find((i) => i.value === value)

    if (foundItem) {
      return { groupLabel, item: foundItem }
    }
  }

  return { groupLabel: undefined, item: undefined }
}

interface LabelDisplayProps extends ComponentProps<'div'> {
  value?: string
  placeholder?: string
  items?: ItemList
}

const LabelDisplay = forwardRef<HTMLDivElement, LabelDisplayProps>(
  ({ value, items, placeholder, className = '', ...props }, ref) => {
    const { item, groupLabel } = useMemo(() => findItem(items, value), [items, value])

    const displayValue = item ? `${groupLabel ? `${groupLabel} ` : ''}${item?.label}` : placeholder

    return (
      <div ref={ref} className={clsx(className, !item && 'text-primary-500')} {...props}>
        {displayValue}
      </div>
    )
  }
)

const SelectItem = forwardRef<HTMLDivElement, RadixSelect.SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={clsx(
          'relative flex h-6 select-none items-center rounded-sm pl-6 pr-9 text-sm leading-none text-primary-950 data-[disabled]:pointer-events-none data-[highlighted]:bg-primary-500 data-[disabled]:text-gray-400 data-[highlighted]:text-primary-50 data-[highlighted]:outline-none',
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
          <CheckIcon className="h-5 w-5" />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    )
  }
)
