import clsx from 'clsx'
import React, { ComponentPropsWithoutRef, ElementType } from 'react'

const paddings = {
  sm: 'p-2',
  md: 'p-4',
  lg: 'p-8',
}

const shadows = {
  none: '',
  normal: 'shadow',
  sm: 'shadow-sm',
  md: 'shadow-md',
  lg: 'shadow-lg',
}

export type CardProps<C extends ElementType = 'div'> = {
  as?: C
  padding?: keyof typeof paddings
  shadow?: keyof typeof shadows
} & ComponentPropsWithoutRef<C>

export const Card = <C extends ElementType>({
  className = '',
  padding = 'md',
  shadow = 'normal',
  as,
  ...props
}: CardProps<C>) => {
  const As = as || 'div'

  return <As className={clsx('rounded-md bg-white', className, paddings[padding], shadows[shadow])} {...props} />
}
