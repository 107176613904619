import React from 'react'
import * as RadixSlider from '@radix-ui/react-slider'
import clsx from 'clsx'

export interface SliderProps extends RadixSlider.SliderProps {}

export const Slider = ({ className = '', ...props }: SliderProps) => {
  return (
    <RadixSlider.Root
      className={clsx(className, 'relative flex h-5 w-[200px] touch-none select-none items-center')}
      {...props}
    >
      <RadixSlider.Track className="relative h-[3px] grow rounded-full bg-black/60">
        <RadixSlider.Range className="absolute h-full rounded-full bg-primary-500" />
      </RadixSlider.Track>
      <RadixSlider.Thumb
        className="shadow-blackA7 block h-5 w-5 rounded-[10px] bg-primary-400 shadow-[0_1px_5px] hover:bg-primary-300 focus:shadow-[0_0_0_5px] focus:shadow-black/50 focus:outline-none"
        aria-label="Volume"
      />
    </RadixSlider.Root>
  )
}
