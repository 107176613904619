export const carriers = {
  'message.alltel.com': 'Alltel',
  'txt.att.net': 'AT&T',
  'myboostmobile.com': 'Boost Mobile',
  'sms.cricketwireless.net': 'Cricket Wireless',
  'msg.fi.google.com': 'Project Fi',
  'text.republicwireless.com': 'Republic Wireless',
  'messaging.sprintpcs.com': 'Sprint',
  'tmomail.net': 'T-Mobile',
  'email.uscc.net': 'U.S. Cellular',
  'vtext.com': 'Verizon',
  'vmobl.com': 'Virgin Mobile',
}
