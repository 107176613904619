import { gql } from '@apollo/client'

export const ME = gql`
  query Me {
    me {
      email
      created_at
      id
      role
      updated_at
      username
      phone
      phone_carrier
      email_verified
      phone_verified
      timezone
    }
  }
`
