import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import React, { Dispatch, createContext, useContext, useState } from 'react'
import { FORGOT_PASSWORD, LOGIN, LOGOUT, ME, SIGNUP } from '../graphql'

const authStateContext = createContext<{
  visitor: {
    __typename?: 'Me'
    created_at?: string
    email?: string
    email_verified?: boolean
    id?: number
    name?: string
    phone?: string
    phone_carrier?: string
    phone_verified?: boolean
    role?: string
    updated_at?: string
    username?: string
    timezone?: string
  } | null
  logout(): Promise<void | string>
  login(payload: { email?: string; username?: string; password: string }): Promise<void | string>
  join(payload: { email?: string; username?: string; password: string }): Promise<void | string>
  requestPasswordReset(email: string): Promise<{ success: boolean; error?: string | null }>
  showLoginScreen: boolean
  setShowLoginScreen: Dispatch<boolean>
  loading: boolean
}>({
  visitor: null,
  async logout() {},
  async login(_) {},
  async join(_) {},
  async requestPasswordReset(_) {
    return { success: false }
  },
  showLoginScreen: false,
  setShowLoginScreen(_) {},
  loading: true,
})

const Provider = authStateContext.Provider

export const AuthStateProvider = ({ children }) => {
  const client = useApolloClient()
  const [showLoginScreen, setShowLoginScreen] = useState(false)

  const { data, loading, refetch, updateQuery } = useQuery(ME, {
    client,
    skip: typeof window === 'undefined',
  })

  const onAuthChange = async () => {
    try {
      const { errors } = await refetch()

      if (errors) {
        updateQuery(() => ({ me: null }))
      }

      if (typeof window.parent?.postMessage === 'function') {
        window.parent.postMessage({ event: 'authChanged' }, '*')
      }
    } catch (e) {
      updateQuery(() => ({ me: null }))
    }
  }

  const [authenticate] = useMutation(LOGIN, { client })
  const [signup] = useMutation(SIGNUP, { client })
  const [deleteSession] = useMutation(LOGOUT, { client })
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, { client })

  const login = async ({ email = '', username = '', password = '' }) => {
    const { data } = await authenticate({
      variables: { email, username, password },
    })

    if (data?.login?.error) {
      return data.login.error
    }

    onAuthChange()
  }

  const join = async ({ email = '', username = '', password = '' }) => {
    const { data, errors } = await signup({
      variables: { email, username, password },
    })

    if (errors) {
      return errors[0].message
    }

    if (data?.signup?.error) {
      return data.signup.error
    }

    return login({ email, username, password })
  }

  const logout = async () => {
    const { errors } = await deleteSession()

    if (errors) {
      return errors[0].message
    }

    onAuthChange()
  }

  const requestPasswordReset = async (email) => {
    const { data } = await forgotPassword({ variables: { email } })

    return data?.forgotPassword ?? { success: false }
  }

  return (
    <Provider
      value={{
        visitor: data?.me ?? null,
        login,
        logout,
        join,
        requestPasswordReset,
        showLoginScreen,
        setShowLoginScreen,
        loading,
      }}
    >
      {children}
    </Provider>
  )
}

export const useAuthState = () => useContext(authStateContext)
