export const ESVBibleArray: string[] = [
  '01001031',
  '01002025',
  '01003024',
  '01004026',
  '01005032',
  '01006022',
  '01007024',
  '01008022',
  '01009029',
  '01010032',
  '01011032',
  '01012020',
  '01013018',
  '01014024',
  '01015021',
  '01016016',
  '01017027',
  '01018033',
  '01019038',
  '01020018',
  '01021034',
  '01022024',
  '01023020',
  '01024067',
  '01025034',
  '01026035',
  '01027046',
  '01028022',
  '01029035',
  '01030043',
  '01031055',
  '01032032',
  '01033020',
  '01034031',
  '01035029',
  '01036043',
  '01037036',
  '01038030',
  '01039023',
  '01040023',
  '01041057',
  '01042038',
  '01043034',
  '01044034',
  '01045028',
  '01046034',
  '01047031',
  '01048022',
  '01049033',
  '01050026',
  '02001022',
  '02002025',
  '02003022',
  '02004031',
  '02005023',
  '02006030',
  '02007025',
  '02008032',
  '02009035',
  '02010029',
  '02011010',
  '02012051',
  '02013022',
  '02014031',
  '02015027',
  '02016036',
  '02017016',
  '02018027',
  '02019025',
  '02020026',
  '02021036',
  '02022031',
  '02023033',
  '02024018',
  '02025040',
  '02026037',
  '02027021',
  '02028043',
  '02029046',
  '02030038',
  '02031018',
  '02032035',
  '02033023',
  '02034035',
  '02035035',
  '02036038',
  '02037029',
  '02038031',
  '02039043',
  '02040038',
  '03001017',
  '03002016',
  '03003017',
  '03004035',
  '03005019',
  '03006030',
  '03007038',
  '03008036',
  '03009024',
  '03010020',
  '03011047',
  '03012008',
  '03013059',
  '03014057',
  '03015033',
  '03016034',
  '03017016',
  '03018030',
  '03019037',
  '03020027',
  '03021024',
  '03022033',
  '03023044',
  '03024023',
  '03025055',
  '03026046',
  '03027034',
  '04001054',
  '04002034',
  '04003051',
  '04004049',
  '04005031',
  '04006027',
  '04007089',
  '04008026',
  '04009023',
  '04010036',
  '04011035',
  '04012016',
  '04013033',
  '04014045',
  '04015041',
  '04016050',
  '04017013',
  '04018032',
  '04019022',
  '04020029',
  '04021035',
  '04022041',
  '04023030',
  '04024025',
  '04025018',
  '04026065',
  '04027023',
  '04028031',
  '04029040',
  '04030016',
  '04031054',
  '04032042',
  '04033056',
  '04034029',
  '04035034',
  '04036013',
  '05001046',
  '05002037',
  '05003029',
  '05004049',
  '05005033',
  '05006025',
  '05007026',
  '05008020',
  '05009029',
  '05010022',
  '05011032',
  '05012032',
  '05013018',
  '05014029',
  '05015023',
  '05016022',
  '05017020',
  '05018022',
  '05019021',
  '05020020',
  '05021023',
  '05022030',
  '05023025',
  '05024022',
  '05025019',
  '05026019',
  '05027026',
  '05028068',
  '05029029',
  '05030020',
  '05031030',
  '05032052',
  '05033029',
  '05034012',
  '06001018',
  '06002024',
  '06003017',
  '06004024',
  '06005015',
  '06006027',
  '06007026',
  '06008035',
  '06009027',
  '06010043',
  '06011023',
  '06012024',
  '06013033',
  '06014015',
  '06015063',
  '06016010',
  '06017018',
  '06018028',
  '06019051',
  '06020009',
  '06021045',
  '06022034',
  '06023016',
  '06024033',
  '07001036',
  '07002023',
  '07003031',
  '07004024',
  '07005031',
  '07006040',
  '07007025',
  '07008035',
  '07009057',
  '07010018',
  '07011040',
  '07012015',
  '07013025',
  '07014020',
  '07015020',
  '07016031',
  '07017013',
  '07018031',
  '07019030',
  '07020048',
  '07021025',
  '08001022',
  '08002023',
  '08003018',
  '08004022',
  '09001028',
  '09002036',
  '09003021',
  '09004022',
  '09005012',
  '09006021',
  '09007017',
  '09008022',
  '09009027',
  '09010027',
  '09011015',
  '09012025',
  '09013023',
  '09014052',
  '09015035',
  '09016023',
  '09017058',
  '09018030',
  '09019024',
  '09020042',
  '09021015',
  '09022023',
  '09023029',
  '09024022',
  '09025044',
  '09026025',
  '09027012',
  '09028025',
  '09029011',
  '09030031',
  '09031013',
  '10001027',
  '10002032',
  '10003039',
  '10004012',
  '10005025',
  '10006023',
  '10007029',
  '10008018',
  '10009013',
  '10010019',
  '10011027',
  '10012031',
  '10013039',
  '10014033',
  '10015037',
  '10016023',
  '10017029',
  '10018033',
  '10019043',
  '10020026',
  '10021022',
  '10022051',
  '10023039',
  '10024025',
  '11001053',
  '11002046',
  '11003028',
  '11004034',
  '11005018',
  '11006038',
  '11007051',
  '11008066',
  '11009028',
  '11010029',
  '11011043',
  '11012033',
  '11013034',
  '11014031',
  '11015034',
  '11016034',
  '11017024',
  '11018046',
  '11019021',
  '11020043',
  '11021029',
  '11022053',
  '12001018',
  '12002025',
  '12003027',
  '12004044',
  '12005027',
  '12006033',
  '12007020',
  '12008029',
  '12009037',
  '12010036',
  '12011021',
  '12012021',
  '12013025',
  '12014029',
  '12015038',
  '12016020',
  '12017041',
  '12018037',
  '12019037',
  '12020021',
  '12021026',
  '12022020',
  '12023037',
  '12024020',
  '12025030',
  '13001054',
  '13002055',
  '13003024',
  '13004043',
  '13005026',
  '13006081',
  '13007040',
  '13008040',
  '13009044',
  '13010014',
  '13011047',
  '13012040',
  '13013014',
  '13014017',
  '13015029',
  '13016043',
  '13017027',
  '13018017',
  '13019019',
  '13020008',
  '13021030',
  '13022019',
  '13023032',
  '13024031',
  '13025031',
  '13026032',
  '13027034',
  '13028021',
  '13029030',
  '14001017',
  '14002018',
  '14003017',
  '14004022',
  '14005014',
  '14006042',
  '14007022',
  '14008018',
  '14009031',
  '14010019',
  '14011023',
  '14012016',
  '14013022',
  '14014015',
  '14015019',
  '14016014',
  '14017019',
  '14018034',
  '14019011',
  '14020037',
  '14021020',
  '14022012',
  '14023021',
  '14024027',
  '14025028',
  '14026023',
  '14027009',
  '14028027',
  '14029036',
  '14030027',
  '14031021',
  '14032033',
  '14033025',
  '14034033',
  '14035027',
  '14036023',
  '15001011',
  '15002070',
  '15003013',
  '15004024',
  '15005017',
  '15006022',
  '15007028',
  '15008036',
  '15009015',
  '15010044',
  '16001011',
  '16002020',
  '16003032',
  '16004023',
  '16005019',
  '16006019',
  '16007073',
  '16008018',
  '16009038',
  '16010039',
  '16011036',
  '16012047',
  '16013031',
  '17001022',
  '17002023',
  '17003015',
  '17004017',
  '17005014',
  '17006014',
  '17007010',
  '17008017',
  '17009032',
  '17010003',
  '18001022',
  '18002013',
  '18003026',
  '18004021',
  '18005027',
  '18006030',
  '18007021',
  '18008022',
  '18009035',
  '18010022',
  '18011020',
  '18012025',
  '18013028',
  '18014022',
  '18015035',
  '18016022',
  '18017016',
  '18018021',
  '18019029',
  '18020029',
  '18021034',
  '18022030',
  '18023017',
  '18024025',
  '18025006',
  '18026014',
  '18027023',
  '18028028',
  '18029025',
  '18030031',
  '18031040',
  '18032022',
  '18033033',
  '18034037',
  '18035016',
  '18036033',
  '18037024',
  '18038041',
  '18039030',
  '18040024',
  '18041034',
  '18042017',
  '19001006',
  '19002012',
  '19003008',
  '19004008',
  '19005012',
  '19006010',
  '19007017',
  '19008009',
  '19009020',
  '19010018',
  '19011007',
  '19012008',
  '19013006',
  '19014007',
  '19015005',
  '19016011',
  '19017015',
  '19018050',
  '19019014',
  '19020009',
  '19021013',
  '19022031',
  '19023006',
  '19024010',
  '19025022',
  '19026012',
  '19027014',
  '19028009',
  '19029011',
  '19030012',
  '19031024',
  '19032011',
  '19033022',
  '19034022',
  '19035028',
  '19036012',
  '19037040',
  '19038022',
  '19039013',
  '19040017',
  '19041013',
  '19042011',
  '19043005',
  '19044026',
  '19045017',
  '19046011',
  '19047009',
  '19048014',
  '19049020',
  '19050023',
  '19051019',
  '19052009',
  '19053006',
  '19054007',
  '19055023',
  '19056013',
  '19057011',
  '19058011',
  '19059017',
  '19060012',
  '19061008',
  '19062012',
  '19063011',
  '19064010',
  '19065013',
  '19066020',
  '19067007',
  '19068035',
  '19069036',
  '19070005',
  '19071024',
  '19072020',
  '19073028',
  '19074023',
  '19075010',
  '19076012',
  '19077020',
  '19078072',
  '19079013',
  '19080019',
  '19081016',
  '19082008',
  '19083018',
  '19084012',
  '19085013',
  '19086017',
  '19087007',
  '19088018',
  '19089052',
  '19090017',
  '19091016',
  '19092015',
  '19093005',
  '19094023',
  '19095011',
  '19096013',
  '19097012',
  '19098009',
  '19099009',
  '19100005',
  '19101008',
  '19102028',
  '19103022',
  '19104035',
  '19105045',
  '19106048',
  '19107043',
  '19108013',
  '19109031',
  '19110007',
  '19111010',
  '19112010',
  '19113009',
  '19114008',
  '19115018',
  '19116019',
  '19117002',
  '19118029',
  '19119176',
  '19120007',
  '19121008',
  '19122009',
  '19123004',
  '19124008',
  '19125005',
  '19126006',
  '19127005',
  '19128006',
  '19129008',
  '19130008',
  '19131003',
  '19132018',
  '19133003',
  '19134003',
  '19135021',
  '19136026',
  '19137009',
  '19138008',
  '19139024',
  '19140013',
  '19141010',
  '19142007',
  '19143012',
  '19144015',
  '19145021',
  '19146010',
  '19147020',
  '19148014',
  '19149009',
  '19150006',
  '20001033',
  '20002022',
  '20003035',
  '20004027',
  '20005023',
  '20006035',
  '20007027',
  '20008036',
  '20009018',
  '20010032',
  '20011031',
  '20012028',
  '20013025',
  '20014035',
  '20015033',
  '20016033',
  '20017028',
  '20018024',
  '20019029',
  '20020030',
  '20021031',
  '20022029',
  '20023035',
  '20024034',
  '20025028',
  '20026028',
  '20027027',
  '20028028',
  '20029027',
  '20030033',
  '20031031',
  '21001018',
  '21002026',
  '21003022',
  '21004016',
  '21005020',
  '21006012',
  '21007029',
  '21008017',
  '21009018',
  '21010020',
  '21011010',
  '21012014',
  '22001017',
  '22002017',
  '22003011',
  '22004016',
  '22005016',
  '22006013',
  '22007013',
  '22008014',
  '23001031',
  '23002022',
  '23003026',
  '23004006',
  '23005030',
  '23006013',
  '23007025',
  '23008022',
  '23009021',
  '23010034',
  '23011016',
  '23012006',
  '23013022',
  '23014032',
  '23015009',
  '23016014',
  '23017014',
  '23018007',
  '23019025',
  '23020006',
  '23021017',
  '23022025',
  '23023018',
  '23024023',
  '23025012',
  '23026021',
  '23027013',
  '23028029',
  '23029024',
  '23030033',
  '23031009',
  '23032020',
  '23033024',
  '23034017',
  '23035010',
  '23036022',
  '23037038',
  '23038022',
  '23039008',
  '23040031',
  '23041029',
  '23042025',
  '23043028',
  '23044028',
  '23045025',
  '23046013',
  '23047015',
  '23048022',
  '23049026',
  '23050011',
  '23051023',
  '23052015',
  '23053012',
  '23054017',
  '23055013',
  '23056012',
  '23057021',
  '23058014',
  '23059021',
  '23060022',
  '23061011',
  '23062012',
  '23063019',
  '23064012',
  '23065025',
  '23066024',
  '24001019',
  '24002037',
  '24003025',
  '24004031',
  '24005031',
  '24006030',
  '24007034',
  '24008022',
  '24009026',
  '24010025',
  '24011023',
  '24012017',
  '24013027',
  '24014022',
  '24015021',
  '24016021',
  '24017027',
  '24018023',
  '24019015',
  '24020018',
  '24021014',
  '24022030',
  '24023040',
  '24024010',
  '24025038',
  '24026024',
  '24027022',
  '24028017',
  '24029032',
  '24030024',
  '24031040',
  '24032044',
  '24033026',
  '24034022',
  '24035019',
  '24036032',
  '24037021',
  '24038028',
  '24039018',
  '24040016',
  '24041018',
  '24042022',
  '24043013',
  '24044030',
  '24045005',
  '24046028',
  '24047007',
  '24048047',
  '24049039',
  '24050046',
  '24051064',
  '24052034',
  '25001022',
  '25002022',
  '25003066',
  '25004022',
  '25005022',
  '26001028',
  '26002010',
  '26003027',
  '26004017',
  '26005017',
  '26006014',
  '26007027',
  '26008018',
  '26009011',
  '26010022',
  '26011025',
  '26012028',
  '26013023',
  '26014023',
  '26015008',
  '26016063',
  '26017024',
  '26018032',
  '26019014',
  '26020049',
  '26021032',
  '26022031',
  '26023049',
  '26024027',
  '26025017',
  '26026021',
  '26027036',
  '26028026',
  '26029021',
  '26030026',
  '26031018',
  '26032032',
  '26033033',
  '26034031',
  '26035015',
  '26036038',
  '26037028',
  '26038023',
  '26039029',
  '26040049',
  '26041026',
  '26042020',
  '26043027',
  '26044031',
  '26045025',
  '26046024',
  '26047023',
  '26048035',
  '27001021',
  '27002049',
  '27003030',
  '27004037',
  '27005031',
  '27006028',
  '27007028',
  '27008027',
  '27009027',
  '27010021',
  '27011045',
  '27012013',
  '28001011',
  '28002023',
  '28003005',
  '28004019',
  '28005015',
  '28006011',
  '28007016',
  '28008014',
  '28009017',
  '28010015',
  '28011012',
  '28012014',
  '28013016',
  '28014009',
  '29001020',
  '29002032',
  '29003021',
  '30001015',
  '30002016',
  '30003015',
  '30004013',
  '30005027',
  '30006014',
  '30007017',
  '30008014',
  '30009015',
  '31001021',
  '32001017',
  '32002010',
  '32003010',
  '32004011',
  '33001016',
  '33002013',
  '33003012',
  '33004013',
  '33005015',
  '33006016',
  '33007020',
  '34001015',
  '34002013',
  '34003019',
  '35001017',
  '35002020',
  '35003019',
  '36001018',
  '36002015',
  '36003020',
  '37001015',
  '37002023',
  '38001021',
  '38002013',
  '38003010',
  '38004014',
  '38005011',
  '38006015',
  '38007014',
  '38008023',
  '38009017',
  '38010012',
  '38011017',
  '38012014',
  '38013009',
  '38014021',
  '39001014',
  '39002017',
  '39003018',
  '39004006',
  '40001025',
  '40002023',
  '40003017',
  '40004025',
  '40005048',
  '40006034',
  '40007029',
  '40008034',
  '40009038',
  '40010042',
  '40011030',
  '40012050',
  '40013058',
  '40014036',
  '40015039',
  '40016028',
  '40017027',
  '40018035',
  '40019030',
  '40020034',
  '40021046',
  '40022046',
  '40023039',
  '40024051',
  '40025046',
  '40026075',
  '40027066',
  '40028020',
  '41001045',
  '41002028',
  '41003035',
  '41004041',
  '41005043',
  '41006056',
  '41007037',
  '41008038',
  '41009050',
  '41010052',
  '41011033',
  '41012044',
  '41013037',
  '41014072',
  '41015047',
  '41016020',
  '42001080',
  '42002052',
  '42003038',
  '42004044',
  '42005039',
  '42006049',
  '42007050',
  '42008056',
  '42009062',
  '42010042',
  '42011054',
  '42012059',
  '42013035',
  '42014035',
  '42015032',
  '42016031',
  '42017037',
  '42018043',
  '42019048',
  '42020047',
  '42021038',
  '42022071',
  '42023056',
  '42024053',
  '43001051',
  '43002025',
  '43003036',
  '43004054',
  '43005047',
  '43006071',
  '43007053',
  '43008059',
  '43009041',
  '43010042',
  '43011057',
  '43012050',
  '43013038',
  '43014031',
  '43015027',
  '43016033',
  '43017026',
  '43018040',
  '43019042',
  '43020031',
  '43021025',
  '44001026',
  '44002047',
  '44003026',
  '44004037',
  '44005042',
  '44006015',
  '44007060',
  '44008040',
  '44009043',
  '44010048',
  '44011030',
  '44012025',
  '44013052',
  '44014028',
  '44015041',
  '44016040',
  '44017034',
  '44018028',
  '44019041',
  '44020038',
  '44021040',
  '44022030',
  '44023035',
  '44024027',
  '44025027',
  '44026032',
  '44027044',
  '44028031',
  '45001032',
  '45002029',
  '45003031',
  '45004025',
  '45005021',
  '45006023',
  '45007025',
  '45008039',
  '45009033',
  '45010021',
  '45011036',
  '45012021',
  '45013014',
  '45014023',
  '45015033',
  '45016027',
  '46001031',
  '46002016',
  '46003023',
  '46004021',
  '46005013',
  '46006020',
  '46007040',
  '46008013',
  '46009027',
  '46010033',
  '46011034',
  '46012031',
  '46013013',
  '46014040',
  '46015058',
  '46016024',
  '47001024',
  '47002017',
  '47003018',
  '47004018',
  '47005021',
  '47006018',
  '47007016',
  '47008024',
  '47009015',
  '47010018',
  '47011033',
  '47012021',
  '47013014',
  '48001024',
  '48002021',
  '48003029',
  '48004031',
  '48005026',
  '48006018',
  '49001023',
  '49002022',
  '49003021',
  '49004032',
  '49005033',
  '49006024',
  '50001030',
  '50002030',
  '50003021',
  '50004023',
  '51001029',
  '51002023',
  '51003025',
  '51004018',
  '52001010',
  '52002020',
  '52003013',
  '52004018',
  '52005028',
  '53001012',
  '53002017',
  '53003018',
  '54001020',
  '54002015',
  '54003016',
  '54004016',
  '54005025',
  '54006021',
  '55001018',
  '55002026',
  '55003017',
  '55004022',
  '56001016',
  '56002015',
  '56003015',
  '57001025',
  '58001014',
  '58002018',
  '58003019',
  '58004016',
  '58005014',
  '58006020',
  '58007028',
  '58008013',
  '58009028',
  '58010039',
  '58011040',
  '58012029',
  '58013025',
  '59001027',
  '59002026',
  '59003018',
  '59004017',
  '59005020',
  '60001025',
  '60002025',
  '60003022',
  '60004019',
  '60005014',
  '61001021',
  '61002022',
  '61003018',
  '62001010',
  '62002029',
  '62003024',
  '62004021',
  '62005021',
  '63001013',
  '64001014',
  '65001025',
  '66001020',
  '66002029',
  '66003022',
  '66004011',
  '66005014',
  '66006017',
  '66007017',
  '66008013',
  '66009021',
  '66010011',
  '66011019',
  '66012017',
  '66013018',
  '66014020',
  '66015008',
  '66016021',
  '66017018',
  '66018024',
  '66019021',
  '66020015',
  '66021027',
  '66022021'
]
