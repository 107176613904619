import { useMutation, useSubscription } from '@apollo/client'
import React, { ComponentProps, useState } from 'react'
import { graphql } from '../gql'
import { PlanForm } from './PlanForm'
import { Button } from '@8thday/react'
import {
  ChevronUpIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  NoSymbolIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

export interface PlansProps extends ComponentProps<'div'> {}

export const Plans = ({ className = '', ...props }: PlansProps) => {
  const { id } = useParams()
  const openPlanId = id ? Number(id) : 0
  const [addingPlan, setAddingPlan] = useState(false)

  const { data } = useSubscription(
    graphql(`
      subscription allPlans {
        bible_reading_plan(order_by: { created_at: asc }) {
          id
          chapters
          name
          active_email
          active_text
          legacy_id
          legacy_email
          legacy_phone
          legacy_carrier
          next_chapter
          last_chapter
          first_day
          time
          translation
        }
      }
    `)
  )

  const goTo = useNavigate()

  return (
    <div className={`${className} p-2`} {...props}>
      <ul>
        {data?.bible_reading_plan.map((p) => (
          <li className="cursor-pointer border-b border-gray-300" key={p.id}>
            {openPlanId === p.id ? (
              <>
                <NavLink
                  to="/plans"
                  className="flex w-full cursor-pointer justify-between duration-300 hover:bg-primary-50 hover:text-primary-800 focus:bg-primary-50 focus:text-primary-500 focus:outline-none"
                >
                  <ChevronUpIcon className="h-8 w-8" />
                  <ChevronUpIcon className="h-8 w-8" />
                </NavLink>
                <PlanForm
                  className="cursor-default"
                  plan={p}
                  onDelete={() => {
                    goTo(`/plans`)
                  }}
                />
              </>
            ) : (
              <NavLink to={`/plans/${p.id}`} className="flex items-center py-3 focus:bg-primary-50 focus:outline-none">
                <span className="text-lg font-semibold text-primary-700">{p.name}</span>
                <span className="ml-4 mr-auto text-gray-500">{p.translation}</span>
                <span className="flex">
                  {p.active_email && <EnvelopeIcon className="h-5 w-5 text-primary-500" />}
                  {p.active_text && <DevicePhoneMobileIcon className="h-5 w-5 text-primary-500" />}
                  {!p.active_email && !p.active_text && <NoSymbolIcon className="h-5 w-5 text-red-400" />}
                </span>
              </NavLink>
            )}
          </li>
        ))}
        <li className="border-b border-gray-300 py-3">
          <Button
            variant={addingPlan ? 'dismissive' : 'primary'}
            PreIcon={addingPlan ? XMarkIcon : PlusIcon}
            onClick={() => setAddingPlan((a) => !a)}
          >
            {addingPlan ? 'Cancel' : 'Add Plan'}
          </Button>
          {addingPlan && (
            <PlanForm
              onCreate={(newId) => {
                goTo(`/plans/${newId}`)
                setAddingPlan(false)
              }}
            />
          )}
        </li>
      </ul>
      <section className="mt-8">
        <p className="text-center text-gray-600">
          To add/verify email and phone number{' '}
          <a
            className="text-primary-500 underline hover:text-primary-400 focus:outline-none"
            href="https://accounts.tehpsalmist.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Manage your tehpsalmist.com account
          </a>
          .<br />
          Note that for now all timezones are currently US/Eastern.
        </p>
      </section>
    </div>
  )
}
