export const bibleStructure = {
  Genesis: [
    'Genesis1:1-31',
    'Genesis2:1-25',
    'Genesis3:1-24',
    'Genesis4:1-26',
    'Genesis5:1-32',
    'Genesis6:1-22',
    'Genesis7:1-24',
    'Genesis8:1-22',
    'Genesis9:1-29',
    'Genesis10:1-32',
    'Genesis11:1-32',
    'Genesis12:1-20',
    'Genesis13:1-18',
    'Genesis14:1-24',
    'Genesis15:1-21',
    'Genesis16:1-16',
    'Genesis17:1-27',
    'Genesis18:1-33',
    'Genesis19:1-38',
    'Genesis20:1-18',
    'Genesis21:1-34',
    'Genesis22:1-24',
    'Genesis23:1-20',
    'Genesis24:1-67',
    'Genesis25:1-34',
    'Genesis26:1-35',
    'Genesis27:1-46',
    'Genesis28:1-22',
    'Genesis29:1-35',
    'Genesis30:1-43',
    'Genesis31:1-55',
    'Genesis32:1-32',
    'Genesis33:1-20',
    'Genesis34:1-31',
    'Genesis35:1-29',
    'Genesis36:1-43',
    'Genesis37:1-36',
    'Genesis38:1-30',
    'Genesis39:1-23',
    'Genesis40:1-23',
    'Genesis41:1-57',
    'Genesis42:1-38',
    'Genesis43:1-34',
    'Genesis44:1-34',
    'Genesis45:1-28',
    'Genesis46:1-34',
    'Genesis47:1-31',
    'Genesis48:1-22',
    'Genesis49:1-33',
    'Genesis50:1-26',
  ],
  Exodus: [
    'Exodus1:1-22',
    'Exodus2:1-25',
    'Exodus3:1-22',
    'Exodus4:1-31',
    'Exodus5:1-23',
    'Exodus6:1-30',
    'Exodus7:1-25',
    'Exodus8:1-32',
    'Exodus9:1-35',
    'Exodus10:1-29',
    'Exodus11:1-10',
    'Exodus12:1-51',
    'Exodus13:1-22',
    'Exodus14:1-31',
    'Exodus15:1-27',
    'Exodus16:1-36',
    'Exodus17:1-16',
    'Exodus18:1-27',
    'Exodus19:1-25',
    'Exodus20:1-26',
    'Exodus21:1-36',
    'Exodus22:1-31',
    'Exodus23:1-33',
    'Exodus24:1-18',
    'Exodus25:1-40',
    'Exodus26:1-37',
    'Exodus27:1-21',
    'Exodus28:1-43',
    'Exodus29:1-46',
    'Exodus30:1-38',
    'Exodus31:1-18',
    'Exodus32:1-35',
    'Exodus33:1-23',
    'Exodus34:1-35',
    'Exodus35:1-35',
    'Exodus36:1-38',
    'Exodus37:1-29',
    'Exodus38:1-31',
    'Exodus39:1-43',
    'Exodus40:1-38',
  ],
  Leviticus: [
    'Leviticus1:1-17',
    'Leviticus2:1-16',
    'Leviticus3:1-17',
    'Leviticus4:1-35',
    'Leviticus5:1-19',
    'Leviticus6:1-30',
    'Leviticus7:1-38',
    'Leviticus8:1-36',
    'Leviticus9:1-24',
    'Leviticus10:1-20',
    'Leviticus11:1-47',
    'Leviticus12:1-8',
    'Leviticus13:1-59',
    'Leviticus14:1-57',
    'Leviticus15:1-33',
    'Leviticus16:1-34',
    'Leviticus17:1-16',
    'Leviticus18:1-30',
    'Leviticus19:1-37',
    'Leviticus20:1-27',
    'Leviticus21:1-24',
    'Leviticus22:1-33',
    'Leviticus23:1-44',
    'Leviticus24:1-23',
    'Leviticus25:1-55',
    'Leviticus26:1-46',
    'Leviticus27:1-34',
  ],
  Numbers: [
    'Numbers1:1-54',
    'Numbers2:1-34',
    'Numbers3:1-51',
    'Numbers4:1-49',
    'Numbers5:1-31',
    'Numbers6:1-27',
    'Numbers7:1-89',
    'Numbers8:1-26',
    'Numbers9:1-23',
    'Numbers10:1-36',
    'Numbers11:1-35',
    'Numbers12:1-16',
    'Numbers13:1-33',
    'Numbers14:1-45',
    'Numbers15:1-41',
    'Numbers16:1-50',
    'Numbers17:1-13',
    'Numbers18:1-32',
    'Numbers19:1-22',
    'Numbers20:1-29',
    'Numbers21:1-35',
    'Numbers22:1-41',
    'Numbers23:1-30',
    'Numbers24:1-25',
    'Numbers25:1-18',
    'Numbers26:1-65',
    'Numbers27:1-23',
    'Numbers28:1-31',
    'Numbers29:1-40',
    'Numbers30:1-16',
    'Numbers31:1-54',
    'Numbers32:1-42',
    'Numbers33:1-56',
    'Numbers34:1-29',
    'Numbers35:1-34',
    'Numbers36:1-13',
  ],
  Deuteronomy: [
    'Deuteronomy1:1-46',
    'Deuteronomy2:1-37',
    'Deuteronomy3:1-29',
    'Deuteronomy4:1-49',
    'Deuteronomy5:1-33',
    'Deuteronomy6:1-25',
    'Deuteronomy7:1-26',
    'Deuteronomy8:1-20',
    'Deuteronomy9:1-29',
    'Deuteronomy10:1-22',
    'Deuteronomy11:1-32',
    'Deuteronomy12:1-32',
    'Deuteronomy13:1-18',
    'Deuteronomy14:1-29',
    'Deuteronomy15:1-23',
    'Deuteronomy16:1-22',
    'Deuteronomy17:1-20',
    'Deuteronomy18:1-22',
    'Deuteronomy19:1-21',
    'Deuteronomy20:1-20',
    'Deuteronomy21:1-23',
    'Deuteronomy22:1-30',
    'Deuteronomy23:1-25',
    'Deuteronomy24:1-22',
    'Deuteronomy25:1-19',
    'Deuteronomy26:1-19',
    'Deuteronomy27:1-26',
    'Deuteronomy28:1-68',
    'Deuteronomy29:1-29',
    'Deuteronomy30:1-20',
    'Deuteronomy31:1-30',
    'Deuteronomy32:1-52',
    'Deuteronomy33:1-29',
    'Deuteronomy34:1-12',
  ],
  Joshua: [
    'Joshua1:1-18',
    'Joshua2:1-24',
    'Joshua3:1-17',
    'Joshua4:1-24',
    'Joshua5:1-15',
    'Joshua6:1-27',
    'Joshua7:1-26',
    'Joshua8:1-35',
    'Joshua9:1-27',
    'Joshua10:1-43',
    'Joshua11:1-23',
    'Joshua12:1-24',
    'Joshua13:1-33',
    'Joshua14:1-15',
    'Joshua15:1-63',
    'Joshua16:1-10',
    'Joshua17:1-18',
    'Joshua18:1-28',
    'Joshua19:1-51',
    'Joshua20:1-9',
    'Joshua21:1-45',
    'Joshua22:1-34',
    'Joshua23:1-16',
    'Joshua24:1-33',
  ],
  Judges: [
    'Judges1:1-36',
    'Judges2:1-23',
    'Judges3:1-31',
    'Judges4:1-24',
    'Judges5:1-31',
    'Judges6:1-40',
    'Judges7:1-25',
    'Judges8:1-35',
    'Judges9:1-57',
    'Judges10:1-18',
    'Judges11:1-40',
    'Judges12:1-15',
    'Judges13:1-25',
    'Judges14:1-20',
    'Judges15:1-20',
    'Judges16:1-31',
    'Judges17:1-13',
    'Judges18:1-31',
    'Judges19:1-30',
    'Judges20:1-48',
    'Judges21:1-25',
  ],
  Ruth: ['Ruth1:1-22', 'Ruth2:1-23', 'Ruth3:1-18', 'Ruth4:1-22'],
  '1Samuel': [
    '1Samuel1:1-28',
    '1Samuel2:1-36',
    '1Samuel3:1-21',
    '1Samuel4:1-22',
    '1Samuel5:1-12',
    '1Samuel6:1-21',
    '1Samuel7:1-17',
    '1Samuel8:1-22',
    '1Samuel9:1-27',
    '1Samuel10:1-27',
    '1Samuel11:1-15',
    '1Samuel12:1-25',
    '1Samuel13:1-23',
    '1Samuel14:1-52',
    '1Samuel15:1-35',
    '1Samuel16:1-23',
    '1Samuel17:1-58',
    '1Samuel18:1-30',
    '1Samuel19:1-24',
    '1Samuel20:1-42',
    '1Samuel21:1-15',
    '1Samuel22:1-23',
    '1Samuel23:1-29',
    '1Samuel24:1-22',
    '1Samuel25:1-44',
    '1Samuel26:1-25',
    '1Samuel27:1-12',
    '1Samuel28:1-25',
    '1Samuel29:1-11',
    '1Samuel30:1-31',
    '1Samuel31:1-13',
  ],
  '2Samuel': [
    '2Samuel1:1-27',
    '2Samuel2:1-32',
    '2Samuel3:1-39',
    '2Samuel4:1-12',
    '2Samuel5:1-25',
    '2Samuel6:1-23',
    '2Samuel7:1-29',
    '2Samuel8:1-18',
    '2Samuel9:1-13',
    '2Samuel10:1-19',
    '2Samuel11:1-27',
    '2Samuel12:1-31',
    '2Samuel13:1-39',
    '2Samuel14:1-33',
    '2Samuel15:1-37',
    '2Samuel16:1-23',
    '2Samuel17:1-29',
    '2Samuel18:1-33',
    '2Samuel19:1-43',
    '2Samuel20:1-26',
    '2Samuel21:1-22',
    '2Samuel22:1-51',
    '2Samuel23:1-39',
    '2Samuel24:1-25',
  ],
  '1Kings': [
    '1Kings1:1-53',
    '1Kings2:1-46',
    '1Kings3:1-28',
    '1Kings4:1-34',
    '1Kings5:1-18',
    '1Kings6:1-38',
    '1Kings7:1-51',
    '1Kings8:1-66',
    '1Kings9:1-28',
    '1Kings10:1-29',
    '1Kings11:1-43',
    '1Kings12:1-33',
    '1Kings13:1-34',
    '1Kings14:1-31',
    '1Kings15:1-34',
    '1Kings16:1-34',
    '1Kings17:1-24',
    '1Kings18:1-46',
    '1Kings19:1-21',
    '1Kings20:1-43',
    '1Kings21:1-29',
    '1Kings22:1-53',
  ],
  '2Kings': [
    '2Kings1:1-18',
    '2Kings2:1-25',
    '2Kings3:1-27',
    '2Kings4:1-44',
    '2Kings5:1-27',
    '2Kings6:1-33',
    '2Kings7:1-20',
    '2Kings8:1-29',
    '2Kings9:1-37',
    '2Kings10:1-36',
    '2Kings11:1-21',
    '2Kings12:1-21',
    '2Kings13:1-25',
    '2Kings14:1-29',
    '2Kings15:1-38',
    '2Kings16:1-20',
    '2Kings17:1-41',
    '2Kings18:1-37',
    '2Kings19:1-37',
    '2Kings20:1-21',
    '2Kings21:1-26',
    '2Kings22:1-20',
    '2Kings23:1-37',
    '2Kings24:1-20',
    '2Kings25:1-30',
  ],
  '1Chronicles': [
    '1Chronicles1:1-54',
    '1Chronicles2:1-55',
    '1Chronicles3:1-24',
    '1Chronicles4:1-43',
    '1Chronicles5:1-26',
    '1Chronicles6:1-81',
    '1Chronicles7:1-40',
    '1Chronicles8:1-40',
    '1Chronicles9:1-44',
    '1Chronicles10:1-14',
    '1Chronicles11:1-47',
    '1Chronicles12:1-40',
    '1Chronicles13:1-14',
    '1Chronicles14:1-17',
    '1Chronicles15:1-29',
    '1Chronicles16:1-43',
    '1Chronicles17:1-27',
    '1Chronicles18:1-17',
    '1Chronicles19:1-19',
    '1Chronicles20:1-8',
    '1Chronicles21:1-30',
    '1Chronicles22:1-19',
    '1Chronicles23:1-32',
    '1Chronicles24:1-31',
    '1Chronicles25:1-31',
    '1Chronicles26:1-32',
    '1Chronicles27:1-34',
    '1Chronicles28:1-21',
    '1Chronicles29:1-30',
  ],
  '2Chronicles': [
    '2Chronicles1:1-17',
    '2Chronicles2:1-18',
    '2Chronicles3:1-17',
    '2Chronicles4:1-22',
    '2Chronicles5:1-14',
    '2Chronicles6:1-42',
    '2Chronicles7:1-22',
    '2Chronicles8:1-18',
    '2Chronicles9:1-31',
    '2Chronicles10:1-19',
    '2Chronicles11:1-23',
    '2Chronicles12:1-16',
    '2Chronicles13:1-22',
    '2Chronicles14:1-15',
    '2Chronicles15:1-19',
    '2Chronicles16:1-14',
    '2Chronicles17:1-19',
    '2Chronicles18:1-34',
    '2Chronicles19:1-11',
    '2Chronicles20:1-37',
    '2Chronicles21:1-20',
    '2Chronicles22:1-12',
    '2Chronicles23:1-21',
    '2Chronicles24:1-27',
    '2Chronicles25:1-28',
    '2Chronicles26:1-23',
    '2Chronicles27:1-9',
    '2Chronicles28:1-27',
    '2Chronicles29:1-36',
    '2Chronicles30:1-27',
    '2Chronicles31:1-21',
    '2Chronicles32:1-33',
    '2Chronicles33:1-25',
    '2Chronicles34:1-33',
    '2Chronicles35:1-27',
    '2Chronicles36:1-23',
  ],
  Ezra: [
    'Ezra1:1-11',
    'Ezra2:1-70',
    'Ezra3:1-13',
    'Ezra4:1-24',
    'Ezra5:1-17',
    'Ezra6:1-22',
    'Ezra7:1-28',
    'Ezra8:1-36',
    'Ezra9:1-15',
    'Ezra10:1-44',
  ],
  Nehemiah: [
    'Nehemiah1:1-11',
    'Nehemiah2:1-20',
    'Nehemiah3:1-32',
    'Nehemiah4:1-23',
    'Nehemiah5:1-19',
    'Nehemiah6:1-19',
    'Nehemiah7:1-73',
    'Nehemiah8:1-18',
    'Nehemiah9:1-38',
    'Nehemiah10:1-39',
    'Nehemiah11:1-36',
    'Nehemiah12:1-47',
    'Nehemiah13:1-31',
  ],
  Esther: [
    'Esther1:1-22',
    'Esther2:1-23',
    'Esther3:1-15',
    'Esther4:1-17',
    'Esther5:1-14',
    'Esther6:1-14',
    'Esther7:1-10',
    'Esther8:1-17',
    'Esther9:1-32',
    'Esther10:1-3',
  ],
  Job: [
    'Job1:1-22',
    'Job2:1-13',
    'Job3:1-26',
    'Job4:1-21',
    'Job5:1-27',
    'Job6:1-30',
    'Job7:1-21',
    'Job8:1-22',
    'Job9:1-35',
    'Job10:1-22',
    'Job11:1-20',
    'Job12:1-25',
    'Job13:1-28',
    'Job14:1-22',
    'Job15:1-35',
    'Job16:1-22',
    'Job17:1-16',
    'Job18:1-21',
    'Job19:1-29',
    'Job20:1-29',
    'Job21:1-34',
    'Job22:1-30',
    'Job23:1-17',
    'Job24:1-25',
    'Job25:1-6',
    'Job26:1-14',
    'Job27:1-23',
    'Job28:1-28',
    'Job29:1-25',
    'Job30:1-31',
    'Job31:1-40',
    'Job32:1-22',
    'Job33:1-33',
    'Job34:1-37',
    'Job35:1-16',
    'Job36:1-33',
    'Job37:1-24',
    'Job38:1-41',
    'Job39:1-30',
    'Job40:1-24',
    'Job41:1-34',
    'Job42:1-17',
  ],
  Psalms: [
    'Psalms1:1-6',
    'Psalms2:1-12',
    'Psalms3:1-8',
    'Psalms4:1-8',
    'Psalms5:1-12',
    'Psalms6:1-10',
    'Psalms7:1-17',
    'Psalms8:1-9',
    'Psalms9:1-20',
    'Psalms10:1-18',
    'Psalms11:1-7',
    'Psalms12:1-8',
    'Psalms13:1-6',
    'Psalms14:1-7',
    'Psalms15:1-5',
    'Psalms16:1-11',
    'Psalms17:1-15',
    'Psalms18:1-50',
    'Psalms19:1-14',
    'Psalms20:1-9',
    'Psalms21:1-13',
    'Psalms22:1-31',
    'Psalms23:1-6',
    'Psalms24:1-10',
    'Psalms25:1-22',
    'Psalms26:1-12',
    'Psalms27:1-14',
    'Psalms28:1-9',
    'Psalms29:1-11',
    'Psalms30:1-12',
    'Psalms31:1-24',
    'Psalms32:1-11',
    'Psalms33:1-22',
    'Psalms34:1-22',
    'Psalms35:1-28',
    'Psalms36:1-12',
    'Psalms37:1-40',
    'Psalms38:1-22',
    'Psalms39:1-13',
    'Psalms40:1-17',
    'Psalms41:1-13',
    'Psalms42:1-11',
    'Psalms43:1-5',
    'Psalms44:1-26',
    'Psalms45:1-17',
    'Psalms46:1-11',
    'Psalms47:1-9',
    'Psalms48:1-14',
    'Psalms49:1-20',
    'Psalms50:1-23',
    'Psalms51:1-19',
    'Psalms52:1-9',
    'Psalms53:1-6',
    'Psalms54:1-7',
    'Psalms55:1-23',
    'Psalms56:1-13',
    'Psalms57:1-11',
    'Psalms58:1-11',
    'Psalms59:1-17',
    'Psalms60:1-12',
    'Psalms61:1-8',
    'Psalms62:1-12',
    'Psalms63:1-11',
    'Psalms64:1-10',
    'Psalms65:1-13',
    'Psalms66:1-20',
    'Psalms67:1-7',
    'Psalms68:1-35',
    'Psalms69:1-36',
    'Psalms70:1-5',
    'Psalms71:1-24',
    'Psalms72:1-20',
    'Psalms73:1-28',
    'Psalms74:1-23',
    'Psalms75:1-10',
    'Psalms76:1-12',
    'Psalms77:1-20',
    'Psalms78:1-72',
    'Psalms79:1-13',
    'Psalms80:1-19',
    'Psalms81:1-16',
    'Psalms82:1-8',
    'Psalms83:1-18',
    'Psalms84:1-12',
    'Psalms85:1-13',
    'Psalms86:1-17',
    'Psalms87:1-7',
    'Psalms88:1-18',
    'Psalms89:1-52',
    'Psalms90:1-17',
    'Psalms91:1-16',
    'Psalms92:1-15',
    'Psalms93:1-5',
    'Psalms94:1-23',
    'Psalms95:1-11',
    'Psalms96:1-13',
    'Psalms97:1-12',
    'Psalms98:1-9',
    'Psalms99:1-9',
    'Psalms100:1-5',
    'Psalms101:1-8',
    'Psalms102:1-28',
    'Psalms103:1-22',
    'Psalms104:1-35',
    'Psalms105:1-45',
    'Psalms106:1-48',
    'Psalms107:1-43',
    'Psalms108:1-13',
    'Psalms109:1-31',
    'Psalms110:1-7',
    'Psalms111:1-10',
    'Psalms112:1-10',
    'Psalms113:1-9',
    'Psalms114:1-8',
    'Psalms115:1-18',
    'Psalms116:1-19',
    'Psalms117:1-2',
    'Psalms118:1-29',
    'Psalms119:1-176',
    'Psalms120:1-7',
    'Psalms121:1-8',
    'Psalms122:1-9',
    'Psalms123:1-4',
    'Psalms124:1-8',
    'Psalms125:1-5',
    'Psalms126:1-6',
    'Psalms127:1-5',
    'Psalms128:1-6',
    'Psalms129:1-8',
    'Psalms130:1-8',
    'Psalms131:1-3',
    'Psalms132:1-18',
    'Psalms133:1-3',
    'Psalms134:1-3',
    'Psalms135:1-21',
    'Psalms136:1-26',
    'Psalms137:1-9',
    'Psalms138:1-8',
    'Psalms139:1-24',
    'Psalms140:1-13',
    'Psalms141:1-10',
    'Psalms142:1-7',
    'Psalms143:1-12',
    'Psalms144:1-15',
    'Psalms145:1-21',
    'Psalms146:1-10',
    'Psalms147:1-20',
    'Psalms148:1-14',
    'Psalms149:1-9',
    'Psalms150:1-6',
  ],
  Proverbs: [
    'Proverbs1:1-33',
    'Proverbs2:1-22',
    'Proverbs3:1-35',
    'Proverbs4:1-27',
    'Proverbs5:1-23',
    'Proverbs6:1-35',
    'Proverbs7:1-27',
    'Proverbs8:1-36',
    'Proverbs9:1-18',
    'Proverbs10:1-32',
    'Proverbs11:1-31',
    'Proverbs12:1-28',
    'Proverbs13:1-25',
    'Proverbs14:1-35',
    'Proverbs15:1-33',
    'Proverbs16:1-33',
    'Proverbs17:1-28',
    'Proverbs18:1-24',
    'Proverbs19:1-29',
    'Proverbs20:1-30',
    'Proverbs21:1-31',
    'Proverbs22:1-29',
    'Proverbs23:1-35',
    'Proverbs24:1-34',
    'Proverbs25:1-28',
    'Proverbs26:1-28',
    'Proverbs27:1-27',
    'Proverbs28:1-28',
    'Proverbs29:1-27',
    'Proverbs30:1-33',
    'Proverbs31:1-31',
  ],
  Ecclesiastes: [
    'Ecclesiastes1:1-18',
    'Ecclesiastes2:1-26',
    'Ecclesiastes3:1-22',
    'Ecclesiastes4:1-16',
    'Ecclesiastes5:1-20',
    'Ecclesiastes6:1-12',
    'Ecclesiastes7:1-29',
    'Ecclesiastes8:1-17',
    'Ecclesiastes9:1-18',
    'Ecclesiastes10:1-20',
    'Ecclesiastes11:1-10',
    'Ecclesiastes12:1-14',
  ],
  SongofSolomon: [
    'SongofSolomon1:1-17',
    'SongofSolomon2:1-17',
    'SongofSolomon3:1-11',
    'SongofSolomon4:1-16',
    'SongofSolomon5:1-16',
    'SongofSolomon6:1-13',
    'SongofSolomon7:1-13',
    'SongofSolomon8:1-14',
  ],
  Isaiah: [
    'Isaiah1:1-31',
    'Isaiah2:1-22',
    'Isaiah3:1-26',
    'Isaiah4:1-6',
    'Isaiah5:1-30',
    'Isaiah6:1-13',
    'Isaiah7:1-25',
    'Isaiah8:1-22',
    'Isaiah9:1-21',
    'Isaiah10:1-34',
    'Isaiah11:1-16',
    'Isaiah12:1-6',
    'Isaiah13:1-22',
    'Isaiah14:1-32',
    'Isaiah15:1-9',
    'Isaiah16:1-14',
    'Isaiah17:1-14',
    'Isaiah18:1-7',
    'Isaiah19:1-25',
    'Isaiah20:1-6',
    'Isaiah21:1-17',
    'Isaiah22:1-25',
    'Isaiah23:1-18',
    'Isaiah24:1-23',
    'Isaiah25:1-12',
    'Isaiah26:1-21',
    'Isaiah27:1-13',
    'Isaiah28:1-29',
    'Isaiah29:1-24',
    'Isaiah30:1-33',
    'Isaiah31:1-9',
    'Isaiah32:1-20',
    'Isaiah33:1-24',
    'Isaiah34:1-17',
    'Isaiah35:1-10',
    'Isaiah36:1-22',
    'Isaiah37:1-38',
    'Isaiah38:1-22',
    'Isaiah39:1-8',
    'Isaiah40:1-31',
    'Isaiah41:1-29',
    'Isaiah42:1-25',
    'Isaiah43:1-28',
    'Isaiah44:1-28',
    'Isaiah45:1-25',
    'Isaiah46:1-13',
    'Isaiah47:1-15',
    'Isaiah48:1-22',
    'Isaiah49:1-26',
    'Isaiah50:1-11',
    'Isaiah51:1-23',
    'Isaiah52:1-15',
    'Isaiah53:1-12',
    'Isaiah54:1-17',
    'Isaiah55:1-13',
    'Isaiah56:1-12',
    'Isaiah57:1-21',
    'Isaiah58:1-14',
    'Isaiah59:1-21',
    'Isaiah60:1-22',
    'Isaiah61:1-11',
    'Isaiah62:1-12',
    'Isaiah63:1-19',
    'Isaiah64:1-12',
    'Isaiah65:1-25',
    'Isaiah66:1-24',
  ],
  Jeremiah: [
    'Jeremiah1:1-19',
    'Jeremiah2:1-37',
    'Jeremiah3:1-25',
    'Jeremiah4:1-31',
    'Jeremiah5:1-31',
    'Jeremiah6:1-30',
    'Jeremiah7:1-34',
    'Jeremiah8:1-22',
    'Jeremiah9:1-26',
    'Jeremiah10:1-25',
    'Jeremiah11:1-23',
    'Jeremiah12:1-17',
    'Jeremiah13:1-27',
    'Jeremiah14:1-22',
    'Jeremiah15:1-21',
    'Jeremiah16:1-21',
    'Jeremiah17:1-27',
    'Jeremiah18:1-23',
    'Jeremiah19:1-15',
    'Jeremiah20:1-18',
    'Jeremiah21:1-14',
    'Jeremiah22:1-30',
    'Jeremiah23:1-40',
    'Jeremiah24:1-10',
    'Jeremiah25:1-38',
    'Jeremiah26:1-24',
    'Jeremiah27:1-22',
    'Jeremiah28:1-17',
    'Jeremiah29:1-32',
    'Jeremiah30:1-24',
    'Jeremiah31:1-40',
    'Jeremiah32:1-44',
    'Jeremiah33:1-26',
    'Jeremiah34:1-22',
    'Jeremiah35:1-19',
    'Jeremiah36:1-32',
    'Jeremiah37:1-21',
    'Jeremiah38:1-28',
    'Jeremiah39:1-18',
    'Jeremiah40:1-16',
    'Jeremiah41:1-18',
    'Jeremiah42:1-22',
    'Jeremiah43:1-13',
    'Jeremiah44:1-30',
    'Jeremiah45:1-5',
    'Jeremiah46:1-28',
    'Jeremiah47:1-7',
    'Jeremiah48:1-47',
    'Jeremiah49:1-39',
    'Jeremiah50:1-46',
    'Jeremiah51:1-64',
    'Jeremiah52:1-34',
  ],
  Lamentations: [
    'Lamentations1:1-22',
    'Lamentations2:1-22',
    'Lamentations3:1-66',
    'Lamentations4:1-22',
    'Lamentations5:1-22',
  ],
  Ezekiel: [
    'Ezekiel1:1-28',
    'Ezekiel2:1-10',
    'Ezekiel3:1-27',
    'Ezekiel4:1-17',
    'Ezekiel5:1-17',
    'Ezekiel6:1-14',
    'Ezekiel7:1-27',
    'Ezekiel8:1-18',
    'Ezekiel9:1-11',
    'Ezekiel10:1-22',
    'Ezekiel11:1-25',
    'Ezekiel12:1-28',
    'Ezekiel13:1-23',
    'Ezekiel14:1-23',
    'Ezekiel15:1-8',
    'Ezekiel16:1-63',
    'Ezekiel17:1-24',
    'Ezekiel18:1-32',
    'Ezekiel19:1-14',
    'Ezekiel20:1-49',
    'Ezekiel21:1-32',
    'Ezekiel22:1-31',
    'Ezekiel23:1-49',
    'Ezekiel24:1-27',
    'Ezekiel25:1-17',
    'Ezekiel26:1-21',
    'Ezekiel27:1-36',
    'Ezekiel28:1-26',
    'Ezekiel29:1-21',
    'Ezekiel30:1-26',
    'Ezekiel31:1-18',
    'Ezekiel32:1-32',
    'Ezekiel33:1-33',
    'Ezekiel34:1-31',
    'Ezekiel35:1-15',
    'Ezekiel36:1-38',
    'Ezekiel37:1-28',
    'Ezekiel38:1-23',
    'Ezekiel39:1-29',
    'Ezekiel40:1-49',
    'Ezekiel41:1-26',
    'Ezekiel42:1-20',
    'Ezekiel43:1-27',
    'Ezekiel44:1-31',
    'Ezekiel45:1-25',
    'Ezekiel46:1-24',
    'Ezekiel47:1-23',
    'Ezekiel48:1-35',
  ],
  Daniel: [
    'Daniel1:1-21',
    'Daniel2:1-49',
    'Daniel3:1-30',
    'Daniel4:1-37',
    'Daniel5:1-31',
    'Daniel6:1-28',
    'Daniel7:1-28',
    'Daniel8:1-27',
    'Daniel9:1-27',
    'Daniel10:1-21',
    'Daniel11:1-45',
    'Daniel12:1-13',
  ],
  Hosea: [
    'Hosea1:1-11',
    'Hosea2:1-23',
    'Hosea3:1-5',
    'Hosea4:1-19',
    'Hosea5:1-15',
    'Hosea6:1-11',
    'Hosea7:1-16',
    'Hosea8:1-14',
    'Hosea9:1-17',
    'Hosea10:1-15',
    'Hosea11:1-12',
    'Hosea12:1-14',
    'Hosea13:1-16',
    'Hosea14:1-9',
  ],
  Joel: ['Joel1:1-20', 'Joel2:1-32', 'Joel3:1-21'],
  Amos: [
    'Amos1:1-15',
    'Amos2:1-16',
    'Amos3:1-15',
    'Amos4:1-13',
    'Amos5:1-27',
    'Amos6:1-14',
    'Amos7:1-17',
    'Amos8:1-14',
    'Amos9:1-15',
  ],
  Obadiah: ['Obadiah1:1-21'],
  Jonah: ['Jonah1:1-17', 'Jonah2:1-10', 'Jonah3:1-10', 'Jonah4:1-11'],
  Micah: ['Micah1:1-16', 'Micah2:1-13', 'Micah3:1-12', 'Micah4:1-13', 'Micah5:1-15', 'Micah6:1-16', 'Micah7:1-20'],
  Nahum: ['Nahum1:1-15', 'Nahum2:1-13', 'Nahum3:1-19'],
  Habakkuk: ['Habakkuk1:1-17', 'Habakkuk2:1-20', 'Habakkuk3:1-19'],
  Zephaniah: ['Zephaniah1:1-18', 'Zephaniah2:1-15', 'Zephaniah3:1-20'],
  Haggai: ['Haggai1:1-15', 'Haggai2:1-23'],
  Zechariah: [
    'Zechariah1:1-21',
    'Zechariah2:1-13',
    'Zechariah3:1-10',
    'Zechariah4:1-14',
    'Zechariah5:1-11',
    'Zechariah6:1-15',
    'Zechariah7:1-14',
    'Zechariah8:1-23',
    'Zechariah9:1-17',
    'Zechariah10:1-12',
    'Zechariah11:1-17',
    'Zechariah12:1-14',
    'Zechariah13:1-9',
    'Zechariah14:1-21',
  ],
  Malachi: ['Malachi1:1-14', 'Malachi2:1-17', 'Malachi3:1-18', 'Malachi4:1-6'],
  Matthew: [
    'Matthew1:1-25',
    'Matthew2:1-23',
    'Matthew3:1-17',
    'Matthew4:1-25',
    'Matthew5:1-48',
    'Matthew6:1-34',
    'Matthew7:1-29',
    'Matthew8:1-34',
    'Matthew9:1-38',
    'Matthew10:1-42',
    'Matthew11:1-30',
    'Matthew12:1-50',
    'Matthew13:1-58',
    'Matthew14:1-36',
    'Matthew15:1-39',
    'Matthew16:1-28',
    'Matthew17:1-27',
    'Matthew18:1-35',
    'Matthew19:1-30',
    'Matthew20:1-34',
    'Matthew21:1-46',
    'Matthew22:1-46',
    'Matthew23:1-39',
    'Matthew24:1-51',
    'Matthew25:1-46',
    'Matthew26:1-75',
    'Matthew27:1-66',
    'Matthew28:1-20',
  ],
  Mark: [
    'Mark1:1-45',
    'Mark2:1-28',
    'Mark3:1-35',
    'Mark4:1-41',
    'Mark5:1-43',
    'Mark6:1-56',
    'Mark7:1-37',
    'Mark8:1-38',
    'Mark9:1-50',
    'Mark10:1-52',
    'Mark11:1-33',
    'Mark12:1-44',
    'Mark13:1-37',
    'Mark14:1-72',
    'Mark15:1-47',
    'Mark16:1-20',
  ],
  Luke: [
    'Luke1:1-80',
    'Luke2:1-52',
    'Luke3:1-38',
    'Luke4:1-44',
    'Luke5:1-39',
    'Luke6:1-49',
    'Luke7:1-50',
    'Luke8:1-56',
    'Luke9:1-62',
    'Luke10:1-42',
    'Luke11:1-54',
    'Luke12:1-59',
    'Luke13:1-35',
    'Luke14:1-35',
    'Luke15:1-32',
    'Luke16:1-31',
    'Luke17:1-37',
    'Luke18:1-43',
    'Luke19:1-48',
    'Luke20:1-47',
    'Luke21:1-38',
    'Luke22:1-71',
    'Luke23:1-56',
    'Luke24:1-53',
  ],
  John: [
    'John1:1-51',
    'John2:1-25',
    'John3:1-36',
    'John4:1-54',
    'John5:1-47',
    'John6:1-71',
    'John7:1-53',
    'John8:1-59',
    'John9:1-41',
    'John10:1-42',
    'John11:1-57',
    'John12:1-50',
    'John13:1-38',
    'John14:1-31',
    'John15:1-27',
    'John16:1-33',
    'John17:1-26',
    'John18:1-40',
    'John19:1-42',
    'John20:1-31',
    'John21:1-25',
  ],
  Acts: [
    'Acts1:1-26',
    'Acts2:1-47',
    'Acts3:1-26',
    'Acts4:1-37',
    'Acts5:1-42',
    'Acts6:1-15',
    'Acts7:1-60',
    'Acts8:1-40',
    'Acts9:1-43',
    'Acts10:1-48',
    'Acts11:1-30',
    'Acts12:1-25',
    'Acts13:1-52',
    'Acts14:1-28',
    'Acts15:1-41',
    'Acts16:1-40',
    'Acts17:1-34',
    'Acts18:1-28',
    'Acts19:1-41',
    'Acts20:1-38',
    'Acts21:1-40',
    'Acts22:1-30',
    'Acts23:1-35',
    'Acts24:1-27',
    'Acts25:1-27',
    'Acts26:1-32',
    'Acts27:1-44',
    'Acts28:1-31',
  ],
  Romans: [
    'Romans1:1-32',
    'Romans2:1-29',
    'Romans3:1-31',
    'Romans4:1-25',
    'Romans5:1-21',
    'Romans6:1-23',
    'Romans7:1-25',
    'Romans8:1-39',
    'Romans9:1-33',
    'Romans10:1-21',
    'Romans11:1-36',
    'Romans12:1-21',
    'Romans13:1-14',
    'Romans14:1-23',
    'Romans15:1-33',
    'Romans16:1-27',
  ],
  '1Corinthians': [
    '1Corinthians1:1-31',
    '1Corinthians2:1-16',
    '1Corinthians3:1-23',
    '1Corinthians4:1-21',
    '1Corinthians5:1-13',
    '1Corinthians6:1-20',
    '1Corinthians7:1-40',
    '1Corinthians8:1-13',
    '1Corinthians9:1-27',
    '1Corinthians10:1-33',
    '1Corinthians11:1-34',
    '1Corinthians12:1-31',
    '1Corinthians13:1-13',
    '1Corinthians14:1-40',
    '1Corinthians15:1-58',
    '1Corinthians16:1-24',
  ],
  '2Corinthians': [
    '2Corinthians1:1-24',
    '2Corinthians2:1-17',
    '2Corinthians3:1-18',
    '2Corinthians4:1-18',
    '2Corinthians5:1-21',
    '2Corinthians6:1-18',
    '2Corinthians7:1-16',
    '2Corinthians8:1-24',
    '2Corinthians9:1-15',
    '2Corinthians10:1-18',
    '2Corinthians11:1-33',
    '2Corinthians12:1-21',
    '2Corinthians13:1-14',
  ],
  Galatians: [
    'Galatians1:1-24',
    'Galatians2:1-21',
    'Galatians3:1-29',
    'Galatians4:1-31',
    'Galatians5:1-26',
    'Galatians6:1-18',
  ],
  Ephesians: [
    'Ephesians1:1-23',
    'Ephesians2:1-22',
    'Ephesians3:1-21',
    'Ephesians4:1-32',
    'Ephesians5:1-33',
    'Ephesians6:1-24',
  ],
  Philippians: ['Philippians1:1-30', 'Philippians2:1-30', 'Philippians3:1-21', 'Philippians4:1-23'],
  Colossians: ['Colossians1:1-29', 'Colossians2:1-23', 'Colossians3:1-25', 'Colossians4:1-18'],
  '1Thessalonians': [
    '1Thessalonians1:1-10',
    '1Thessalonians2:1-20',
    '1Thessalonians3:1-13',
    '1Thessalonians4:1-18',
    '1Thessalonians5:1-28',
  ],
  '2Thessalonians': ['2Thessalonians1:1-12', '2Thessalonians2:1-17', '2Thessalonians3:1-18'],
  '1Timothy': [
    '1Timothy1:1-20',
    '1Timothy2:1-15',
    '1Timothy3:1-16',
    '1Timothy4:1-16',
    '1Timothy5:1-25',
    '1Timothy6:1-21',
  ],
  '2Timothy': ['2Timothy1:1-18', '2Timothy2:1-26', '2Timothy3:1-17', '2Timothy4:1-22'],
  Titus: ['Titus1:1-16', 'Titus2:1-15', 'Titus3:1-15'],
  Philemon: ['Philemon1:1-25'],
  Hebrews: [
    'Hebrews1:1-14',
    'Hebrews2:1-18',
    'Hebrews3:1-19',
    'Hebrews4:1-16',
    'Hebrews5:1-14',
    'Hebrews6:1-20',
    'Hebrews7:1-28',
    'Hebrews8:1-13',
    'Hebrews9:1-28',
    'Hebrews10:1-39',
    'Hebrews11:1-40',
    'Hebrews12:1-29',
    'Hebrews13:1-25',
  ],
  James: ['James1:1-27', 'James2:1-26', 'James3:1-18', 'James4:1-17', 'James5:1-20'],
  '1Peter': ['1Peter1:1-25', '1Peter2:1-25', '1Peter3:1-22', '1Peter4:1-19', '1Peter5:1-14'],
  '2Peter': ['2Peter1:1-21', '2Peter2:1-22', '2Peter3:1-18'],
  '1John': ['1John1:1-10', '1John2:1-29', '1John3:1-24', '1John4:1-21', '1John5:1-21'],
  '2John': ['2John1:1-13'],
  '3John': ['3John1:1-14'],
  Jude: ['Jude1:1-25'],
  Revelation: [
    'Revelation1:1-20',
    'Revelation2:1-29',
    'Revelation3:1-22',
    'Revelation4:1-11',
    'Revelation5:1-14',
    'Revelation6:1-17',
    'Revelation7:1-17',
    'Revelation8:1-13',
    'Revelation9:1-21',
    'Revelation10:1-11',
    'Revelation11:1-19',
    'Revelation12:1-17',
    'Revelation13:1-18',
    'Revelation14:1-20',
    'Revelation15:1-8',
    'Revelation16:1-21',
    'Revelation17:1-18',
    'Revelation18:1-24',
    'Revelation19:1-21',
    'Revelation20:1-15',
    'Revelation21:1-27',
    'Revelation22:1-21',
  ],
}
