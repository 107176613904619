import { ListBulletIcon, CheckCircleIcon as CheckCircleSolidIcon } from '@heroicons/react/24/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import React, { ComponentProps } from 'react'

export interface LoadingProps extends ComponentProps<'div'> {}

export const Loading = ({ className = '', ...props }: LoadingProps) => {
  return (
    <div className={`${className} flex-center h-full flex-col`} {...props}>
      <h1 className="mb-4 text-5xl font-semibold text-primary-500">Bible Reminder 365</h1>
    </div>
  )
}
