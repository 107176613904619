import React, { ComponentProps } from 'react'

export interface HomeProps extends ComponentProps<'div'> {}

export const Home = ({ className = '', ...props }: HomeProps) => {
  return (
    <div className={`${className} mx-auto flex max-w-2xl flex-col gap-y-4 p-4`} {...props}>
      <p>
        The purpose of this app is simple: <strong>Put the Word of God in front of your eyes.</strong>
      </p>
      <p>
        Your reasons for wanting to see God's Word may differ from mine or the reasons you had yesterday, but this tool
        can help you, at the very least, see it more often. What you decide to do after it shows up in your email or
        text messages is completely on you. I pray that you walk with the Spirit in those moments and "take up and read"
        as often as the opportunities present themselves.
      </p>
      <p>
        This tool won't change your life, but the God who <em className="italic">can</em> change your life wants to
        speak to you.
      </p>

      <p>
        "This is my beloved Son, in whom I am well pleased. Listen to Him." (
        <a href="/read/esv/Matthew17">Matthew 17:5</a>)
      </p>
    </div>
  )
}
