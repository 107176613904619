/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

export type BibleChapterPayload = {
  __typename?: 'BibleChapterPayload';
  error?: Maybe<Scalars['String']['output']>;
  passage?: Maybe<Scalars['String']['output']>;
};

export type BibleReminderOutput = {
  __typename?: 'BibleReminderOutput';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsError = {
  __typename?: 'JSError';
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stack?: Maybe<Scalars['String']['output']>;
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  error?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type LogoutInput = {
  allSessions?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Me = {
  __typename?: 'Me';
  created_at?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_carrier?: Maybe<Scalars['String']['output']>;
  phone_verified?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SendEmailEnvelope = {
  __typename?: 'SendEmailEnvelope';
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type SendEmailInput = {
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type SendEmailPayload = {
  __typename?: 'SendEmailPayload';
  error?: Maybe<JsError>;
  result?: Maybe<SendEmailResult>;
};

export type SendEmailResult = {
  __typename?: 'SendEmailResult';
  accepted?: Maybe<Array<Scalars['String']['output']>>;
  envelope?: Maybe<SendEmailEnvelope>;
  envelopeTime?: Maybe<Scalars['Int']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  messageSize?: Maybe<Scalars['Int']['output']>;
  messageTime?: Maybe<Scalars['Int']['output']>;
  rejected?: Maybe<Array<Scalars['String']['output']>>;
  response?: Maybe<Scalars['String']['output']>;
};

export type SendReminderPayload = {
  __typename?: 'SendReminderPayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SendVerificationCodeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type SendVerificationCodeOutput = {
  __typename?: 'SendVerificationCodeOutput';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SignupInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SignupPayload = {
  __typename?: 'SignupPayload';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitVerificationCodePayload = {
  __typename?: 'SubmitVerificationCodePayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TriggerHabitPayload = {
  __typename?: 'TriggerHabitPayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** fields of action: "bibleReminder" */
export type BibleReminder = {
  __typename?: 'bibleReminder';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz']['output'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output: BibleReminderOutput;
};

/** metadata for a user's bible reading reminders */
export type Bible_Reading_Plan = {
  __typename?: 'bible_reading_plan';
  active_email: Scalars['Boolean']['output'];
  active_text: Scalars['Boolean']['output'];
  chapters: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  first_day?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_chapter?: Maybe<Scalars['Int']['output']>;
  legacy_carrier?: Maybe<Scalars['String']['output']>;
  legacy_email?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_phone?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  next_chapter: Scalars['Int']['output'];
  starting_chapter?: Maybe<Scalars['Int']['output']>;
  time: Scalars['String']['output'];
  timer_id?: Maybe<Scalars['String']['output']>;
  translation: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "bible_reading_plan" */
export type Bible_Reading_Plan_Aggregate = {
  __typename?: 'bible_reading_plan_aggregate';
  aggregate?: Maybe<Bible_Reading_Plan_Aggregate_Fields>;
  nodes: Array<Bible_Reading_Plan>;
};

export type Bible_Reading_Plan_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Bible_Reading_Plan_Aggregate_Bool_Exp_Count>;
};

export type Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_And = {
  arguments: Bible_Reading_Plan_Select_Column_Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Bible_Reading_Plan_Select_Column_Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bible_Reading_Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bible_reading_plan" */
export type Bible_Reading_Plan_Aggregate_Fields = {
  __typename?: 'bible_reading_plan_aggregate_fields';
  avg?: Maybe<Bible_Reading_Plan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Bible_Reading_Plan_Max_Fields>;
  min?: Maybe<Bible_Reading_Plan_Min_Fields>;
  stddev?: Maybe<Bible_Reading_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Bible_Reading_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bible_Reading_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Bible_Reading_Plan_Sum_Fields>;
  var_pop?: Maybe<Bible_Reading_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Bible_Reading_Plan_Var_Samp_Fields>;
  variance?: Maybe<Bible_Reading_Plan_Variance_Fields>;
};


/** aggregate fields of "bible_reading_plan" */
export type Bible_Reading_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "bible_reading_plan" */
export type Bible_Reading_Plan_Aggregate_Order_By = {
  avg?: InputMaybe<Bible_Reading_Plan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bible_Reading_Plan_Max_Order_By>;
  min?: InputMaybe<Bible_Reading_Plan_Min_Order_By>;
  stddev?: InputMaybe<Bible_Reading_Plan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bible_Reading_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bible_Reading_Plan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bible_Reading_Plan_Sum_Order_By>;
  var_pop?: InputMaybe<Bible_Reading_Plan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bible_Reading_Plan_Var_Samp_Order_By>;
  variance?: InputMaybe<Bible_Reading_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bible_reading_plan" */
export type Bible_Reading_Plan_Arr_Rel_Insert_Input = {
  data: Array<Bible_Reading_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bible_Reading_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Bible_Reading_Plan_Avg_Fields = {
  __typename?: 'bible_reading_plan_avg_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Avg_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bible_reading_plan". All fields are combined with a logical 'AND'. */
export type Bible_Reading_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Bible_Reading_Plan_Bool_Exp>>;
  _not?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Bible_Reading_Plan_Bool_Exp>>;
  active_email?: InputMaybe<Boolean_Comparison_Exp>;
  active_text?: InputMaybe<Boolean_Comparison_Exp>;
  chapters?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_day?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_chapter?: InputMaybe<Int_Comparison_Exp>;
  legacy_carrier?: InputMaybe<String_Comparison_Exp>;
  legacy_email?: InputMaybe<String_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  legacy_phone?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  next_chapter?: InputMaybe<Int_Comparison_Exp>;
  starting_chapter?: InputMaybe<Int_Comparison_Exp>;
  time?: InputMaybe<String_Comparison_Exp>;
  timer_id?: InputMaybe<String_Comparison_Exp>;
  translation?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bible_reading_plan" */
export enum Bible_Reading_Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  BibleReadingPlanPkey = 'bible_reading_plan_pkey',
  /** unique or primary key constraint on columns "timer_id" */
  BibleReadingPlanTimerIdKey = 'bible_reading_plan_timer_id_key'
}

/** input type for incrementing numeric columns in table "bible_reading_plan" */
export type Bible_Reading_Plan_Inc_Input = {
  chapters?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_chapter?: InputMaybe<Scalars['Int']['input']>;
  next_chapter?: InputMaybe<Scalars['Int']['input']>;
  starting_chapter?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "bible_reading_plan" */
export type Bible_Reading_Plan_Insert_Input = {
  active_email?: InputMaybe<Scalars['Boolean']['input']>;
  active_text?: InputMaybe<Scalars['Boolean']['input']>;
  chapters?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_day?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_chapter?: InputMaybe<Scalars['Int']['input']>;
  legacy_carrier?: InputMaybe<Scalars['String']['input']>;
  legacy_email?: InputMaybe<Scalars['String']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_phone?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_chapter?: InputMaybe<Scalars['Int']['input']>;
  starting_chapter?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Bible_Reading_Plan_Max_Fields = {
  __typename?: 'bible_reading_plan_max_fields';
  chapters?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_day?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_chapter?: Maybe<Scalars['Int']['output']>;
  legacy_carrier?: Maybe<Scalars['String']['output']>;
  legacy_email?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_phone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next_chapter?: Maybe<Scalars['Int']['output']>;
  starting_chapter?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Max_Order_By = {
  chapters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  legacy_carrier?: InputMaybe<Order_By>;
  legacy_email?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_phone?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bible_Reading_Plan_Min_Fields = {
  __typename?: 'bible_reading_plan_min_fields';
  chapters?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_day?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_chapter?: Maybe<Scalars['Int']['output']>;
  legacy_carrier?: Maybe<Scalars['String']['output']>;
  legacy_email?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_phone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next_chapter?: Maybe<Scalars['Int']['output']>;
  starting_chapter?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Min_Order_By = {
  chapters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  legacy_carrier?: InputMaybe<Order_By>;
  legacy_email?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_phone?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bible_reading_plan" */
export type Bible_Reading_Plan_Mutation_Response = {
  __typename?: 'bible_reading_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Bible_Reading_Plan>;
};

/** on_conflict condition type for table "bible_reading_plan" */
export type Bible_Reading_Plan_On_Conflict = {
  constraint: Bible_Reading_Plan_Constraint;
  update_columns?: Array<Bible_Reading_Plan_Update_Column>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "bible_reading_plan". */
export type Bible_Reading_Plan_Order_By = {
  active_email?: InputMaybe<Order_By>;
  active_text?: InputMaybe<Order_By>;
  chapters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  legacy_carrier?: InputMaybe<Order_By>;
  legacy_email?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_phone?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bible_reading_plan */
export type Bible_Reading_Plan_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "bible_reading_plan" */
export enum Bible_Reading_Plan_Select_Column {
  /** column name */
  ActiveEmail = 'active_email',
  /** column name */
  ActiveText = 'active_text',
  /** column name */
  Chapters = 'chapters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstDay = 'first_day',
  /** column name */
  Id = 'id',
  /** column name */
  LastChapter = 'last_chapter',
  /** column name */
  LegacyCarrier = 'legacy_carrier',
  /** column name */
  LegacyEmail = 'legacy_email',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacyPhone = 'legacy_phone',
  /** column name */
  Name = 'name',
  /** column name */
  NextChapter = 'next_chapter',
  /** column name */
  StartingChapter = 'starting_chapter',
  /** column name */
  Time = 'time',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  Translation = 'translation',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "bible_reading_plan_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bible_reading_plan" */
export enum Bible_Reading_Plan_Select_Column_Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ActiveEmail = 'active_email',
  /** column name */
  ActiveText = 'active_text'
}

/** select "bible_reading_plan_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bible_reading_plan" */
export enum Bible_Reading_Plan_Select_Column_Bible_Reading_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ActiveEmail = 'active_email',
  /** column name */
  ActiveText = 'active_text'
}

/** input type for updating data in table "bible_reading_plan" */
export type Bible_Reading_Plan_Set_Input = {
  active_email?: InputMaybe<Scalars['Boolean']['input']>;
  active_text?: InputMaybe<Scalars['Boolean']['input']>;
  chapters?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_day?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_chapter?: InputMaybe<Scalars['Int']['input']>;
  legacy_carrier?: InputMaybe<Scalars['String']['input']>;
  legacy_email?: InputMaybe<Scalars['String']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_phone?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_chapter?: InputMaybe<Scalars['Int']['input']>;
  starting_chapter?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Bible_Reading_Plan_Stddev_Fields = {
  __typename?: 'bible_reading_plan_stddev_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Stddev_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bible_Reading_Plan_Stddev_Pop_Fields = {
  __typename?: 'bible_reading_plan_stddev_pop_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Stddev_Pop_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bible_Reading_Plan_Stddev_Samp_Fields = {
  __typename?: 'bible_reading_plan_stddev_samp_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Stddev_Samp_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bible_reading_plan" */
export type Bible_Reading_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bible_Reading_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bible_Reading_Plan_Stream_Cursor_Value_Input = {
  active_email?: InputMaybe<Scalars['Boolean']['input']>;
  active_text?: InputMaybe<Scalars['Boolean']['input']>;
  chapters?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_day?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_chapter?: InputMaybe<Scalars['Int']['input']>;
  legacy_carrier?: InputMaybe<Scalars['String']['input']>;
  legacy_email?: InputMaybe<Scalars['String']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_phone?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_chapter?: InputMaybe<Scalars['Int']['input']>;
  starting_chapter?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Bible_Reading_Plan_Sum_Fields = {
  __typename?: 'bible_reading_plan_sum_fields';
  chapters?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_chapter?: Maybe<Scalars['Int']['output']>;
  next_chapter?: Maybe<Scalars['Int']['output']>;
  starting_chapter?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Sum_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "bible_reading_plan" */
export enum Bible_Reading_Plan_Update_Column {
  /** column name */
  ActiveEmail = 'active_email',
  /** column name */
  ActiveText = 'active_text',
  /** column name */
  Chapters = 'chapters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstDay = 'first_day',
  /** column name */
  Id = 'id',
  /** column name */
  LastChapter = 'last_chapter',
  /** column name */
  LegacyCarrier = 'legacy_carrier',
  /** column name */
  LegacyEmail = 'legacy_email',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacyPhone = 'legacy_phone',
  /** column name */
  Name = 'name',
  /** column name */
  NextChapter = 'next_chapter',
  /** column name */
  StartingChapter = 'starting_chapter',
  /** column name */
  Time = 'time',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  Translation = 'translation',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Bible_Reading_Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bible_Reading_Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bible_Reading_Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bible_Reading_Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bible_Reading_Plan_Var_Pop_Fields = {
  __typename?: 'bible_reading_plan_var_pop_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Var_Pop_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bible_Reading_Plan_Var_Samp_Fields = {
  __typename?: 'bible_reading_plan_var_samp_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Var_Samp_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bible_Reading_Plan_Variance_Fields = {
  __typename?: 'bible_reading_plan_variance_fields';
  chapters?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_chapter?: Maybe<Scalars['Float']['output']>;
  next_chapter?: Maybe<Scalars['Float']['output']>;
  starting_chapter?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "bible_reading_plan" */
export type Bible_Reading_Plan_Variance_Order_By = {
  chapters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_chapter?: InputMaybe<Order_By>;
  next_chapter?: InputMaybe<Order_By>;
  starting_chapter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** comments on articles or other content */
export type Comment = {
  __typename?: 'comment';
  content_id: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  path: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "comment" */
export type Comment_Aggregate = {
  __typename?: 'comment_aggregate';
  aggregate?: Maybe<Comment_Aggregate_Fields>;
  nodes: Array<Comment>;
};

export type Comment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comment_Aggregate_Bool_Exp_Count>;
};

export type Comment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment" */
export type Comment_Aggregate_Fields = {
  __typename?: 'comment_aggregate_fields';
  avg?: Maybe<Comment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Comment_Max_Fields>;
  min?: Maybe<Comment_Min_Fields>;
  stddev?: Maybe<Comment_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Sum_Fields>;
  var_pop?: Maybe<Comment_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Var_Samp_Fields>;
  variance?: Maybe<Comment_Variance_Fields>;
};


/** aggregate fields of "comment" */
export type Comment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment" */
export type Comment_Aggregate_Order_By = {
  avg?: InputMaybe<Comment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Max_Order_By>;
  min?: InputMaybe<Comment_Min_Order_By>;
  stddev?: InputMaybe<Comment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comment_Sum_Order_By>;
  var_pop?: InputMaybe<Comment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comment_Var_Samp_Order_By>;
  variance?: InputMaybe<Comment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment" */
export type Comment_Arr_Rel_Insert_Input = {
  data: Array<Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};

/** aggregate avg on columns */
export type Comment_Avg_Fields = {
  __typename?: 'comment_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "comment" */
export type Comment_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type Comment_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Bool_Exp>>;
  _not?: InputMaybe<Comment_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Bool_Exp>>;
  content_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment" */
export enum Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentPkey = 'comment_pkey'
}

/** input type for incrementing numeric columns in table "comment" */
export type Comment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "comment" */
export type Comment_Insert_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Comment_Max_Fields = {
  __typename?: 'comment_max_fields';
  content_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "comment" */
export type Comment_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Min_Fields = {
  __typename?: 'comment_min_fields';
  content_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "comment" */
export type Comment_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment" */
export type Comment_Mutation_Response = {
  __typename?: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment>;
};

/** on_conflict condition type for table "comment" */
export type Comment_On_Conflict = {
  constraint: Comment_Constraint;
  update_columns?: Array<Comment_Update_Column>;
  where?: InputMaybe<Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "comment". */
export type Comment_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment */
export type Comment_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "comment" */
export enum Comment_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comment" */
export type Comment_Set_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Comment_Stddev_Fields = {
  __typename?: 'comment_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "comment" */
export type Comment_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comment_Stddev_Pop_Fields = {
  __typename?: 'comment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "comment" */
export type Comment_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comment_Stddev_Samp_Fields = {
  __typename?: 'comment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "comment" */
export type Comment_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "comment" */
export type Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Comment_Sum_Fields = {
  __typename?: 'comment_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "comment" */
export type Comment_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "comment" */
export enum Comment_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Comment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comment_Var_Pop_Fields = {
  __typename?: 'comment_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "comment" */
export type Comment_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comment_Var_Samp_Fields = {
  __typename?: 'comment_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "comment" */
export type Comment_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comment_Variance_Fields = {
  __typename?: 'comment_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "comment" */
export type Comment_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** recurring tasks, chores, or activities */
export type Habit = {
  __typename?: 'habit';
  can_catch_up: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  executions: Array<Habit_Execution>;
  /** An aggregate relationship */
  executions_aggregate: Habit_Execution_Aggregate;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  should_remind: Scalars['Boolean']['output'];
  /** An object relationship */
  tag?: Maybe<Tehdo_Tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  timing: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};


/** recurring tasks, chores, or activities */
export type HabitExecutionsArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


/** recurring tasks, chores, or activities */
export type HabitExecutions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};

/** aggregated selection of "habit" */
export type Habit_Aggregate = {
  __typename?: 'habit_aggregate';
  aggregate?: Maybe<Habit_Aggregate_Fields>;
  nodes: Array<Habit>;
};

export type Habit_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Habit_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Habit_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Habit_Aggregate_Bool_Exp_Count>;
};

export type Habit_Aggregate_Bool_Exp_Bool_And = {
  arguments: Habit_Select_Column_Habit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Habit_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Habit_Select_Column_Habit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Habit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Habit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "habit" */
export type Habit_Aggregate_Fields = {
  __typename?: 'habit_aggregate_fields';
  avg?: Maybe<Habit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Habit_Max_Fields>;
  min?: Maybe<Habit_Min_Fields>;
  stddev?: Maybe<Habit_Stddev_Fields>;
  stddev_pop?: Maybe<Habit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Habit_Stddev_Samp_Fields>;
  sum?: Maybe<Habit_Sum_Fields>;
  var_pop?: Maybe<Habit_Var_Pop_Fields>;
  var_samp?: Maybe<Habit_Var_Samp_Fields>;
  variance?: Maybe<Habit_Variance_Fields>;
};


/** aggregate fields of "habit" */
export type Habit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Habit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "habit" */
export type Habit_Aggregate_Order_By = {
  avg?: InputMaybe<Habit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Habit_Max_Order_By>;
  min?: InputMaybe<Habit_Min_Order_By>;
  stddev?: InputMaybe<Habit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Habit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Habit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Habit_Sum_Order_By>;
  var_pop?: InputMaybe<Habit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Habit_Var_Samp_Order_By>;
  variance?: InputMaybe<Habit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "habit" */
export type Habit_Arr_Rel_Insert_Input = {
  data: Array<Habit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Habit_On_Conflict>;
};

/** aggregate avg on columns */
export type Habit_Avg_Fields = {
  __typename?: 'habit_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "habit" */
export type Habit_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "habit". All fields are combined with a logical 'AND'. */
export type Habit_Bool_Exp = {
  _and?: InputMaybe<Array<Habit_Bool_Exp>>;
  _not?: InputMaybe<Habit_Bool_Exp>;
  _or?: InputMaybe<Array<Habit_Bool_Exp>>;
  can_catch_up?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  executions?: InputMaybe<Habit_Execution_Bool_Exp>;
  executions_aggregate?: InputMaybe<Habit_Execution_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  should_remind?: InputMaybe<Boolean_Comparison_Exp>;
  tag?: InputMaybe<Tehdo_Tag_Bool_Exp>;
  tag_id?: InputMaybe<Int_Comparison_Exp>;
  timer_id?: InputMaybe<String_Comparison_Exp>;
  timing?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "habit" */
export enum Habit_Constraint {
  /** unique or primary key constraint on columns "id" */
  HabitPkey = 'habit_pkey'
}

/** marks when a habit should be done and was done. */
export type Habit_Execution = {
  __typename?: 'habit_execution';
  created_at: Scalars['timestamptz']['output'];
  done?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  habit: Habit;
  habit_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Scalars['timestamptz']['output'];
  skipped: Scalars['Boolean']['output'];
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "habit_execution" */
export type Habit_Execution_Aggregate = {
  __typename?: 'habit_execution_aggregate';
  aggregate?: Maybe<Habit_Execution_Aggregate_Fields>;
  nodes: Array<Habit_Execution>;
};

export type Habit_Execution_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Habit_Execution_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Habit_Execution_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Habit_Execution_Aggregate_Bool_Exp_Count>;
};

export type Habit_Execution_Aggregate_Bool_Exp_Bool_And = {
  arguments: Habit_Execution_Select_Column_Habit_Execution_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Execution_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Habit_Execution_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Habit_Execution_Select_Column_Habit_Execution_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Execution_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Habit_Execution_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Habit_Execution_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "habit_execution" */
export type Habit_Execution_Aggregate_Fields = {
  __typename?: 'habit_execution_aggregate_fields';
  avg?: Maybe<Habit_Execution_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Habit_Execution_Max_Fields>;
  min?: Maybe<Habit_Execution_Min_Fields>;
  stddev?: Maybe<Habit_Execution_Stddev_Fields>;
  stddev_pop?: Maybe<Habit_Execution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Habit_Execution_Stddev_Samp_Fields>;
  sum?: Maybe<Habit_Execution_Sum_Fields>;
  var_pop?: Maybe<Habit_Execution_Var_Pop_Fields>;
  var_samp?: Maybe<Habit_Execution_Var_Samp_Fields>;
  variance?: Maybe<Habit_Execution_Variance_Fields>;
};


/** aggregate fields of "habit_execution" */
export type Habit_Execution_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "habit_execution" */
export type Habit_Execution_Aggregate_Order_By = {
  avg?: InputMaybe<Habit_Execution_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Habit_Execution_Max_Order_By>;
  min?: InputMaybe<Habit_Execution_Min_Order_By>;
  stddev?: InputMaybe<Habit_Execution_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Habit_Execution_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Habit_Execution_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Habit_Execution_Sum_Order_By>;
  var_pop?: InputMaybe<Habit_Execution_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Habit_Execution_Var_Samp_Order_By>;
  variance?: InputMaybe<Habit_Execution_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "habit_execution" */
export type Habit_Execution_Arr_Rel_Insert_Input = {
  data: Array<Habit_Execution_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Habit_Execution_On_Conflict>;
};

/** aggregate avg on columns */
export type Habit_Execution_Avg_Fields = {
  __typename?: 'habit_execution_avg_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "habit_execution" */
export type Habit_Execution_Avg_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "habit_execution". All fields are combined with a logical 'AND'. */
export type Habit_Execution_Bool_Exp = {
  _and?: InputMaybe<Array<Habit_Execution_Bool_Exp>>;
  _not?: InputMaybe<Habit_Execution_Bool_Exp>;
  _or?: InputMaybe<Array<Habit_Execution_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  done?: InputMaybe<Timestamptz_Comparison_Exp>;
  habit?: InputMaybe<Habit_Bool_Exp>;
  habit_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Timestamptz_Comparison_Exp>;
  skipped?: InputMaybe<Boolean_Comparison_Exp>;
  snoozed?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "habit_execution" */
export enum Habit_Execution_Constraint {
  /** unique or primary key constraint on columns "id" */
  HabitExecutionPkey = 'habit_execution_pkey'
}

/** input type for incrementing numeric columns in table "habit_execution" */
export type Habit_Execution_Inc_Input = {
  habit_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "habit_execution" */
export type Habit_Execution_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  habit?: InputMaybe<Habit_Obj_Rel_Insert_Input>;
  habit_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Habit_Execution_Max_Fields = {
  __typename?: 'habit_execution_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done?: Maybe<Scalars['timestamptz']['output']>;
  habit_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "habit_execution" */
export type Habit_Execution_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Habit_Execution_Min_Fields = {
  __typename?: 'habit_execution_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done?: Maybe<Scalars['timestamptz']['output']>;
  habit_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "habit_execution" */
export type Habit_Execution_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "habit_execution" */
export type Habit_Execution_Mutation_Response = {
  __typename?: 'habit_execution_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Habit_Execution>;
};

/** on_conflict condition type for table "habit_execution" */
export type Habit_Execution_On_Conflict = {
  constraint: Habit_Execution_Constraint;
  update_columns?: Array<Habit_Execution_Update_Column>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};

/** Ordering options when selecting data from "habit_execution". */
export type Habit_Execution_Order_By = {
  created_at?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  habit?: InputMaybe<Habit_Order_By>;
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: habit_execution */
export type Habit_Execution_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "habit_execution" */
export enum Habit_Execution_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Done = 'done',
  /** column name */
  HabitId = 'habit_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Order = 'order',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Snoozed = 'snoozed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "habit_execution_aggregate_bool_exp_bool_and_arguments_columns" columns of table "habit_execution" */
export enum Habit_Execution_Select_Column_Habit_Execution_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Skipped = 'skipped'
}

/** select "habit_execution_aggregate_bool_exp_bool_or_arguments_columns" columns of table "habit_execution" */
export enum Habit_Execution_Select_Column_Habit_Execution_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Skipped = 'skipped'
}

/** input type for updating data in table "habit_execution" */
export type Habit_Execution_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  habit_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Habit_Execution_Stddev_Fields = {
  __typename?: 'habit_execution_stddev_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "habit_execution" */
export type Habit_Execution_Stddev_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Habit_Execution_Stddev_Pop_Fields = {
  __typename?: 'habit_execution_stddev_pop_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "habit_execution" */
export type Habit_Execution_Stddev_Pop_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Habit_Execution_Stddev_Samp_Fields = {
  __typename?: 'habit_execution_stddev_samp_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "habit_execution" */
export type Habit_Execution_Stddev_Samp_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "habit_execution" */
export type Habit_Execution_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Habit_Execution_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Habit_Execution_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  habit_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Habit_Execution_Sum_Fields = {
  __typename?: 'habit_execution_sum_fields';
  habit_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "habit_execution" */
export type Habit_Execution_Sum_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "habit_execution" */
export enum Habit_Execution_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Done = 'done',
  /** column name */
  HabitId = 'habit_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Order = 'order',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Snoozed = 'snoozed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Habit_Execution_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Habit_Execution_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Habit_Execution_Set_Input>;
  /** filter the rows which have to be updated */
  where: Habit_Execution_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Habit_Execution_Var_Pop_Fields = {
  __typename?: 'habit_execution_var_pop_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "habit_execution" */
export type Habit_Execution_Var_Pop_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Habit_Execution_Var_Samp_Fields = {
  __typename?: 'habit_execution_var_samp_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "habit_execution" */
export type Habit_Execution_Var_Samp_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Habit_Execution_Variance_Fields = {
  __typename?: 'habit_execution_variance_fields';
  habit_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "habit_execution" */
export type Habit_Execution_Variance_Order_By = {
  habit_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "habit" */
export type Habit_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "habit" */
export type Habit_Insert_Input = {
  can_catch_up?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  executions?: InputMaybe<Habit_Execution_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  should_remind?: InputMaybe<Scalars['Boolean']['input']>;
  tag?: InputMaybe<Tehdo_Tag_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  timing?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Habit_Max_Fields = {
  __typename?: 'habit_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "habit" */
export type Habit_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  timing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Habit_Min_Fields = {
  __typename?: 'habit_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "habit" */
export type Habit_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  timing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "habit" */
export type Habit_Mutation_Response = {
  __typename?: 'habit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Habit>;
};

/** input type for inserting object relation for remote table "habit" */
export type Habit_Obj_Rel_Insert_Input = {
  data: Habit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Habit_On_Conflict>;
};

/** on_conflict condition type for table "habit" */
export type Habit_On_Conflict = {
  constraint: Habit_Constraint;
  update_columns?: Array<Habit_Update_Column>;
  where?: InputMaybe<Habit_Bool_Exp>;
};

/** Ordering options when selecting data from "habit". */
export type Habit_Order_By = {
  can_catch_up?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executions_aggregate?: InputMaybe<Habit_Execution_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  should_remind?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tehdo_Tag_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  timing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: habit */
export type Habit_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "habit" */
export enum Habit_Select_Column {
  /** column name */
  CanCatchUp = 'can_catch_up',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  ShouldRemind = 'should_remind',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  Timing = 'timing',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "habit_aggregate_bool_exp_bool_and_arguments_columns" columns of table "habit" */
export enum Habit_Select_Column_Habit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CanCatchUp = 'can_catch_up',
  /** column name */
  ShouldRemind = 'should_remind'
}

/** select "habit_aggregate_bool_exp_bool_or_arguments_columns" columns of table "habit" */
export enum Habit_Select_Column_Habit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CanCatchUp = 'can_catch_up',
  /** column name */
  ShouldRemind = 'should_remind'
}

/** input type for updating data in table "habit" */
export type Habit_Set_Input = {
  can_catch_up?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  should_remind?: InputMaybe<Scalars['Boolean']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  timing?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Habit_Stddev_Fields = {
  __typename?: 'habit_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "habit" */
export type Habit_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Habit_Stddev_Pop_Fields = {
  __typename?: 'habit_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "habit" */
export type Habit_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Habit_Stddev_Samp_Fields = {
  __typename?: 'habit_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "habit" */
export type Habit_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "habit" */
export type Habit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Habit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Habit_Stream_Cursor_Value_Input = {
  can_catch_up?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  should_remind?: InputMaybe<Scalars['Boolean']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  timing?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Habit_Sum_Fields = {
  __typename?: 'habit_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "habit" */
export type Habit_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "habit" */
export enum Habit_Update_Column {
  /** column name */
  CanCatchUp = 'can_catch_up',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  ShouldRemind = 'should_remind',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  Timing = 'timing',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Habit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Habit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Habit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Habit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Habit_Var_Pop_Fields = {
  __typename?: 'habit_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "habit" */
export type Habit_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Habit_Var_Samp_Fields = {
  __typename?: 'habit_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "habit" */
export type Habit_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Habit_Variance_Fields = {
  __typename?: 'habit_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "habit" */
export type Habit_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Bible Reminder */
  bibleReminder: Scalars['uuid']['output'];
  /** delete data from the table: "bible_reading_plan" */
  delete_bible_reading_plan?: Maybe<Bible_Reading_Plan_Mutation_Response>;
  /** delete single row from the table: "bible_reading_plan" */
  delete_bible_reading_plan_by_pk?: Maybe<Bible_Reading_Plan>;
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<Comment_Mutation_Response>;
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<Comment>;
  /** delete data from the table: "habit" */
  delete_habit?: Maybe<Habit_Mutation_Response>;
  /** delete single row from the table: "habit" */
  delete_habit_by_pk?: Maybe<Habit>;
  /** delete data from the table: "habit_execution" */
  delete_habit_execution?: Maybe<Habit_Execution_Mutation_Response>;
  /** delete single row from the table: "habit_execution" */
  delete_habit_execution_by_pk?: Maybe<Habit_Execution>;
  /** delete data from the table: "password_reset_link" */
  delete_password_reset_link?: Maybe<Password_Reset_Link_Mutation_Response>;
  /** delete single row from the table: "password_reset_link" */
  delete_password_reset_link_by_pk?: Maybe<Password_Reset_Link>;
  /** delete data from the table: "post_subscription" */
  delete_post_subscription?: Maybe<Post_Subscription_Mutation_Response>;
  /** delete single row from the table: "post_subscription" */
  delete_post_subscription_by_pk?: Maybe<Post_Subscription>;
  /** delete data from the table: "reminder" */
  delete_reminder?: Maybe<Reminder_Mutation_Response>;
  /** delete single row from the table: "reminder" */
  delete_reminder_by_pk?: Maybe<Reminder>;
  /** delete data from the table: "session" */
  delete_session?: Maybe<Session_Mutation_Response>;
  /** delete single row from the table: "session" */
  delete_session_by_pk?: Maybe<Session>;
  /** delete data from the table: "task" */
  delete_task?: Maybe<Task_Mutation_Response>;
  /** delete single row from the table: "task" */
  delete_task_by_pk?: Maybe<Task>;
  /** delete data from the table: "tehdo_tag" */
  delete_tehdo_tag?: Maybe<Tehdo_Tag_Mutation_Response>;
  /** delete single row from the table: "tehdo_tag" */
  delete_tehdo_tag_by_pk?: Maybe<Tehdo_Tag>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "verification_code" */
  delete_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** delete single row from the table: "verification_code" */
  delete_verification_code_by_pk?: Maybe<Verification_Code>;
  forgotPassword: ForgotPasswordPayload;
  /** insert data into the table: "bible_reading_plan" */
  insert_bible_reading_plan?: Maybe<Bible_Reading_Plan_Mutation_Response>;
  /** insert a single row into the table: "bible_reading_plan" */
  insert_bible_reading_plan_one?: Maybe<Bible_Reading_Plan>;
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<Comment_Mutation_Response>;
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<Comment>;
  /** insert data into the table: "habit" */
  insert_habit?: Maybe<Habit_Mutation_Response>;
  /** insert data into the table: "habit_execution" */
  insert_habit_execution?: Maybe<Habit_Execution_Mutation_Response>;
  /** insert a single row into the table: "habit_execution" */
  insert_habit_execution_one?: Maybe<Habit_Execution>;
  /** insert a single row into the table: "habit" */
  insert_habit_one?: Maybe<Habit>;
  /** insert data into the table: "password_reset_link" */
  insert_password_reset_link?: Maybe<Password_Reset_Link_Mutation_Response>;
  /** insert a single row into the table: "password_reset_link" */
  insert_password_reset_link_one?: Maybe<Password_Reset_Link>;
  /** insert data into the table: "post_subscription" */
  insert_post_subscription?: Maybe<Post_Subscription_Mutation_Response>;
  /** insert a single row into the table: "post_subscription" */
  insert_post_subscription_one?: Maybe<Post_Subscription>;
  /** insert data into the table: "reminder" */
  insert_reminder?: Maybe<Reminder_Mutation_Response>;
  /** insert a single row into the table: "reminder" */
  insert_reminder_one?: Maybe<Reminder>;
  /** insert data into the table: "session" */
  insert_session?: Maybe<Session_Mutation_Response>;
  /** insert a single row into the table: "session" */
  insert_session_one?: Maybe<Session>;
  /** insert data into the table: "task" */
  insert_task?: Maybe<Task_Mutation_Response>;
  /** insert a single row into the table: "task" */
  insert_task_one?: Maybe<Task>;
  /** insert data into the table: "tehdo_tag" */
  insert_tehdo_tag?: Maybe<Tehdo_Tag_Mutation_Response>;
  /** insert a single row into the table: "tehdo_tag" */
  insert_tehdo_tag_one?: Maybe<Tehdo_Tag>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "verification_code" */
  insert_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** insert a single row into the table: "verification_code" */
  insert_verification_code_one?: Maybe<Verification_Code>;
  login: LoginPayload;
  logout?: Maybe<LogoutPayload>;
  /** reset password using special link ID */
  resetPassword: ResetPasswordPayload;
  /** send bible reading plan reminder */
  sendBibleReminder?: Maybe<SendReminderPayload>;
  /** send an email from ben@tehpsalmist.com */
  sendEmail: SendEmailPayload;
  sendReminder: SendReminderPayload;
  /** send verification code */
  sendVerificationCode: SendVerificationCodeOutput;
  signup?: Maybe<SignupPayload>;
  /** submit verification code for phone or email */
  submitVerificationCode: SubmitVerificationCodePayload;
  /** trigger a new habit (update or create a new execution) */
  triggerHabit?: Maybe<TriggerHabitPayload>;
  /** update data of the table: "bible_reading_plan" */
  update_bible_reading_plan?: Maybe<Bible_Reading_Plan_Mutation_Response>;
  /** update single row of the table: "bible_reading_plan" */
  update_bible_reading_plan_by_pk?: Maybe<Bible_Reading_Plan>;
  /** update multiples rows of table: "bible_reading_plan" */
  update_bible_reading_plan_many?: Maybe<Array<Maybe<Bible_Reading_Plan_Mutation_Response>>>;
  /** update data of the table: "comment" */
  update_comment?: Maybe<Comment_Mutation_Response>;
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<Comment>;
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<Comment_Mutation_Response>>>;
  /** update data of the table: "habit" */
  update_habit?: Maybe<Habit_Mutation_Response>;
  /** update single row of the table: "habit" */
  update_habit_by_pk?: Maybe<Habit>;
  /** update data of the table: "habit_execution" */
  update_habit_execution?: Maybe<Habit_Execution_Mutation_Response>;
  /** update single row of the table: "habit_execution" */
  update_habit_execution_by_pk?: Maybe<Habit_Execution>;
  /** update multiples rows of table: "habit_execution" */
  update_habit_execution_many?: Maybe<Array<Maybe<Habit_Execution_Mutation_Response>>>;
  /** update multiples rows of table: "habit" */
  update_habit_many?: Maybe<Array<Maybe<Habit_Mutation_Response>>>;
  /** update data of the table: "password_reset_link" */
  update_password_reset_link?: Maybe<Password_Reset_Link_Mutation_Response>;
  /** update single row of the table: "password_reset_link" */
  update_password_reset_link_by_pk?: Maybe<Password_Reset_Link>;
  /** update multiples rows of table: "password_reset_link" */
  update_password_reset_link_many?: Maybe<Array<Maybe<Password_Reset_Link_Mutation_Response>>>;
  /** update data of the table: "post_subscription" */
  update_post_subscription?: Maybe<Post_Subscription_Mutation_Response>;
  /** update single row of the table: "post_subscription" */
  update_post_subscription_by_pk?: Maybe<Post_Subscription>;
  /** update multiples rows of table: "post_subscription" */
  update_post_subscription_many?: Maybe<Array<Maybe<Post_Subscription_Mutation_Response>>>;
  /** update data of the table: "reminder" */
  update_reminder?: Maybe<Reminder_Mutation_Response>;
  /** update single row of the table: "reminder" */
  update_reminder_by_pk?: Maybe<Reminder>;
  /** update multiples rows of table: "reminder" */
  update_reminder_many?: Maybe<Array<Maybe<Reminder_Mutation_Response>>>;
  /** update data of the table: "session" */
  update_session?: Maybe<Session_Mutation_Response>;
  /** update single row of the table: "session" */
  update_session_by_pk?: Maybe<Session>;
  /** update multiples rows of table: "session" */
  update_session_many?: Maybe<Array<Maybe<Session_Mutation_Response>>>;
  /** update data of the table: "task" */
  update_task?: Maybe<Task_Mutation_Response>;
  /** update single row of the table: "task" */
  update_task_by_pk?: Maybe<Task>;
  /** update multiples rows of table: "task" */
  update_task_many?: Maybe<Array<Maybe<Task_Mutation_Response>>>;
  /** update data of the table: "tehdo_tag" */
  update_tehdo_tag?: Maybe<Tehdo_Tag_Mutation_Response>;
  /** update single row of the table: "tehdo_tag" */
  update_tehdo_tag_by_pk?: Maybe<Tehdo_Tag>;
  /** update multiples rows of table: "tehdo_tag" */
  update_tehdo_tag_many?: Maybe<Array<Maybe<Tehdo_Tag_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "verification_code" */
  update_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** update single row of the table: "verification_code" */
  update_verification_code_by_pk?: Maybe<Verification_Code>;
  /** update multiples rows of table: "verification_code" */
  update_verification_code_many?: Maybe<Array<Maybe<Verification_Code_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootBibleReminderArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  legacyId?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootDelete_Bible_Reading_PlanArgs = {
  where: Bible_Reading_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bible_Reading_Plan_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CommentArgs = {
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HabitArgs = {
  where: Habit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Habit_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Habit_ExecutionArgs = {
  where: Habit_Execution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Habit_Execution_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_LinkArgs = {
  where: Password_Reset_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_Link_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Post_SubscriptionArgs = {
  where: Post_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Subscription_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReminderArgs = {
  where: Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reminder_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TaskArgs = {
  where: Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tehdo_TagArgs = {
  where: Tehdo_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tehdo_Tag_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Verification_CodeArgs = {
  where: Verification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_Code_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_Bible_Reading_PlanArgs = {
  objects: Array<Bible_Reading_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Bible_Reading_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bible_Reading_Plan_OneArgs = {
  object: Bible_Reading_Plan_Insert_Input;
  on_conflict?: InputMaybe<Bible_Reading_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentArgs = {
  objects: Array<Comment_Insert_Input>;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_OneArgs = {
  object: Comment_Insert_Input;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HabitArgs = {
  objects: Array<Habit_Insert_Input>;
  on_conflict?: InputMaybe<Habit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Habit_ExecutionArgs = {
  objects: Array<Habit_Execution_Insert_Input>;
  on_conflict?: InputMaybe<Habit_Execution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Habit_Execution_OneArgs = {
  object: Habit_Execution_Insert_Input;
  on_conflict?: InputMaybe<Habit_Execution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Habit_OneArgs = {
  object: Habit_Insert_Input;
  on_conflict?: InputMaybe<Habit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_LinkArgs = {
  objects: Array<Password_Reset_Link_Insert_Input>;
  on_conflict?: InputMaybe<Password_Reset_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_Link_OneArgs = {
  object: Password_Reset_Link_Insert_Input;
  on_conflict?: InputMaybe<Password_Reset_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_SubscriptionArgs = {
  objects: Array<Post_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Post_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Subscription_OneArgs = {
  object: Post_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Post_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReminderArgs = {
  objects: Array<Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_OneArgs = {
  object: Reminder_Insert_Input;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: InputMaybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: InputMaybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskArgs = {
  objects: Array<Task_Insert_Input>;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_OneArgs = {
  object: Task_Insert_Input;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tehdo_TagArgs = {
  objects: Array<Tehdo_Tag_Insert_Input>;
  on_conflict?: InputMaybe<Tehdo_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tehdo_Tag_OneArgs = {
  object: Tehdo_Tag_Insert_Input;
  on_conflict?: InputMaybe<Tehdo_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_CodeArgs = {
  objects: Array<Verification_Code_Insert_Input>;
  on_conflict?: InputMaybe<Verification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_Code_OneArgs = {
  object: Verification_Code_Insert_Input;
  on_conflict?: InputMaybe<Verification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  input: LoginInput;
};


/** mutation root */
export type Mutation_RootLogoutArgs = {
  input: LogoutInput;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  linkId: Scalars['uuid']['input'];
  newPassword: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSendBibleReminderArgs = {
  planId: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  input: SendEmailInput;
};


/** mutation root */
export type Mutation_RootSendReminderArgs = {
  reminderId: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootSendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  input: SignupInput;
};


/** mutation root */
export type Mutation_RootSubmitVerificationCodeArgs = {
  code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootTriggerHabitArgs = {
  habitId: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_Bible_Reading_PlanArgs = {
  _inc?: InputMaybe<Bible_Reading_Plan_Inc_Input>;
  _set?: InputMaybe<Bible_Reading_Plan_Set_Input>;
  where: Bible_Reading_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bible_Reading_Plan_By_PkArgs = {
  _inc?: InputMaybe<Bible_Reading_Plan_Inc_Input>;
  _set?: InputMaybe<Bible_Reading_Plan_Set_Input>;
  pk_columns: Bible_Reading_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bible_Reading_Plan_ManyArgs = {
  updates: Array<Bible_Reading_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentArgs = {
  _inc?: InputMaybe<Comment_Inc_Input>;
  _set?: InputMaybe<Comment_Set_Input>;
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_By_PkArgs = {
  _inc?: InputMaybe<Comment_Inc_Input>;
  _set?: InputMaybe<Comment_Set_Input>;
  pk_columns: Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_ManyArgs = {
  updates: Array<Comment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HabitArgs = {
  _inc?: InputMaybe<Habit_Inc_Input>;
  _set?: InputMaybe<Habit_Set_Input>;
  where: Habit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Habit_By_PkArgs = {
  _inc?: InputMaybe<Habit_Inc_Input>;
  _set?: InputMaybe<Habit_Set_Input>;
  pk_columns: Habit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Habit_ExecutionArgs = {
  _inc?: InputMaybe<Habit_Execution_Inc_Input>;
  _set?: InputMaybe<Habit_Execution_Set_Input>;
  where: Habit_Execution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Habit_Execution_By_PkArgs = {
  _inc?: InputMaybe<Habit_Execution_Inc_Input>;
  _set?: InputMaybe<Habit_Execution_Set_Input>;
  pk_columns: Habit_Execution_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Habit_Execution_ManyArgs = {
  updates: Array<Habit_Execution_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Habit_ManyArgs = {
  updates: Array<Habit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_LinkArgs = {
  _inc?: InputMaybe<Password_Reset_Link_Inc_Input>;
  _set?: InputMaybe<Password_Reset_Link_Set_Input>;
  where: Password_Reset_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Link_By_PkArgs = {
  _inc?: InputMaybe<Password_Reset_Link_Inc_Input>;
  _set?: InputMaybe<Password_Reset_Link_Set_Input>;
  pk_columns: Password_Reset_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Link_ManyArgs = {
  updates: Array<Password_Reset_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_SubscriptionArgs = {
  _inc?: InputMaybe<Post_Subscription_Inc_Input>;
  _set?: InputMaybe<Post_Subscription_Set_Input>;
  where: Post_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Post_Subscription_Inc_Input>;
  _set?: InputMaybe<Post_Subscription_Set_Input>;
  pk_columns: Post_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Subscription_ManyArgs = {
  updates: Array<Post_Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReminderArgs = {
  _append?: InputMaybe<Reminder_Append_Input>;
  _delete_at_path?: InputMaybe<Reminder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reminder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reminder_Delete_Key_Input>;
  _inc?: InputMaybe<Reminder_Inc_Input>;
  _prepend?: InputMaybe<Reminder_Prepend_Input>;
  _set?: InputMaybe<Reminder_Set_Input>;
  where: Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_By_PkArgs = {
  _append?: InputMaybe<Reminder_Append_Input>;
  _delete_at_path?: InputMaybe<Reminder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reminder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reminder_Delete_Key_Input>;
  _inc?: InputMaybe<Reminder_Inc_Input>;
  _prepend?: InputMaybe<Reminder_Prepend_Input>;
  _set?: InputMaybe<Reminder_Set_Input>;
  pk_columns: Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_ManyArgs = {
  updates: Array<Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _append?: InputMaybe<Session_Append_Input>;
  _delete_at_path?: InputMaybe<Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Session_Delete_Key_Input>;
  _inc?: InputMaybe<Session_Inc_Input>;
  _prepend?: InputMaybe<Session_Prepend_Input>;
  _set?: InputMaybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _append?: InputMaybe<Session_Append_Input>;
  _delete_at_path?: InputMaybe<Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Session_Delete_Key_Input>;
  _inc?: InputMaybe<Session_Inc_Input>;
  _prepend?: InputMaybe<Session_Prepend_Input>;
  _set?: InputMaybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_ManyArgs = {
  updates: Array<Session_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskArgs = {
  _inc?: InputMaybe<Task_Inc_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  where: Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Task_By_PkArgs = {
  _inc?: InputMaybe<Task_Inc_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  pk_columns: Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_ManyArgs = {
  updates: Array<Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tehdo_TagArgs = {
  _inc?: InputMaybe<Tehdo_Tag_Inc_Input>;
  _set?: InputMaybe<Tehdo_Tag_Set_Input>;
  where: Tehdo_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tehdo_Tag_By_PkArgs = {
  _inc?: InputMaybe<Tehdo_Tag_Inc_Input>;
  _set?: InputMaybe<Tehdo_Tag_Set_Input>;
  pk_columns: Tehdo_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tehdo_Tag_ManyArgs = {
  updates: Array<Tehdo_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_CodeArgs = {
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  _set?: InputMaybe<Verification_Code_Set_Input>;
  where: Verification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Code_By_PkArgs = {
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  _set?: InputMaybe<Verification_Code_Set_Input>;
  pk_columns: Verification_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Code_ManyArgs = {
  updates: Array<Verification_Code_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** password reset links */
export type Password_Reset_Link = {
  __typename?: 'password_reset_link';
  expiration: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "password_reset_link" */
export type Password_Reset_Link_Aggregate = {
  __typename?: 'password_reset_link_aggregate';
  aggregate?: Maybe<Password_Reset_Link_Aggregate_Fields>;
  nodes: Array<Password_Reset_Link>;
};

export type Password_Reset_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Password_Reset_Link_Aggregate_Bool_Exp_Count>;
};

export type Password_Reset_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Password_Reset_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "password_reset_link" */
export type Password_Reset_Link_Aggregate_Fields = {
  __typename?: 'password_reset_link_aggregate_fields';
  avg?: Maybe<Password_Reset_Link_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Password_Reset_Link_Max_Fields>;
  min?: Maybe<Password_Reset_Link_Min_Fields>;
  stddev?: Maybe<Password_Reset_Link_Stddev_Fields>;
  stddev_pop?: Maybe<Password_Reset_Link_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Password_Reset_Link_Stddev_Samp_Fields>;
  sum?: Maybe<Password_Reset_Link_Sum_Fields>;
  var_pop?: Maybe<Password_Reset_Link_Var_Pop_Fields>;
  var_samp?: Maybe<Password_Reset_Link_Var_Samp_Fields>;
  variance?: Maybe<Password_Reset_Link_Variance_Fields>;
};


/** aggregate fields of "password_reset_link" */
export type Password_Reset_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "password_reset_link" */
export type Password_Reset_Link_Aggregate_Order_By = {
  avg?: InputMaybe<Password_Reset_Link_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Password_Reset_Link_Max_Order_By>;
  min?: InputMaybe<Password_Reset_Link_Min_Order_By>;
  stddev?: InputMaybe<Password_Reset_Link_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Password_Reset_Link_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Password_Reset_Link_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Password_Reset_Link_Sum_Order_By>;
  var_pop?: InputMaybe<Password_Reset_Link_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Password_Reset_Link_Var_Samp_Order_By>;
  variance?: InputMaybe<Password_Reset_Link_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "password_reset_link" */
export type Password_Reset_Link_Arr_Rel_Insert_Input = {
  data: Array<Password_Reset_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Password_Reset_Link_On_Conflict>;
};

/** aggregate avg on columns */
export type Password_Reset_Link_Avg_Fields = {
  __typename?: 'password_reset_link_avg_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "password_reset_link" */
export type Password_Reset_Link_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "password_reset_link". All fields are combined with a logical 'AND'. */
export type Password_Reset_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Password_Reset_Link_Bool_Exp>>;
  _not?: InputMaybe<Password_Reset_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Password_Reset_Link_Bool_Exp>>;
  expiration?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_reset_link" */
export enum Password_Reset_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  PasswordResetLinkPkey = 'password_reset_link_pkey'
}

/** input type for incrementing numeric columns in table "password_reset_link" */
export type Password_Reset_Link_Inc_Input = {
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "password_reset_link" */
export type Password_Reset_Link_Insert_Input = {
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Password_Reset_Link_Max_Fields = {
  __typename?: 'password_reset_link_max_fields';
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "password_reset_link" */
export type Password_Reset_Link_Max_Order_By = {
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Password_Reset_Link_Min_Fields = {
  __typename?: 'password_reset_link_min_fields';
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "password_reset_link" */
export type Password_Reset_Link_Min_Order_By = {
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "password_reset_link" */
export type Password_Reset_Link_Mutation_Response = {
  __typename?: 'password_reset_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Reset_Link>;
};

/** on_conflict condition type for table "password_reset_link" */
export type Password_Reset_Link_On_Conflict = {
  constraint: Password_Reset_Link_Constraint;
  update_columns?: Array<Password_Reset_Link_Update_Column>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "password_reset_link". */
export type Password_Reset_Link_Order_By = {
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: password_reset_link */
export type Password_Reset_Link_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "password_reset_link" */
export enum Password_Reset_Link_Select_Column {
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "password_reset_link" */
export type Password_Reset_Link_Set_Input = {
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Password_Reset_Link_Stddev_Fields = {
  __typename?: 'password_reset_link_stddev_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "password_reset_link" */
export type Password_Reset_Link_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Password_Reset_Link_Stddev_Pop_Fields = {
  __typename?: 'password_reset_link_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "password_reset_link" */
export type Password_Reset_Link_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Password_Reset_Link_Stddev_Samp_Fields = {
  __typename?: 'password_reset_link_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "password_reset_link" */
export type Password_Reset_Link_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "password_reset_link" */
export type Password_Reset_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Password_Reset_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Password_Reset_Link_Stream_Cursor_Value_Input = {
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Password_Reset_Link_Sum_Fields = {
  __typename?: 'password_reset_link_sum_fields';
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "password_reset_link" */
export type Password_Reset_Link_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "password_reset_link" */
export enum Password_Reset_Link_Update_Column {
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Password_Reset_Link_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Password_Reset_Link_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Password_Reset_Link_Set_Input>;
  /** filter the rows which have to be updated */
  where: Password_Reset_Link_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Password_Reset_Link_Var_Pop_Fields = {
  __typename?: 'password_reset_link_var_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "password_reset_link" */
export type Password_Reset_Link_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Password_Reset_Link_Var_Samp_Fields = {
  __typename?: 'password_reset_link_var_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "password_reset_link" */
export type Password_Reset_Link_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Password_Reset_Link_Variance_Fields = {
  __typename?: 'password_reset_link_variance_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "password_reset_link" */
export type Password_Reset_Link_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** tracks a user's subscription to comments on a specific post */
export type Post_Subscription = {
  __typename?: 'post_subscription';
  content_id: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "post_subscription" */
export type Post_Subscription_Aggregate = {
  __typename?: 'post_subscription_aggregate';
  aggregate?: Maybe<Post_Subscription_Aggregate_Fields>;
  nodes: Array<Post_Subscription>;
};

export type Post_Subscription_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Subscription_Aggregate_Bool_Exp_Count>;
};

export type Post_Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Post_Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_subscription" */
export type Post_Subscription_Aggregate_Fields = {
  __typename?: 'post_subscription_aggregate_fields';
  avg?: Maybe<Post_Subscription_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Post_Subscription_Max_Fields>;
  min?: Maybe<Post_Subscription_Min_Fields>;
  stddev?: Maybe<Post_Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Subscription_Sum_Fields>;
  var_pop?: Maybe<Post_Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Subscription_Var_Samp_Fields>;
  variance?: Maybe<Post_Subscription_Variance_Fields>;
};


/** aggregate fields of "post_subscription" */
export type Post_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "post_subscription" */
export type Post_Subscription_Aggregate_Order_By = {
  avg?: InputMaybe<Post_Subscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Subscription_Max_Order_By>;
  min?: InputMaybe<Post_Subscription_Min_Order_By>;
  stddev?: InputMaybe<Post_Subscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Post_Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Post_Subscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Post_Subscription_Sum_Order_By>;
  var_pop?: InputMaybe<Post_Subscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Post_Subscription_Var_Samp_Order_By>;
  variance?: InputMaybe<Post_Subscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_subscription" */
export type Post_Subscription_Arr_Rel_Insert_Input = {
  data: Array<Post_Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Post_Subscription_Avg_Fields = {
  __typename?: 'post_subscription_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "post_subscription" */
export type Post_Subscription_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_subscription". All fields are combined with a logical 'AND'. */
export type Post_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Post_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Subscription_Bool_Exp>>;
  content_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_subscription" */
export enum Post_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostSubscriptionPkey = 'post_subscription_pkey'
}

/** input type for incrementing numeric columns in table "post_subscription" */
export type Post_Subscription_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "post_subscription" */
export type Post_Subscription_Insert_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Post_Subscription_Max_Fields = {
  __typename?: 'post_subscription_max_fields';
  content_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "post_subscription" */
export type Post_Subscription_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Subscription_Min_Fields = {
  __typename?: 'post_subscription_min_fields';
  content_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "post_subscription" */
export type Post_Subscription_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_subscription" */
export type Post_Subscription_Mutation_Response = {
  __typename?: 'post_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Subscription>;
};

/** on_conflict condition type for table "post_subscription" */
export type Post_Subscription_On_Conflict = {
  constraint: Post_Subscription_Constraint;
  update_columns?: Array<Post_Subscription_Update_Column>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "post_subscription". */
export type Post_Subscription_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_subscription */
export type Post_Subscription_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "post_subscription" */
export enum Post_Subscription_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "post_subscription" */
export type Post_Subscription_Set_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Post_Subscription_Stddev_Fields = {
  __typename?: 'post_subscription_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "post_subscription" */
export type Post_Subscription_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Subscription_Stddev_Pop_Fields = {
  __typename?: 'post_subscription_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "post_subscription" */
export type Post_Subscription_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Subscription_Stddev_Samp_Fields = {
  __typename?: 'post_subscription_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "post_subscription" */
export type Post_Subscription_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "post_subscription" */
export type Post_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Subscription_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Post_Subscription_Sum_Fields = {
  __typename?: 'post_subscription_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "post_subscription" */
export type Post_Subscription_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "post_subscription" */
export enum Post_Subscription_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Post_Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Post_Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Post_Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Post_Subscription_Var_Pop_Fields = {
  __typename?: 'post_subscription_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "post_subscription" */
export type Post_Subscription_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Subscription_Var_Samp_Fields = {
  __typename?: 'post_subscription_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "post_subscription" */
export type Post_Subscription_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Subscription_Variance_Fields = {
  __typename?: 'post_subscription_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "post_subscription" */
export type Post_Subscription_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** Bible Reminder */
  bibleReminder?: Maybe<BibleReminder>;
  /** fetch data from the table: "bible_reading_plan" */
  bible_reading_plan: Array<Bible_Reading_Plan>;
  /** fetch aggregated fields from the table: "bible_reading_plan" */
  bible_reading_plan_aggregate: Bible_Reading_Plan_Aggregate;
  /** fetch data from the table: "bible_reading_plan" using primary key columns */
  bible_reading_plan_by_pk?: Maybe<Bible_Reading_Plan>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch a chapter of the Bible */
  getBibleChapter?: Maybe<BibleChapterPayload>;
  /** fetch data from the table: "habit" */
  habit: Array<Habit>;
  /** fetch aggregated fields from the table: "habit" */
  habit_aggregate: Habit_Aggregate;
  /** fetch data from the table: "habit" using primary key columns */
  habit_by_pk?: Maybe<Habit>;
  /** fetch data from the table: "habit_execution" */
  habit_execution: Array<Habit_Execution>;
  /** fetch aggregated fields from the table: "habit_execution" */
  habit_execution_aggregate: Habit_Execution_Aggregate;
  /** fetch data from the table: "habit_execution" using primary key columns */
  habit_execution_by_pk?: Maybe<Habit_Execution>;
  /** Fetches the logged in user */
  me?: Maybe<Me>;
  /** fetch data from the table: "password_reset_link" */
  password_reset_link: Array<Password_Reset_Link>;
  /** fetch aggregated fields from the table: "password_reset_link" */
  password_reset_link_aggregate: Password_Reset_Link_Aggregate;
  /** fetch data from the table: "password_reset_link" using primary key columns */
  password_reset_link_by_pk?: Maybe<Password_Reset_Link>;
  /** fetch data from the table: "post_subscription" */
  post_subscription: Array<Post_Subscription>;
  /** fetch aggregated fields from the table: "post_subscription" */
  post_subscription_aggregate: Post_Subscription_Aggregate;
  /** fetch data from the table: "post_subscription" using primary key columns */
  post_subscription_by_pk?: Maybe<Post_Subscription>;
  /** fetch data from the table: "reminder" */
  reminder: Array<Reminder>;
  /** fetch aggregated fields from the table: "reminder" */
  reminder_aggregate: Reminder_Aggregate;
  /** fetch data from the table: "reminder" using primary key columns */
  reminder_by_pk?: Maybe<Reminder>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "tehdo_tag" */
  tehdo_tag: Array<Tehdo_Tag>;
  /** fetch aggregated fields from the table: "tehdo_tag" */
  tehdo_tag_aggregate: Tehdo_Tag_Aggregate;
  /** fetch data from the table: "tehdo_tag" using primary key columns */
  tehdo_tag_by_pk?: Maybe<Tehdo_Tag>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "verification_code" */
  verification_code: Array<Verification_Code>;
  /** fetch aggregated fields from the table: "verification_code" */
  verification_code_aggregate: Verification_Code_Aggregate;
  /** fetch data from the table: "verification_code" using primary key columns */
  verification_code_by_pk?: Maybe<Verification_Code>;
};


export type Query_RootBibleReminderArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBible_Reading_PlanArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


export type Query_RootBible_Reading_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


export type Query_RootBible_Reading_Plan_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Query_RootComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Query_RootComment_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootGetBibleChapterArgs = {
  chapter: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};


export type Query_RootHabitArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


export type Query_RootHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


export type Query_RootHabit_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootHabit_ExecutionArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


export type Query_RootHabit_Execution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


export type Query_RootHabit_Execution_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPassword_Reset_LinkArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


export type Query_RootPassword_Reset_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


export type Query_RootPassword_Reset_Link_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPost_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


export type Query_RootPost_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


export type Query_RootPost_Subscription_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootReminderArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


export type Query_RootReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


export type Query_RootReminder_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootSessionArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  token: Scalars['String']['input'];
};


export type Query_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Query_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Query_RootTask_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTehdo_TagArgs = {
  distinct_on?: InputMaybe<Array<Tehdo_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tehdo_Tag_Order_By>>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};


export type Query_RootTehdo_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tehdo_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tehdo_Tag_Order_By>>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};


export type Query_RootTehdo_Tag_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootVerification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Query_RootVerification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Query_RootVerification_Code_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** what and who and how often to send an email to someone */
export type Reminder = {
  __typename?: 'reminder';
  accountability_config?: Maybe<Scalars['jsonb']['output']>;
  accountability_message?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  accountable_user?: Maybe<User>;
  accountable_user_id?: Maybe<Scalars['Int']['output']>;
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  forgetful_user: User;
  forgetful_user_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  /** cron or date string */
  remind_time: Scalars['String']['output'];
  reminder_message: Scalars['String']['output'];
  timer_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** what and who and how often to send an email to someone */
export type ReminderAccountability_ConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "reminder" */
export type Reminder_Aggregate = {
  __typename?: 'reminder_aggregate';
  aggregate?: Maybe<Reminder_Aggregate_Fields>;
  nodes: Array<Reminder>;
};

export type Reminder_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Reminder_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reminder_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Reminder_Aggregate_Bool_Exp_Count>;
};

export type Reminder_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reminder_Select_Column_Reminder_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reminder_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reminder_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reminder_Select_Column_Reminder_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reminder_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reminder_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reminder_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reminder" */
export type Reminder_Aggregate_Fields = {
  __typename?: 'reminder_aggregate_fields';
  avg?: Maybe<Reminder_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reminder_Max_Fields>;
  min?: Maybe<Reminder_Min_Fields>;
  stddev?: Maybe<Reminder_Stddev_Fields>;
  stddev_pop?: Maybe<Reminder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reminder_Stddev_Samp_Fields>;
  sum?: Maybe<Reminder_Sum_Fields>;
  var_pop?: Maybe<Reminder_Var_Pop_Fields>;
  var_samp?: Maybe<Reminder_Var_Samp_Fields>;
  variance?: Maybe<Reminder_Variance_Fields>;
};


/** aggregate fields of "reminder" */
export type Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reminder" */
export type Reminder_Aggregate_Order_By = {
  avg?: InputMaybe<Reminder_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reminder_Max_Order_By>;
  min?: InputMaybe<Reminder_Min_Order_By>;
  stddev?: InputMaybe<Reminder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reminder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reminder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reminder_Sum_Order_By>;
  var_pop?: InputMaybe<Reminder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reminder_Var_Samp_Order_By>;
  variance?: InputMaybe<Reminder_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reminder_Append_Input = {
  accountability_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "reminder" */
export type Reminder_Arr_Rel_Insert_Input = {
  data: Array<Reminder_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};

/** aggregate avg on columns */
export type Reminder_Avg_Fields = {
  __typename?: 'reminder_avg_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reminder" */
export type Reminder_Avg_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reminder". All fields are combined with a logical 'AND'. */
export type Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Reminder_Bool_Exp>>;
  _not?: InputMaybe<Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Reminder_Bool_Exp>>;
  accountability_config?: InputMaybe<Jsonb_Comparison_Exp>;
  accountability_message?: InputMaybe<String_Comparison_Exp>;
  accountable_user?: InputMaybe<User_Bool_Exp>;
  accountable_user_id?: InputMaybe<Int_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  forgetful_user?: InputMaybe<User_Bool_Exp>;
  forgetful_user_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  remind_time?: InputMaybe<String_Comparison_Exp>;
  reminder_message?: InputMaybe<String_Comparison_Exp>;
  timer_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reminder" */
export enum Reminder_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReminderPkey = 'reminder_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reminder_Delete_At_Path_Input = {
  accountability_config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reminder_Delete_Elem_Input = {
  accountability_config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reminder_Delete_Key_Input = {
  accountability_config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "reminder" */
export type Reminder_Inc_Input = {
  accountable_user_id?: InputMaybe<Scalars['Int']['input']>;
  forgetful_user_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reminder" */
export type Reminder_Insert_Input = {
  accountability_config?: InputMaybe<Scalars['jsonb']['input']>;
  accountability_message?: InputMaybe<Scalars['String']['input']>;
  accountable_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  accountable_user_id?: InputMaybe<Scalars['Int']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  forgetful_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  forgetful_user_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** cron or date string */
  remind_time?: InputMaybe<Scalars['String']['input']>;
  reminder_message?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Reminder_Max_Fields = {
  __typename?: 'reminder_max_fields';
  accountability_message?: Maybe<Scalars['String']['output']>;
  accountable_user_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  forgetful_user_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** cron or date string */
  remind_time?: Maybe<Scalars['String']['output']>;
  reminder_message?: Maybe<Scalars['String']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "reminder" */
export type Reminder_Max_Order_By = {
  accountability_message?: InputMaybe<Order_By>;
  accountable_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  /** cron or date string */
  remind_time?: InputMaybe<Order_By>;
  reminder_message?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reminder_Min_Fields = {
  __typename?: 'reminder_min_fields';
  accountability_message?: Maybe<Scalars['String']['output']>;
  accountable_user_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  forgetful_user_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** cron or date string */
  remind_time?: Maybe<Scalars['String']['output']>;
  reminder_message?: Maybe<Scalars['String']['output']>;
  timer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "reminder" */
export type Reminder_Min_Order_By = {
  accountability_message?: InputMaybe<Order_By>;
  accountable_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  /** cron or date string */
  remind_time?: InputMaybe<Order_By>;
  reminder_message?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reminder" */
export type Reminder_Mutation_Response = {
  __typename?: 'reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reminder>;
};

/** on_conflict condition type for table "reminder" */
export type Reminder_On_Conflict = {
  constraint: Reminder_Constraint;
  update_columns?: Array<Reminder_Update_Column>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "reminder". */
export type Reminder_Order_By = {
  accountability_config?: InputMaybe<Order_By>;
  accountability_message?: InputMaybe<Order_By>;
  accountable_user?: InputMaybe<User_Order_By>;
  accountable_user_id?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  forgetful_user?: InputMaybe<User_Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  remind_time?: InputMaybe<Order_By>;
  reminder_message?: InputMaybe<Order_By>;
  timer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reminder */
export type Reminder_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reminder_Prepend_Input = {
  accountability_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "reminder" */
export enum Reminder_Select_Column {
  /** column name */
  AccountabilityConfig = 'accountability_config',
  /** column name */
  AccountabilityMessage = 'accountability_message',
  /** column name */
  AccountableUserId = 'accountable_user_id',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForgetfulUserId = 'forgetful_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  RemindTime = 'remind_time',
  /** column name */
  ReminderMessage = 'reminder_message',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "reminder_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reminder" */
export enum Reminder_Select_Column_Reminder_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "reminder_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reminder" */
export enum Reminder_Select_Column_Reminder_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "reminder" */
export type Reminder_Set_Input = {
  accountability_config?: InputMaybe<Scalars['jsonb']['input']>;
  accountability_message?: InputMaybe<Scalars['String']['input']>;
  accountable_user_id?: InputMaybe<Scalars['Int']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  forgetful_user_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** cron or date string */
  remind_time?: InputMaybe<Scalars['String']['input']>;
  reminder_message?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Reminder_Stddev_Fields = {
  __typename?: 'reminder_stddev_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reminder" */
export type Reminder_Stddev_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reminder_Stddev_Pop_Fields = {
  __typename?: 'reminder_stddev_pop_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reminder" */
export type Reminder_Stddev_Pop_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reminder_Stddev_Samp_Fields = {
  __typename?: 'reminder_stddev_samp_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reminder" */
export type Reminder_Stddev_Samp_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reminder" */
export type Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reminder_Stream_Cursor_Value_Input = {
  accountability_config?: InputMaybe<Scalars['jsonb']['input']>;
  accountability_message?: InputMaybe<Scalars['String']['input']>;
  accountable_user_id?: InputMaybe<Scalars['Int']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  forgetful_user_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** cron or date string */
  remind_time?: InputMaybe<Scalars['String']['input']>;
  reminder_message?: InputMaybe<Scalars['String']['input']>;
  timer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Reminder_Sum_Fields = {
  __typename?: 'reminder_sum_fields';
  accountable_user_id?: Maybe<Scalars['Int']['output']>;
  forgetful_user_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reminder" */
export type Reminder_Sum_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "reminder" */
export enum Reminder_Update_Column {
  /** column name */
  AccountabilityConfig = 'accountability_config',
  /** column name */
  AccountabilityMessage = 'accountability_message',
  /** column name */
  AccountableUserId = 'accountable_user_id',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForgetfulUserId = 'forgetful_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  RemindTime = 'remind_time',
  /** column name */
  ReminderMessage = 'reminder_message',
  /** column name */
  TimerId = 'timer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Reminder_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reminder_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reminder_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reminder_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reminder_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reminder_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reminder_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reminder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reminder_Var_Pop_Fields = {
  __typename?: 'reminder_var_pop_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reminder" */
export type Reminder_Var_Pop_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reminder_Var_Samp_Fields = {
  __typename?: 'reminder_var_samp_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reminder" */
export type Reminder_Var_Samp_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reminder_Variance_Fields = {
  __typename?: 'reminder_variance_fields';
  accountable_user_id?: Maybe<Scalars['Float']['output']>;
  forgetful_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reminder" */
export type Reminder_Variance_Order_By = {
  accountable_user_id?: InputMaybe<Order_By>;
  forgetful_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** user sessions */
export type Session = {
  __typename?: 'session';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  session_data: Scalars['jsonb']['output'];
  token: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};


/** user sessions */
export type SessionSession_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "session" */
export type Session_Aggregate = {
  __typename?: 'session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

export type Session_Aggregate_Bool_Exp = {
  count?: InputMaybe<Session_Aggregate_Bool_Exp_Count>;
};

export type Session_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Session_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "session" */
export type Session_Aggregate_Fields = {
  __typename?: 'session_aggregate_fields';
  avg?: Maybe<Session_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
  stddev?: Maybe<Session_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Sum_Fields>;
  var_pop?: Maybe<Session_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Var_Samp_Fields>;
  variance?: Maybe<Session_Variance_Fields>;
};


/** aggregate fields of "session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "session" */
export type Session_Aggregate_Order_By = {
  avg?: InputMaybe<Session_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Session_Max_Order_By>;
  min?: InputMaybe<Session_Min_Order_By>;
  stddev?: InputMaybe<Session_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Session_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Session_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Session_Sum_Order_By>;
  var_pop?: InputMaybe<Session_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Session_Var_Samp_Order_By>;
  variance?: InputMaybe<Session_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Session_Append_Input = {
  session_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "session" */
export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Session_On_Conflict>;
};

/** aggregate avg on columns */
export type Session_Avg_Fields = {
  __typename?: 'session_avg_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "session" */
export type Session_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Bool_Exp>>;
  _not?: InputMaybe<Session_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  session_data?: InputMaybe<Jsonb_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "session" */
export enum Session_Constraint {
  /** unique or primary key constraint on columns "token" */
  SessionPkey = 'session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Session_Delete_At_Path_Input = {
  session_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Session_Delete_Elem_Input = {
  session_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Session_Delete_Key_Input = {
  session_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "session" */
export type Session_Inc_Input = {
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "session" */
export type Session_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_data?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: 'session_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "session" */
export type Session_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: 'session_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "session" */
export type Session_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "session" */
export type Session_Mutation_Response = {
  __typename?: 'session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** on_conflict condition type for table "session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns?: Array<Session_Update_Column>;
  where?: InputMaybe<Session_Bool_Exp>;
};

/** Ordering options when selecting data from "session". */
export type Session_Order_By = {
  created_at?: InputMaybe<Order_By>;
  session_data?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: session */
export type Session_Pk_Columns_Input = {
  token: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Session_Prepend_Input = {
  session_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "session" */
export enum Session_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "session" */
export type Session_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_data?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Session_Stddev_Fields = {
  __typename?: 'session_stddev_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "session" */
export type Session_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Session_Stddev_Pop_Fields = {
  __typename?: 'session_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "session" */
export type Session_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Session_Stddev_Samp_Fields = {
  __typename?: 'session_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "session" */
export type Session_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "session" */
export type Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Session_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  session_data?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Session_Sum_Fields = {
  __typename?: 'session_sum_fields';
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "session" */
export type Session_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "session" */
export enum Session_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Session_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Session_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Session_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Session_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Session_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Session_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Session_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Session_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Session_Var_Pop_Fields = {
  __typename?: 'session_var_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "session" */
export type Session_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Session_Var_Samp_Fields = {
  __typename?: 'session_var_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "session" */
export type Session_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Session_Variance_Fields = {
  __typename?: 'session_variance_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "session" */
export type Session_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** Bible Reminder */
  bibleReminder?: Maybe<BibleReminder>;
  /** fetch data from the table: "bible_reading_plan" */
  bible_reading_plan: Array<Bible_Reading_Plan>;
  /** fetch aggregated fields from the table: "bible_reading_plan" */
  bible_reading_plan_aggregate: Bible_Reading_Plan_Aggregate;
  /** fetch data from the table: "bible_reading_plan" using primary key columns */
  bible_reading_plan_by_pk?: Maybe<Bible_Reading_Plan>;
  /** fetch data from the table in a streaming manner: "bible_reading_plan" */
  bible_reading_plan_stream: Array<Bible_Reading_Plan>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<Comment>;
  /** fetch data from the table: "habit" */
  habit: Array<Habit>;
  /** fetch aggregated fields from the table: "habit" */
  habit_aggregate: Habit_Aggregate;
  /** fetch data from the table: "habit" using primary key columns */
  habit_by_pk?: Maybe<Habit>;
  /** fetch data from the table: "habit_execution" */
  habit_execution: Array<Habit_Execution>;
  /** fetch aggregated fields from the table: "habit_execution" */
  habit_execution_aggregate: Habit_Execution_Aggregate;
  /** fetch data from the table: "habit_execution" using primary key columns */
  habit_execution_by_pk?: Maybe<Habit_Execution>;
  /** fetch data from the table in a streaming manner: "habit_execution" */
  habit_execution_stream: Array<Habit_Execution>;
  /** fetch data from the table in a streaming manner: "habit" */
  habit_stream: Array<Habit>;
  /** fetch data from the table: "password_reset_link" */
  password_reset_link: Array<Password_Reset_Link>;
  /** fetch aggregated fields from the table: "password_reset_link" */
  password_reset_link_aggregate: Password_Reset_Link_Aggregate;
  /** fetch data from the table: "password_reset_link" using primary key columns */
  password_reset_link_by_pk?: Maybe<Password_Reset_Link>;
  /** fetch data from the table in a streaming manner: "password_reset_link" */
  password_reset_link_stream: Array<Password_Reset_Link>;
  /** fetch data from the table: "post_subscription" */
  post_subscription: Array<Post_Subscription>;
  /** fetch aggregated fields from the table: "post_subscription" */
  post_subscription_aggregate: Post_Subscription_Aggregate;
  /** fetch data from the table: "post_subscription" using primary key columns */
  post_subscription_by_pk?: Maybe<Post_Subscription>;
  /** fetch data from the table in a streaming manner: "post_subscription" */
  post_subscription_stream: Array<Post_Subscription>;
  /** fetch data from the table: "reminder" */
  reminder: Array<Reminder>;
  /** fetch aggregated fields from the table: "reminder" */
  reminder_aggregate: Reminder_Aggregate;
  /** fetch data from the table: "reminder" using primary key columns */
  reminder_by_pk?: Maybe<Reminder>;
  /** fetch data from the table in a streaming manner: "reminder" */
  reminder_stream: Array<Reminder>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table in a streaming manner: "session" */
  session_stream: Array<Session>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table in a streaming manner: "task" */
  task_stream: Array<Task>;
  /** fetch data from the table: "tehdo_tag" */
  tehdo_tag: Array<Tehdo_Tag>;
  /** fetch aggregated fields from the table: "tehdo_tag" */
  tehdo_tag_aggregate: Tehdo_Tag_Aggregate;
  /** fetch data from the table: "tehdo_tag" using primary key columns */
  tehdo_tag_by_pk?: Maybe<Tehdo_Tag>;
  /** fetch data from the table in a streaming manner: "tehdo_tag" */
  tehdo_tag_stream: Array<Tehdo_Tag>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "verification_code" */
  verification_code: Array<Verification_Code>;
  /** fetch aggregated fields from the table: "verification_code" */
  verification_code_aggregate: Verification_Code_Aggregate;
  /** fetch data from the table: "verification_code" using primary key columns */
  verification_code_by_pk?: Maybe<Verification_Code>;
  /** fetch data from the table in a streaming manner: "verification_code" */
  verification_code_stream: Array<Verification_Code>;
};


export type Subscription_RootBibleReminderArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBible_Reading_PlanArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


export type Subscription_RootBible_Reading_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


export type Subscription_RootBible_Reading_Plan_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootBible_Reading_Plan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Bible_Reading_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


export type Subscription_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootComment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootHabitArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


export type Subscription_RootHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


export type Subscription_RootHabit_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootHabit_ExecutionArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


export type Subscription_RootHabit_Execution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


export type Subscription_RootHabit_Execution_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootHabit_Execution_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Habit_Execution_Stream_Cursor_Input>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


export type Subscription_RootHabit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Habit_Stream_Cursor_Input>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_LinkArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Link_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPassword_Reset_Link_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Password_Reset_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


export type Subscription_RootPost_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


export type Subscription_RootPost_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


export type Subscription_RootPost_Subscription_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPost_Subscription_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Post_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


export type Subscription_RootReminderArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


export type Subscription_RootReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


export type Subscription_RootReminder_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootReminder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


export type Subscription_RootSessionArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  token: Scalars['String']['input'];
};


export type Subscription_RootSession_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTask_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTask_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTehdo_TagArgs = {
  distinct_on?: InputMaybe<Array<Tehdo_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tehdo_Tag_Order_By>>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};


export type Subscription_RootTehdo_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tehdo_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tehdo_Tag_Order_By>>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};


export type Subscription_RootTehdo_Tag_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTehdo_Tag_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tehdo_Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootVerification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Subscription_RootVerification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Subscription_RootVerification_Code_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootVerification_Code_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Verification_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};

/** one off todo items */
export type Task = {
  __typename?: 'task';
  created_at: Scalars['timestamptz']['output'];
  deadline?: Maybe<Scalars['timestamptz']['output']>;
  description: Scalars['String']['output'];
  done?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  ignored: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  order: Scalars['timestamptz']['output'];
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  tag?: Maybe<Tehdo_Tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "task" */
export type Task_Aggregate = {
  __typename?: 'task_aggregate';
  aggregate?: Maybe<Task_Aggregate_Fields>;
  nodes: Array<Task>;
};

export type Task_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Task_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Task_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Task_Aggregate_Bool_Exp_Count>;
};

export type Task_Aggregate_Bool_Exp_Bool_And = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Task_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task" */
export type Task_Aggregate_Fields = {
  __typename?: 'task_aggregate_fields';
  avg?: Maybe<Task_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Task_Max_Fields>;
  min?: Maybe<Task_Min_Fields>;
  stddev?: Maybe<Task_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Sum_Fields>;
  var_pop?: Maybe<Task_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Var_Samp_Fields>;
  variance?: Maybe<Task_Variance_Fields>;
};


/** aggregate fields of "task" */
export type Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "task" */
export type Task_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Max_Order_By>;
  min?: InputMaybe<Task_Min_Order_By>;
  stddev?: InputMaybe<Task_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "task" */
export type Task_Arr_Rel_Insert_Input = {
  data: Array<Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Avg_Fields = {
  __typename?: 'task_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "task" */
export type Task_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
export type Task_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Bool_Exp>>;
  _not?: InputMaybe<Task_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deadline?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  done?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ignored?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Timestamptz_Comparison_Exp>;
  snoozed?: InputMaybe<Timestamptz_Comparison_Exp>;
  tag?: InputMaybe<Tehdo_Tag_Bool_Exp>;
  tag_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "task" */
export enum Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

/** input type for incrementing numeric columns in table "task" */
export type Task_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "task" */
export type Task_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ignored?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  tag?: InputMaybe<Tehdo_Tag_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Task_Max_Fields = {
  __typename?: 'task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "task" */
export type Task_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Min_Fields = {
  __typename?: 'task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  snoozed?: Maybe<Scalars['timestamptz']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "task" */
export type Task_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task" */
export type Task_Mutation_Response = {
  __typename?: 'task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
};

/** on_conflict condition type for table "task" */
export type Task_On_Conflict = {
  constraint: Task_Constraint;
  update_columns?: Array<Task_Update_Column>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Ordering options when selecting data from "task". */
export type Task_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  snoozed?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tehdo_Tag_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task */
export type Task_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "task" */
export enum Task_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Description = 'description',
  /** column name */
  Done = 'done',
  /** column name */
  Id = 'id',
  /** column name */
  Ignored = 'ignored',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  Snoozed = 'snoozed',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Ignored = 'ignored'
}

/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
export enum Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Ignored = 'ignored'
}

/** input type for updating data in table "task" */
export type Task_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ignored?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Task_Stddev_Fields = {
  __typename?: 'task_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "task" */
export type Task_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Stddev_Pop_Fields = {
  __typename?: 'task_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "task" */
export type Task_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Stddev_Samp_Fields = {
  __typename?: 'task_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "task" */
export type Task_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "task" */
export type Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ignored?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  snoozed?: InputMaybe<Scalars['timestamptz']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Task_Sum_Fields = {
  __typename?: 'task_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "task" */
export type Task_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "task" */
export enum Task_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Description = 'description',
  /** column name */
  Done = 'done',
  /** column name */
  Id = 'id',
  /** column name */
  Ignored = 'ignored',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  Snoozed = 'snoozed',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Task_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Task_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Var_Pop_Fields = {
  __typename?: 'task_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "task" */
export type Task_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Var_Samp_Fields = {
  __typename?: 'task_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "task" */
export type Task_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Variance_Fields = {
  __typename?: 'task_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "task" */
export type Task_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** tags for tasks and habits */
export type Tehdo_Tag = {
  __typename?: 'tehdo_tag';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  habits: Array<Habit>;
  /** An aggregate relationship */
  habits_aggregate: Habit_Aggregate;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  order: Scalars['timestamptz']['output'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['Int']['output'];
};


/** tags for tasks and habits */
export type Tehdo_TagHabitsArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


/** tags for tasks and habits */
export type Tehdo_TagHabits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


/** tags for tasks and habits */
export type Tehdo_TagTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


/** tags for tasks and habits */
export type Tehdo_TagTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "tehdo_tag" */
export type Tehdo_Tag_Aggregate = {
  __typename?: 'tehdo_tag_aggregate';
  aggregate?: Maybe<Tehdo_Tag_Aggregate_Fields>;
  nodes: Array<Tehdo_Tag>;
};

/** aggregate fields of "tehdo_tag" */
export type Tehdo_Tag_Aggregate_Fields = {
  __typename?: 'tehdo_tag_aggregate_fields';
  avg?: Maybe<Tehdo_Tag_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tehdo_Tag_Max_Fields>;
  min?: Maybe<Tehdo_Tag_Min_Fields>;
  stddev?: Maybe<Tehdo_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Tehdo_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tehdo_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Tehdo_Tag_Sum_Fields>;
  var_pop?: Maybe<Tehdo_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Tehdo_Tag_Var_Samp_Fields>;
  variance?: Maybe<Tehdo_Tag_Variance_Fields>;
};


/** aggregate fields of "tehdo_tag" */
export type Tehdo_Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tehdo_Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Tehdo_Tag_Avg_Fields = {
  __typename?: 'tehdo_tag_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tehdo_tag". All fields are combined with a logical 'AND'. */
export type Tehdo_Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tehdo_Tag_Bool_Exp>>;
  _not?: InputMaybe<Tehdo_Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tehdo_Tag_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  habits?: InputMaybe<Habit_Bool_Exp>;
  habits_aggregate?: InputMaybe<Habit_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Timestamptz_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tehdo_tag" */
export enum Tehdo_Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  TehdoTagPkey = 'tehdo_tag_pkey'
}

/** input type for incrementing numeric columns in table "tehdo_tag" */
export type Tehdo_Tag_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tehdo_tag" */
export type Tehdo_Tag_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  habits?: InputMaybe<Habit_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Tehdo_Tag_Max_Fields = {
  __typename?: 'tehdo_tag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Tehdo_Tag_Min_Fields = {
  __typename?: 'tehdo_tag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "tehdo_tag" */
export type Tehdo_Tag_Mutation_Response = {
  __typename?: 'tehdo_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tehdo_Tag>;
};

/** input type for inserting object relation for remote table "tehdo_tag" */
export type Tehdo_Tag_Obj_Rel_Insert_Input = {
  data: Tehdo_Tag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tehdo_Tag_On_Conflict>;
};

/** on_conflict condition type for table "tehdo_tag" */
export type Tehdo_Tag_On_Conflict = {
  constraint: Tehdo_Tag_Constraint;
  update_columns?: Array<Tehdo_Tag_Update_Column>;
  where?: InputMaybe<Tehdo_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "tehdo_tag". */
export type Tehdo_Tag_Order_By = {
  created_at?: InputMaybe<Order_By>;
  habits_aggregate?: InputMaybe<Habit_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tehdo_tag */
export type Tehdo_Tag_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tehdo_tag" */
export enum Tehdo_Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tehdo_tag" */
export type Tehdo_Tag_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Tehdo_Tag_Stddev_Fields = {
  __typename?: 'tehdo_tag_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Tehdo_Tag_Stddev_Pop_Fields = {
  __typename?: 'tehdo_tag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Tehdo_Tag_Stddev_Samp_Fields = {
  __typename?: 'tehdo_tag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tehdo_tag" */
export type Tehdo_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tehdo_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tehdo_Tag_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Tehdo_Tag_Sum_Fields = {
  __typename?: 'tehdo_tag_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "tehdo_tag" */
export enum Tehdo_Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Tehdo_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tehdo_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tehdo_Tag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tehdo_Tag_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tehdo_Tag_Var_Pop_Fields = {
  __typename?: 'tehdo_tag_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Tehdo_Tag_Var_Samp_Fields = {
  __typename?: 'tehdo_tag_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Tehdo_Tag_Variance_Fields = {
  __typename?: 'tehdo_tag_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** registered guests of tehpsalmist.com */
export type User = {
  __typename?: 'user';
  /** An array relationship */
  accountability_reminders: Array<Reminder>;
  /** An aggregate relationship */
  accountability_reminders_aggregate: Reminder_Aggregate;
  /** An array relationship */
  bible_reading_plans: Array<Bible_Reading_Plan>;
  /** An aggregate relationship */
  bible_reading_plans_aggregate: Bible_Reading_Plan_Aggregate;
  /** An array relationship */
  comments: Array<Comment>;
  /** An aggregate relationship */
  comments_aggregate: Comment_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  email_verified: Scalars['Boolean']['output'];
  /** An array relationship */
  habit_executions: Array<Habit_Execution>;
  /** An aggregate relationship */
  habit_executions_aggregate: Habit_Execution_Aggregate;
  /** An array relationship */
  habits: Array<Habit>;
  /** An aggregate relationship */
  habits_aggregate: Habit_Aggregate;
  id: Scalars['Int']['output'];
  nacl: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  /** An array relationship */
  password_reset_links: Array<Password_Reset_Link>;
  /** An aggregate relationship */
  password_reset_links_aggregate: Password_Reset_Link_Aggregate;
  phone?: Maybe<Scalars['String']['output']>;
  phone_carrier?: Maybe<Scalars['String']['output']>;
  phone_verified: Scalars['Boolean']['output'];
  /** An array relationship */
  post_subscriptions: Array<Post_Subscription>;
  /** An aggregate relationship */
  post_subscriptions_aggregate: Post_Subscription_Aggregate;
  /** An array relationship */
  reminders: Array<Reminder>;
  /** An aggregate relationship */
  reminders_aggregate: Reminder_Aggregate;
  role: Scalars['String']['output'];
  /** An array relationship */
  sessions: Array<Session>;
  /** An aggregate relationship */
  sessions_aggregate: Session_Aggregate;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  timezone: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_verification_codes: Array<Verification_Code>;
  /** An aggregate relationship */
  user_verification_codes_aggregate: Verification_Code_Aggregate;
  username: Scalars['String']['output'];
};


/** registered guests of tehpsalmist.com */
export type UserAccountability_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserAccountability_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserBible_Reading_PlansArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserBible_Reading_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bible_Reading_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Bible_Reading_Plan_Order_By>>;
  where?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserHabit_ExecutionsArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserHabit_Executions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Execution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Execution_Order_By>>;
  where?: InputMaybe<Habit_Execution_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserHabitsArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserHabits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Habit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Habit_Order_By>>;
  where?: InputMaybe<Habit_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserPassword_Reset_LinksArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserPassword_Reset_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Link_Order_By>>;
  where?: InputMaybe<Password_Reset_Link_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserPost_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserPost_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Post_Subscription_Order_By>>;
  where?: InputMaybe<Post_Subscription_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserRemindersArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserReminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserSessionsArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserUser_Verification_CodesArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


/** registered guests of tehpsalmist.com */
export type UserUser_Verification_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  accountability_reminders?: InputMaybe<Reminder_Bool_Exp>;
  accountability_reminders_aggregate?: InputMaybe<Reminder_Aggregate_Bool_Exp>;
  bible_reading_plans?: InputMaybe<Bible_Reading_Plan_Bool_Exp>;
  bible_reading_plans_aggregate?: InputMaybe<Bible_Reading_Plan_Aggregate_Bool_Exp>;
  comments?: InputMaybe<Comment_Bool_Exp>;
  comments_aggregate?: InputMaybe<Comment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  habit_executions?: InputMaybe<Habit_Execution_Bool_Exp>;
  habit_executions_aggregate?: InputMaybe<Habit_Execution_Aggregate_Bool_Exp>;
  habits?: InputMaybe<Habit_Bool_Exp>;
  habits_aggregate?: InputMaybe<Habit_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nacl?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_reset_links?: InputMaybe<Password_Reset_Link_Bool_Exp>;
  password_reset_links_aggregate?: InputMaybe<Password_Reset_Link_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone_carrier?: InputMaybe<String_Comparison_Exp>;
  phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  post_subscriptions?: InputMaybe<Post_Subscription_Bool_Exp>;
  post_subscriptions_aggregate?: InputMaybe<Post_Subscription_Aggregate_Bool_Exp>;
  reminders?: InputMaybe<Reminder_Bool_Exp>;
  reminders_aggregate?: InputMaybe<Reminder_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  sessions?: InputMaybe<Session_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Bool_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_verification_codes?: InputMaybe<Verification_Code_Bool_Exp>;
  user_verification_codes_aggregate?: InputMaybe<Verification_Code_Aggregate_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "phone" */
  UserPhoneKey = 'user_phone_key',
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  accountability_reminders?: InputMaybe<Reminder_Arr_Rel_Insert_Input>;
  bible_reading_plans?: InputMaybe<Bible_Reading_Plan_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Comment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  habit_executions?: InputMaybe<Habit_Execution_Arr_Rel_Insert_Input>;
  habits?: InputMaybe<Habit_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nacl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  password_reset_links?: InputMaybe<Password_Reset_Link_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_carrier?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  post_subscriptions?: InputMaybe<Post_Subscription_Arr_Rel_Insert_Input>;
  reminders?: InputMaybe<Reminder_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<Session_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_verification_codes?: InputMaybe<Verification_Code_Arr_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nacl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_carrier?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nacl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_carrier?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  accountability_reminders_aggregate?: InputMaybe<Reminder_Aggregate_Order_By>;
  bible_reading_plans_aggregate?: InputMaybe<Bible_Reading_Plan_Aggregate_Order_By>;
  comments_aggregate?: InputMaybe<Comment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  habit_executions_aggregate?: InputMaybe<Habit_Execution_Aggregate_Order_By>;
  habits_aggregate?: InputMaybe<Habit_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  nacl?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  password_reset_links_aggregate?: InputMaybe<Password_Reset_Link_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_carrier?: InputMaybe<Order_By>;
  phone_verified?: InputMaybe<Order_By>;
  post_subscriptions_aggregate?: InputMaybe<Post_Subscription_Aggregate_Order_By>;
  reminders_aggregate?: InputMaybe<Reminder_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_verification_codes_aggregate?: InputMaybe<Verification_Code_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Id = 'id',
  /** column name */
  Nacl = 'nacl',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneCarrier = 'phone_carrier',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Role = 'role',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nacl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_carrier?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nacl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_carrier?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Id = 'id',
  /** column name */
  Nacl = 'nacl',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneCarrier = 'phone_carrier',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Role = 'role',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** email and phone verification codes and metadata */
export type Verification_Code = {
  __typename?: 'verification_code';
  attempts: Scalars['Int']['output'];
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "verification_code" */
export type Verification_Code_Aggregate = {
  __typename?: 'verification_code_aggregate';
  aggregate?: Maybe<Verification_Code_Aggregate_Fields>;
  nodes: Array<Verification_Code>;
};

export type Verification_Code_Aggregate_Bool_Exp = {
  count?: InputMaybe<Verification_Code_Aggregate_Bool_Exp_Count>;
};

export type Verification_Code_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Verification_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Verification_Code_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "verification_code" */
export type Verification_Code_Aggregate_Fields = {
  __typename?: 'verification_code_aggregate_fields';
  avg?: Maybe<Verification_Code_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Verification_Code_Max_Fields>;
  min?: Maybe<Verification_Code_Min_Fields>;
  stddev?: Maybe<Verification_Code_Stddev_Fields>;
  stddev_pop?: Maybe<Verification_Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Verification_Code_Stddev_Samp_Fields>;
  sum?: Maybe<Verification_Code_Sum_Fields>;
  var_pop?: Maybe<Verification_Code_Var_Pop_Fields>;
  var_samp?: Maybe<Verification_Code_Var_Samp_Fields>;
  variance?: Maybe<Verification_Code_Variance_Fields>;
};


/** aggregate fields of "verification_code" */
export type Verification_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verification_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "verification_code" */
export type Verification_Code_Aggregate_Order_By = {
  avg?: InputMaybe<Verification_Code_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Verification_Code_Max_Order_By>;
  min?: InputMaybe<Verification_Code_Min_Order_By>;
  stddev?: InputMaybe<Verification_Code_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Verification_Code_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Verification_Code_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Verification_Code_Sum_Order_By>;
  var_pop?: InputMaybe<Verification_Code_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Verification_Code_Var_Samp_Order_By>;
  variance?: InputMaybe<Verification_Code_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "verification_code" */
export type Verification_Code_Arr_Rel_Insert_Input = {
  data: Array<Verification_Code_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Verification_Code_On_Conflict>;
};

/** aggregate avg on columns */
export type Verification_Code_Avg_Fields = {
  __typename?: 'verification_code_avg_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "verification_code" */
export type Verification_Code_Avg_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "verification_code". All fields are combined with a logical 'AND'. */
export type Verification_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Verification_Code_Bool_Exp>>;
  _not?: InputMaybe<Verification_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Verification_Code_Bool_Exp>>;
  attempts?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_code" */
export enum Verification_Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationCodePkey = 'verification_code_pkey'
}

/** input type for incrementing numeric columns in table "verification_code" */
export type Verification_Code_Inc_Input = {
  attempts?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "verification_code" */
export type Verification_Code_Insert_Input = {
  attempts?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Verification_Code_Max_Fields = {
  __typename?: 'verification_code_max_fields';
  attempts?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "verification_code" */
export type Verification_Code_Max_Order_By = {
  attempts?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Verification_Code_Min_Fields = {
  __typename?: 'verification_code_min_fields';
  attempts?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "verification_code" */
export type Verification_Code_Min_Order_By = {
  attempts?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "verification_code" */
export type Verification_Code_Mutation_Response = {
  __typename?: 'verification_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Verification_Code>;
};

/** on_conflict condition type for table "verification_code" */
export type Verification_Code_On_Conflict = {
  constraint: Verification_Code_Constraint;
  update_columns?: Array<Verification_Code_Update_Column>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_code". */
export type Verification_Code_Order_By = {
  attempts?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verification_code */
export type Verification_Code_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "verification_code" */
export enum Verification_Code_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "verification_code" */
export type Verification_Code_Set_Input = {
  attempts?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Verification_Code_Stddev_Fields = {
  __typename?: 'verification_code_stddev_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "verification_code" */
export type Verification_Code_Stddev_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Verification_Code_Stddev_Pop_Fields = {
  __typename?: 'verification_code_stddev_pop_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "verification_code" */
export type Verification_Code_Stddev_Pop_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Verification_Code_Stddev_Samp_Fields = {
  __typename?: 'verification_code_stddev_samp_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "verification_code" */
export type Verification_Code_Stddev_Samp_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "verification_code" */
export type Verification_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verification_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verification_Code_Stream_Cursor_Value_Input = {
  attempts?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Verification_Code_Sum_Fields = {
  __typename?: 'verification_code_sum_fields';
  attempts?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "verification_code" */
export type Verification_Code_Sum_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "verification_code" */
export enum Verification_Code_Update_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UserId = 'user_id'
}

export type Verification_Code_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verification_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verification_Code_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Verification_Code_Var_Pop_Fields = {
  __typename?: 'verification_code_var_pop_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "verification_code" */
export type Verification_Code_Var_Pop_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Verification_Code_Var_Samp_Fields = {
  __typename?: 'verification_code_var_samp_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "verification_code" */
export type Verification_Code_Var_Samp_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Verification_Code_Variance_Fields = {
  __typename?: 'verification_code_variance_fields';
  attempts?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "verification_code" */
export type Verification_Code_Variance_Order_By = {
  attempts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type CreatePlanMutationVariables = Exact<{
  object: Bible_Reading_Plan_Insert_Input;
}>;


export type CreatePlanMutation = { __typename?: 'mutation_root', insert_bible_reading_plan_one?: { __typename?: 'bible_reading_plan', id: number } | null };

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  set: Bible_Reading_Plan_Set_Input;
}>;


export type UpdatePlanMutation = { __typename?: 'mutation_root', update_bible_reading_plan_by_pk?: { __typename?: 'bible_reading_plan', id: number } | null };

export type DeleteBibleReadingPlanMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBibleReadingPlanMutation = { __typename?: 'mutation_root', delete_bible_reading_plan_by_pk?: { __typename?: 'bible_reading_plan', id: number } | null };

export type AllPlansSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllPlansSubscription = { __typename?: 'subscription_root', bible_reading_plan: Array<{ __typename?: 'bible_reading_plan', id: number, chapters: number, name: string, active_email: boolean, active_text: boolean, legacy_id?: string | null, legacy_email?: string | null, legacy_phone?: string | null, legacy_carrier?: string | null, next_chapter: number, last_chapter?: number | null, first_day?: string | null, time: string, translation: string }> };

export type GetBibleChapterQueryVariables = Exact<{
  chapter: Scalars['Int']['input'];
  version: Scalars['String']['input'];
}>;


export type GetBibleChapterQuery = { __typename?: 'query_root', getBibleChapter?: { __typename?: 'BibleChapterPayload', passage?: string | null, error?: string | null } | null };

export type LoginMutationVariables = Exact<{
  username?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login: { __typename?: 'LoginPayload', token?: string | null, error?: string | null } };

export type SignupMutationVariables = Exact<{
  username: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SignupMutation = { __typename?: 'mutation_root', signup?: { __typename?: 'SignupPayload', id?: number | null, email?: string | null, username?: string | null, error?: string | null } | null };

export type LogoutMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
  allSessions?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type LogoutMutation = { __typename?: 'mutation_root', logout?: { __typename?: 'LogoutPayload', success: boolean, error?: string | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'mutation_root', forgotPassword: { __typename?: 'ForgotPasswordPayload', success: boolean, error?: string | null } };

export type ResetPasswordMutationVariables = Exact<{
  linkId: Scalars['uuid']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'mutation_root', resetPassword: { __typename?: 'ResetPasswordPayload', success: boolean, error?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'query_root', me?: { __typename?: 'Me', email?: string | null, created_at?: string | null, id?: number | null, role?: string | null, updated_at?: string | null, username?: string | null } | null };


export const CreatePlanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createPlan"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"object"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bible_reading_plan_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_bible_reading_plan_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"object"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePlan"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"set"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bible_reading_plan_set_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_bible_reading_plan_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pk_columns"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"set"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const DeleteBibleReadingPlanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteBibleReadingPlan"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"delete_bible_reading_plan_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<DeleteBibleReadingPlanMutation, DeleteBibleReadingPlanMutationVariables>;
export const AllPlansDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"allPlans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bible_reading_plan"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"order_by"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"created_at"},"value":{"kind":"EnumValue","value":"asc"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"chapters"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"active_email"}},{"kind":"Field","name":{"kind":"Name","value":"active_text"}},{"kind":"Field","name":{"kind":"Name","value":"legacy_id"}},{"kind":"Field","name":{"kind":"Name","value":"legacy_email"}},{"kind":"Field","name":{"kind":"Name","value":"legacy_phone"}},{"kind":"Field","name":{"kind":"Name","value":"legacy_carrier"}},{"kind":"Field","name":{"kind":"Name","value":"next_chapter"}},{"kind":"Field","name":{"kind":"Name","value":"last_chapter"}},{"kind":"Field","name":{"kind":"Name","value":"first_day"}},{"kind":"Field","name":{"kind":"Name","value":"time"}},{"kind":"Field","name":{"kind":"Name","value":"translation"}}]}}]}}]} as unknown as DocumentNode<AllPlansSubscription, AllPlansSubscriptionVariables>;
export const GetBibleChapterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBibleChapter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"chapter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"version"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getBibleChapter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"chapter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"chapter"}}},{"kind":"Argument","name":{"kind":"Name","value":"version"},"value":{"kind":"Variable","name":{"kind":"Name","value":"version"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"passage"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<GetBibleChapterQuery, GetBibleChapterQueryVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const SignupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Signup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<SignupMutation, SignupMutationVariables>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"allSessions"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"allSessions"},"value":{"kind":"Variable","name":{"kind":"Name","value":"allSessions"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ForgotPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forgotPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"linkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"linkId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"linkId"}}},{"kind":"Argument","name":{"kind":"Name","value":"newPassword"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const MeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"updated_at"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}}]}}]} as unknown as DocumentNode<MeQuery, MeQueryVariables>;