import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation Login($username: String, $email: String, $password: String!) {
    login(input: { username: $username, password: $password, email: $email }) {
      token
      error
    }
  }
`

export const SIGNUP = gql`
  mutation Signup($username: String!, $email: String!, $password: String!) {
    signup(input: { username: $username, password: $password, email: $email }) {
      id
      email
      username
      error
    }
  }
`

export const LOGOUT = gql`
  mutation Logout($token: String, $allSessions: Boolean) {
    logout(input: { token: $token, allSessions: $allSessions }) {
      success
      error
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      error
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($linkId: uuid!, $newPassword: String!) {
    resetPassword(linkId: $linkId, newPassword: $newPassword) {
      success
      error
    }
  }
`
