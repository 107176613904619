import React, { ComponentProps, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { bibleStructure, chapters, translations } from '../data'
import { useQuery } from '@apollo/client'
import { graphql } from '../gql'
import { Item, Select } from '@8thday/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { Loading } from './Loading'

let currentChapter = 0
const bibleOptions = Object.entries(bibleStructure).reduce<Record<string, Item[]>>((map, [book, chapterList]) => {
  map[book] = chapterList.map((v, i) => ({ label: i + 1, value: `${i + currentChapter}` }))
  currentChapter += chapterList.length
  return map
}, {})
const translationItems = translations.map((t) => ({ label: t.acronym, value: t.code }))

export interface ReadProps extends ComponentProps<'div'> {}

export const Read = ({ className = '', ...props }: ReadProps) => {
  const { chapter, version = '' } = useParams()

  const chapterInt = useMemo(() => chapters.findIndex((c) => c === chapter), [chapter])

  const { data, loading } = useQuery(
    graphql(`
      query GetBibleChapter($chapter: Int!, $version: String!) {
        getBibleChapter(chapter: $chapter, version: $version) {
          passage
          error
        }
      }
    `),
    { variables: { chapter: chapterInt, version }, skip: !version || chapterInt === -1, fetchPolicy: 'cache-first' }
  )

  const passage = data?.getBibleChapter?.passage

  const goTo = useNavigate()

  return (
    <div className={`${className} relative h-content overflow-y-hidden`} {...props}>
      <div className="flex-center absolute bottom-0 left-0 right-0 z-10 gap-2 bg-white p-1 md:bottom-[unset] md:top-0">
        <Select
          className="w-fit"
          value={version}
          items={translationItems}
          onValueChange={(v) => goTo(`/read/${v}/${chapter}`)}
          placeholder="Translation"
          collapseDescriptionArea
        />
        <Select
          className="w-fit"
          value={`${chapterInt}`}
          onValueChange={(c) => goTo(`/read/${version || 'esv'}/${chapters[c]}`)}
          placeholder="Chapter"
          items={bibleOptions}
          groupLabelInValue
          collapseDescriptionArea
        />
      </div>
      {!passage && !loading && (
        <>
          <h2 className="mt-16">Take up and Read!</h2>
          <p>Select a chapter and translation to begin reading.</p>
        </>
      )}
      {(passage || loading) && (
        <>
          {chapterInt > 0 && (
            <button
              className="flex-center absolute bottom-0 left-0 z-20 px-1 py-2 text-primary-500 hover:bg-primary-50 focus:bg-primary-100 focus:outline-none sm:px-4 md:top-0 md:py-4"
              onClick={() => goTo(`/read/${version}/${chapters[chapterInt - 1]}`)}
            >
              <ArrowLeftIcon className="h-8 w-8 sm:h-12 sm:w-12" />
            </button>
          )}
          {passage && (
            <div
              className="no-scrollbar relative top-0 mx-auto mb-16 max-h-content max-w-xl overflow-y-auto px-2 pb-36 pt-2 text-base sm:px-0 md:pt-16 md:text-lg"
              dangerouslySetInnerHTML={{ __html: passage }}
            />
          )}
          {loading && <Loading />}
          {chapterInt < chapters.length - 1 && (
            <button
              className="flex-center absolute bottom-0 right-0 z-20 px-1 py-2 text-primary-500 hover:bg-primary-50 focus:bg-primary-100 focus:outline-none sm:px-4 md:top-0 md:py-4"
              onClick={() => goTo(`/read/${version}/${chapters[chapterInt + 1]}`)}
            >
              <ArrowRightIcon className="h-8 w-8 sm:h-12 sm:w-12" />
            </button>
          )}
        </>
      )}
    </div>
  )
}
