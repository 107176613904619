import clsx from 'clsx'
import React, { ComponentProps, forwardRef } from 'react'
import { IconType } from './type-utils'

export interface ButtonVariants {
  primary: string
  secondary: string
  dismissive: string
  destructive: string
}

let variants: ButtonVariants = {
  primary: 'border-transparent bg-primary-500 text-white enabled:hover:bg-primary-600 focus:ring-primary-500',
  secondary: 'border-transparent bg-primary-100 text-primary-700 enabled:hover:bg-primary-200 focus:ring-primary-500',
  dismissive: 'border-gray-300 bg-white text-gray-700 enabled:hover:bg-gray-50 focus:ring-primary-500',
  destructive: 'border-transparent bg-red-500 text-white enabled:hover:bg-red-600 focus:ring-red-500',
}

let baseClasses =
  'relative inline-flex justify-center items-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-70'

export const customizeButtonClasses = ({
  variants: newVariants,
  baseClasses: newBase,
}: {
  variants?: Partial<ButtonVariants>
  baseClasses?: string
}) => {
  variants = {
    ...variants,
    ...newVariants,
  }

  if (typeof newBase === 'string') {
    baseClasses = newBase
  }
}

export interface ButtonProps extends ComponentProps<'button'> {
  PreIcon?: IconType
  PostIcon?: IconType
  variant?: keyof ButtonVariants
  spin?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { type = 'button', className = '', children, PreIcon, PostIcon, variant = 'secondary', spin = false, ...props },
    ref
  ) => {
    return (
      <button ref={ref} type={type} className={clsx(className, variants[variant], baseClasses)} {...props}>
        {PreIcon && (
          <PreIcon
            className={clsx('mr-2 h-5 w-5', {
              'animate-spin': spin,
            })}
            aria-hidden="true"
          />
        )}
        {children}
        {PostIcon && (
          <PostIcon
            className={clsx('ml-2 h-5 w-5', {
              'animate-spin': spin,
            })}
            aria-hidden="true"
          />
        )}
      </button>
    )
  }
)
