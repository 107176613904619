import { toast } from '@8thday/react'
import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'

const { API_HTTP_ENDPOINT = 'https://api.tehpsalmist.com', API_WS_ENDPOINT = 'wss://api.tehpsalmist.com' } = process.env

const wsLink = () => {
  const wsClient = createClient({
    url: `${API_WS_ENDPOINT}/v1/graphql`,
    connectionParams: () => ({ credentials: 'include' }),
    shouldRetry(errOrCloseEvent: any) {
      if (errOrCloseEvent?.isTrusted) return true
      toast.error({ message: 'subscription disconnect', description: JSON.stringify(errOrCloseEvent) })
      return false
    },
  })

  return new GraphQLWsLink(wsClient)
}

const httpLink = () =>
  new HttpLink({
    uri: `${API_HTTP_ENDPOINT}/v1/graphql`,
    credentials: 'include',
  })

export const getClient = () =>
  new ApolloClient({
    link:
      typeof window !== 'undefined'
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query)

              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            },
            wsLink(),
            httpLink()
          )
        : httpLink(),
    cache: new InMemoryCache(),
    credentials: 'include',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
