import React, { ComponentProps, ReactNode } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Loading } from './components/Loading'
import { Button, IconType } from '@8thday/react'
import clsx from 'clsx'
import { BookOpenIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import { LoginPage, useAuthState } from '@tehpsalmist/tehpsalmist-auth'

export interface AppProps extends ComponentProps<'main'> {}

export const App = ({ className = '', ...props }: AppProps) => {
  const { loading, visitor, setShowLoginScreen, logout } = useAuthState()

  return (
    <>
      <main className={`${className} flex h-100svh max-w-screen flex-col pb-16 sm:pb-0 sm:pt-16`} {...props}>
        {visitor ? (
          <Outlet />
        ) : loading ? (
          <Loading />
        ) : (
          <Button className="my-auto self-center" variant="primary" onClick={() => setShowLoginScreen(true)}>
            Login
          </Button>
        )}
      </main>
      <nav className="fixed bottom-0 flex h-16 w-full max-w-screen items-stretch justify-evenly bg-primary-50 transition-all duration-300 sm:top-0 sm:justify-end">
        <h1 className="mr-auto hidden items-center p-2 text-center font-mono text-lg font-bold leading-4 text-primary-500 sm:flex md:p-4 md:text-xl">
          Bible Reminder 365
        </h1>
        <TehNavLink to="/plans" icon={CalendarDaysIcon} label="Plans" />
        <TehNavLink to="/read" icon={BookOpenIcon} label="Read" />
        {visitor && (
          <button
            className="flex-center -order-1 grow flex-col whitespace-nowrap text-primary-500 transition-all duration-300 hover:bg-primary-100 focus:bg-primary-100 focus:outline-none sm:order-1 sm:grow-0 sm:flex-row sm:px-4"
            onClick={logout}
          >
            Logout
          </button>
        )}
      </nav>
      <LoginPage className="mb-16 !h-contentD sm:mb-0 sm:mt-16" />
    </>
  )
}

interface TehNavLinkProps {
  to: string
  className?: string
  icon?: IconType
  label: ReactNode
}

const TehNavLink = ({ label, icon: Icon, className = '', to }: TehNavLinkProps) => (
  <NavLink
    className={({ isActive }) =>
      clsx(
        `flex-center grow flex-col whitespace-nowrap text-primary-500 transition-all duration-300 focus:outline-none sm:grow-0 sm:flex-row sm:px-4`,
        className,
        isActive ? 'bg-primary-200 text-primary-600' : 'hover:bg-primary-100 focus:bg-primary-100'
      )
    }
    to={to}
  >
    {Icon && <Icon className="h-8 w-8" />}
    <span className="text-[11px] sm:ml-2 sm:text-sm md:text-base">{label}</span>
  </NavLink>
)
