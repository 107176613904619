import React from 'react'
import { createRoot } from 'react-dom/client'
import { getClient } from './graphql/getClient'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { App } from './App'
import { ApolloProvider } from '@apollo/client'
import { AuthStateProvider } from '@tehpsalmist/tehpsalmist-auth'
import { Loading } from './components/Loading'
import { Toaster } from 'react-hot-toast'
import { Home } from './components/Home'
import { Plans } from './components/Plans'
import { Read } from './components/Read'
import { Redirect } from './components/Redirect'

const client = getClient()

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'plans',
        element: <Plans />,
        children: [
          {
            path: ':id',
            element: <Plans />,
            children: [
              {
                path: '*',
                element: <Plans />,
              },
            ],
          },
        ],
      },
      {
        path: 'read',
        element: <Read />,
        children: [
          {
            path: ':version',
            element: <Read />,
            children: [
              {
                path: ':chapter',
                element: <Read />,
                children: [{ path: '*', element: <Read /> }],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'dashboard',
    element: <Redirect to="/" />,
  },
])

const root = createRoot(document.getElementById('app')!)

root.render(
  <ApolloProvider client={client}>
    <AuthStateProvider>
      <RouterProvider router={router} fallbackElement={<Loading />} />
      <Toaster
        containerClassName="!bottom-20 sm:!top-32 sm:!bottom-full"
        toastOptions={{ duration: 1500 }}
        position="bottom-right"
      />
    </AuthStateProvider>
  </ApolloProvider>
)
