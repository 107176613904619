import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React, { ComponentProps, forwardRef, ReactNode, useState } from 'react'
import { IconType } from './type-utils'

export interface TextInputProps extends ComponentProps<'input'> {
  label?: ReactNode
  errorMessage?: ReactNode
  PostIcon?: IconType
  PreIcon?: IconType
  inputClass?: string
  iconClass?: string
  innerClass?: string
  labelClass?: string
  description?: ReactNode
  collapseDescriptionArea?: boolean
  hideLabel?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className = '',
      value,
      onChange,
      type = 'text',
      label,
      id,
      errorMessage,
      PostIcon,
      PreIcon,
      iconClass = 'text-gray-400',
      inputClass = '',
      innerClass = '',
      labelClass = '',
      description,
      collapseDescriptionArea = false,
      hideLabel = false,
      ...props
    },
    ref
  ) => {
    const suffixIconCount = [!!PostIcon, !!errorMessage].filter(Boolean).length
    const [touched, setTouched] = useState(false)

    const requiredAndBlank = !value && touched && props.required

    const errorState = !!errorMessage || requiredAndBlank

    return (
      <div className={`${className}`}>
        {label && (
          <label
            htmlFor={id}
            className={clsx('block text-base font-medium text-gray-700', labelClass, { 'sr-only': hideLabel })}
          >
            {label}
            {props.required && (
              <span className={clsx({ 'text-emerald-400': !requiredAndBlank, 'text-red-400': requiredAndBlank })}>
                {' '}
                *
              </span>
            )}
          </label>
        )}
        <div className={clsx(innerClass, 'relative rounded-md shadow-sm', { 'mt-1': !!label })}>
          {PreIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center ml-3">
              <PreIcon className={clsx('h-5 w-5', iconClass)} aria-hidden="true" />
            </div>
          )}
          <input
            id={id}
            type={type}
            className={clsx(
              'block w-full rounded-md focus:outline-none disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400',
              inputClass,
              {
                'pl-10': !!PreIcon,
                'pr-16': suffixIconCount === 2,
                'pr-10': suffixIconCount === 1,
                'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500': errorState,
                'border-primary-300 text-primary-900 placeholder-primary-300 focus:border-primary-500 focus:ring-primary-500':
                  !errorState,
              }
            )}
            value={value}
            onChange={(e) => {
              if (!touched) {
                setTouched(true)
              }
              onChange?.(e)
            }}
            aria-invalid={errorMessage ? 'true' : 'false'}
            aria-describedby={`${id}-description`}
            ref={ref}
            {...props}
          />
          {errorMessage && (
            <div
              className={clsx('pointer-events-none absolute inset-y-0 right-0 flex items-center', {
                'mr-3': !PostIcon,
                'mr-10': !!PostIcon,
              })}
            >
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}
          {PostIcon && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center mr-3">
              <PostIcon className={clsx('h-5 w-5', iconClass)} aria-hidden="true" />
            </div>
          )}
        </div>
        {!collapseDescriptionArea && (
          <p
            className={clsx('mt-1 text-sm', {
              'text-red-600': errorState,
              'text-gray-500': description && !errorState,
              'h-5': !description && !errorState && !collapseDescriptionArea,
            })}
            id={`${id}-description`}
          >
            {errorMessage || (requiredAndBlank && 'This field is required.') || description}
          </p>
        )}
      </div>
    )
  }
)
